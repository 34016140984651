import React from 'react';
import logo from "../../asset/Youthplus3DW.png";
import './forgotpassword.css';

const ForgotPassword = () => {

  
  return (
    <div style={{backgroundColor:"#F1F1F2", height:"100%"}}>
      <div style={{backgroundColor:"#ffffff", display:"flex", justifyContent:"center", alignItems:"center", padding:"0.3rem" }}>
            <img src={logo} style={{height:"30px"}} alt="" />
            <h2 style={{color:"#0F0F0F", fontSize:"1.2rem", fontWeight:"700", paddingTop:"0.3rem"}}>youthplus</h2>
      </div>

      <div style={{margin:"0 auto",
                height:"100vh",
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center",
                gap:"0.4rem"
                }}>
      <svg width="64" height="64" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.25" cx="37" cy="37" r="37" fill="#08839B"/>
        <circle opacity="0.4" cx="37" cy="37" r="28" fill="#08839B"/>
        <path d="M39.9065 21.3891L52.4532 29.5444C52.8966 29.8326 53.1182 29.9767 53.2788 30.1689C53.421 30.339 53.5278 30.5358 53.593 30.7477C53.6666 30.9871 53.6666 31.2515 53.6666 31.7803V43.9998C53.6666 46.8001 53.6666 48.2002 53.1216 49.2698C52.6422 50.2106 51.8773 50.9755 50.9365 51.4548C49.867 51.9998 48.4668 51.9998 45.6666 51.9998H28.3333C25.533 51.9998 24.1329 51.9998 23.0633 51.4548C22.1225 50.9755 21.3576 50.2106 20.8782 49.2698C20.3333 48.2002 20.3333 46.8001 20.3333 43.9998V31.7803C20.3333 31.2515 20.3333 30.9871 20.4069 30.7477C20.472 30.5358 20.5788 30.339 20.721 30.1689C20.8816 29.9767 21.1033 29.8326 21.5466 29.5444L34.0933 21.3891M39.9065 21.3891C38.8544 20.7052 38.3284 20.3633 37.7616 20.2303C37.2606 20.1127 36.7392 20.1127 36.2382 20.2303C35.6714 20.3633 35.1454 20.7052 34.0933 21.3891M39.9065 21.3891L50.2268 28.0973C51.3732 28.8425 51.9464 29.215 52.145 29.6875C52.3185 30.1005 52.3185 30.5658 52.145 30.9787C51.9464 31.4512 51.3732 31.8238 50.2268 32.569L39.9065 39.2772C38.8545 39.961 38.3284 40.3029 37.7616 40.436C37.2606 40.5536 36.7392 40.5536 36.2382 40.436C35.6714 40.3029 35.1454 39.961 34.0933 39.2772L23.773 32.569C22.6266 31.8238 22.0534 31.4512 21.8549 30.9787C21.6814 30.5658 21.6814 30.1005 21.8549 29.6875C22.0534 29.215 22.6266 28.8425 23.773 28.0973L34.0933 21.3891M52.8332 48.6665L41.7619 38.6665M32.238 38.6665L21.1666 48.6665" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>  

        <h5>Check your email</h5>

        <p className='forgotP' style={{
            color:"rgba(0, 0, 0, 0.7)"
        }}>We've sent you an email with instructions to reset your password. Please check your inbox.</p>
        <p className='forgotP' style={{
            color:"rgba(255, 0, 0, 1)",
            marginBottom:"3rem"
        }}>If you don't see the email within a few minutes, be sure to check your spam or junk folder.</p>

        <p className='forgotP' style={{
            color:"rgba(0, 0, 0, 1)"
        }}>Still can't find the email? Contact our <span style={{color:"rgba(8, 131, 155, 1)", textDecoration:"underline"}}> <a href="mailto:knowbot@sidmach.com"> support team</a></span>,
        and we'll be happy to assist you.</p>
      </div>
    </div>
  )
}

export default ForgotPassword
