import React from 'react'
import TransportHeader from '../../Components/transportheader'
import TransportBanner from '../../Components/bannerComp'
import TransportFeatures from '../../Components/feature'
import Map from '../../Components/map'
import About from '../../Components/about'
import TransportFooter from '../../Components/transportfooter'




function Transport() {
  return (
    <div className='transport-home'>
      <TransportHeader />
      <TransportBanner />
      <hr/>
      <TransportFeatures />
      <hr/>
      <Map/>
      <hr/>
      <About />
      <TransportFooter/>
        
    </div>
  )
}

export default Transport