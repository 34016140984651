import React from 'react'
import { Typography, Button } from 'antd'
import DoubleArrow from '../asset/doubleedgedarrow.svg'
import SingleArrow from '../asset/singleedgedarrow.svg'

function HistoryList({status, tripType, origin,destination, date}) {
    
  return (
    <div>
      <Typography className='history-status' style={{fontWeight: '500',color: status ==='Completed'?'#034f08':status==='In Progress'?'blue':'red'}}>{status}</Typography>
            <div className='history-items'>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Button style={{borderRadius: '1.8125rem', background:' #B5C3C2', margin:'auto 0'}}>{tripType}</Button>
                    <div style={{display:'flex', margin:'1rem 0rem'}}>
                        <Typography className='history-text'>{origin}</Typography>
                        <img src={tripType ==='Round trip'? DoubleArrow : SingleArrow} alt='arrow' width='50px' height='20px' className='arrow-img'/>
                        <Typography className='history-text'>{destination}</Typography>
                    </div>
                    <div style={{display:'flex', margin:'1rem 0rem'}}> 
                        <Typography>Date booked - </Typography>
                        <Typography>{date}</Typography>
                    </div>
                    <Button>View</Button>
                </div>
            </div>
    </div>
  )
}

export default HistoryList
