import React from 'react'
import TransportHeader from '../../Components/transportheader'
import HistoryContent from '../../Components/historyContent'

function History() {
  return (
    <div className='history'>
        <TransportHeader/>
        <HistoryContent/>
    </div>
  )
}

export default History
