// encryptionUtils.js
import CryptoJS from "crypto-js";

// Generate a 256-bit key and a 128-bit IV for AES encryption
const secretKey = CryptoJS.enc.Hex.parse(
  "0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef"
); // 64 hex characters (256-bit key)
const iv = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210"); // 32 hex characters (128-bit IV)

// Function to make Base64 URL-safe
const urlSafeBase64Encode = (str) => {
  return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

// Function to decode URL-safe Base64
const urlSafeBase64Decode = (str) => {
  str = str.replace(/-/g, "+").replace(/_/g, "/");
  while (str.length % 4) {
    str += "=";
  }
  return str;
};

export const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
    iv: iv,
  });
  const encryptedBase64 = encrypted.toString();
  const encodedData = urlSafeBase64Encode(encryptedBase64);
  return encodedData;
};

export const decryptData = (encodedData) => {
  const encryptedBase64 = urlSafeBase64Decode(encodedData);
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, secretKey, {
    iv: iv,
  });
  const decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
