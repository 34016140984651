import React from "react";
import { Button } from "antd";
import plus from "../asset/plus.png";
import minus from "../asset/minus.png";

const Counter = ({
  label,
  count,
  setCount,
  quantity,
  seatsLeft,
  total,
  minTotal,
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      marginBottom: "1rem",
      justifyContent: "start",
      flexDirection: "column",
    }}
  >
    <span style={{ marginLeft: "" }}>{label}</span>
    <br />
    <div style={{ marginLeft: "0.5rem", marginTop: "0" }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          //   setCount(count - 1);
          // }}
          // disabled={count <= 0}
          if (total > minTotal) {
            setCount(count - 1);
          }
        }}
        disabled={count <= 0 || total <= minTotal}
        style={{ border: "2px solid white", background: "white" }}
      >
        <img src={minus} alt="minus-icon" />
      </Button>
      <span
        style={{
          border: "2px solid #CFCFCF",
          width: "20px",
          paddingLeft: "0.9rem",
          paddingRight: "0.9rem",
          paddingTop: "0.2rem",
          paddingBottom: "0.2rem",
        }}
      >
        {count}
      </span>
      <Button
        disabled={quantity >= seatsLeft ? true : false}
        onClick={(e) => {
          e.stopPropagation();
          setCount(count + 1);
        }}
        style={{
          border: "2px solid white",
          background: "white",
          boxShadow: "none",
        }}
      >
        <img src={plus} alt="add-icon" />
      </Button>
    </div>
    {/* <span style={{ marginLeft: "1rem" }}>{label}</span> */}
  </div>
); // Assuming you have a Counter component for increment/decrement functionality
const PassengerDropdown = ({
  adultCount,
  quantity,
  seatsLeft,
  setAdultCount,
  childCount,
  setChildCount,
}) => {
  const minTotal = 1;
  const total = adultCount + childCount;

  return (
    <div>
      {/* <h2 style={{ color: "#08839B", fontSize: "25px", marginBottom:"4rem" }}>Select Number of Passengers</h2> */}
      <div
        style={{
          backgroundColor: "#ffffff",
          border: "#000000",
          padding: "0.5rem",
          // outline: "2px solid red",
          display: "flex",
          flexDirection: "column",
          // alignItems: "end  "
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <img src={one} alt="icon" /> */}
        <h2
          style={{ color: "#08839B", fontSize: "20px", marginLeft: "0.5rem" }}
        >
          {" "}
          Select Number of Passengers
        </h2>
        <br />
        <div style={{ display: "flex" }}>
          <Counter
            label="Adults"
            quantity={quantity}
            seatsLeft={seatsLeft}
            count={adultCount}
            setCount={setAdultCount}
            total={total}
            minTotal={minTotal}
          />
          <Counter
            label="Children"
            quantity={quantity}
            seatsLeft={seatsLeft}
            count={childCount}
            setCount={setChildCount}
            total={total}
            minTotal={minTotal}
          />
        </div>
      </div>
    </div>
  );

  // return (
  //   <Dropdown overlay={menu} trigger={["click"]}>
  //     <Button style={{ width: "100%", overflow: "hidden",  }}>
  //       {adultCount} Adults, {childCount} Children
  //       <img src={dropdownArrow} alt="dropdown-arrow" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
  //     </Button>
  //   </Dropdown>
  // );
  //  <br/>
};

export default PassengerDropdown;
