import React, { useState } from "react";
import { Form, Input, Select, Row, Col, Card, Badge, Typography } from "antd";
// import Two from "../asset/two-icon.svg";

const { Option } = Select;

function PersonalDetails({
  // quantity,
  // seatsLeft,
  passengerDetails,
  handlePassengerChange,
  // setValidation, // Added: to set validation state
}) 

{
  const [errors, setErrors] = useState([]);


  const validateField = (index, field, value) => {
    let errorMessage = "";
    switch (field) {
      case "firstname":
      case "nok.firstname":
        if (!value) errorMessage = "Please enter your first name!";
        break;
      case "lastname":
      case "nok.lastname":
        if (!value) errorMessage = "Please enter your last name!";
        break;
      case "email":
      case "nok.email":
        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))
          errorMessage = "Please enter a valid email address!";
        break;
      case "phone":
      case "nok.phone":
        if (!/^[0-9]{8,15}$/.test(value))
          errorMessage = "Please enter a valid phone number!";
        break;
      case "gendervalue":
        if (!value) errorMessage = "Please select your gender!";
        break;
      case "titlevalue":
        if (!value) errorMessage = "Please select your title!";
        break;
      default:
        break;
    }
    const newErrors = [...errors];
    if (!newErrors[index]) newErrors[index] = {};
    newErrors[index][field] = errorMessage;
    setErrors(newErrors);
  };

  return (
    <div style={{}}>
      <br />
      {/* <h2 style={{ color: "#08839B", fontSize: "20px", backgroundColor:"white", marginBottom:"0",
         paddingTop:"1rem",
        paddingBottom:"0",
        paddingLeft:"1.4rem"
        }}
         > <img src={Two} alt="icon" /> Passengers</h2> */}

      {passengerDetails.map((details, index) => (
        <Card
          key={index}
          title={
            <>
              <Badge
                count={index + 1}
                style={{
                  backgroundColor: "#08839B",
                  marginRight: 8,
                  width: "22px",
                }}
              />
              <span style={{ color: "#08839B" }}>
                Passenger Details ({details.passengerType})
              </span>
            </>
          }
          style={{ background: "#fff", marginBottom: 20 }}
        >
          <Form layout="vertical">
            <Row gutter={[8, 8]}>
              <Col span={6}>
                <Form.Item
                  label="Gender"
                  validateStatus={errors[index]?.gendervalue ? "error" : ""}
                  help={errors[index]?.gendervalue}
                >
                  <Select
                    value={details.gendervalue}
                    onChange={(value) => {
                      handlePassengerChange(index, "gendervalue", value)
                      validateField(index, "gendervalue", value);}
                    }
                  >
                    <Option value="M">Male</Option>
                    <Option value="F">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Title"
                  validateStatus={errors[index]?.titlevalue ? "error" : ""}
                  help={errors[index]?.titlevalue}
                >
                  <Select
                    value={details.titlevalue}
                    onChange={(value) =>{
                      handlePassengerChange(index, "titlevalue", value)
                      validateField(index, "titlevalue", value);}
                    }
                  >
                    <Option value="mr">Mr</Option>
                    <Option value="mrs">Mrs</Option>
                    <Option value="miss">Miss</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  validateStatus={errors[index]?.firstname ? "error" : ""}
                  help={errors[index]?.firstname}
                >
                  <Input
                    value={details.firstname}
                    onChange={(e) =>{
                      handlePassengerChange(index, "firstname", e.target.value)
                      validateField(index, "firstname", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  validateStatus={errors[index]?.lastname ? "error" : ""}
                  help={errors[index]?.lastname}
                >
                  <Input
                    value={details.lastname}
                    onChange={(e) =>{
                      handlePassengerChange(index, "lastname", e.target.value)
                      validateField(index, "lastname", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  validateStatus={errors[index]?.phone ? "error" : ""}
                  help={errors[index]?.phone}
                >
                  <Input
                    value={details.phone}
                    onChange={(e) =>{
                      handlePassengerChange(index, "phone", e.target.value)
                      validateField(index, "phone", e.target.value);
                    }
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  // name="email"
                  validateStatus={errors[index]?.email ? "error" : ""}
                  help={errors[index]?.email}
                >
                  <Input
                    value={details.email}
                    onChange={(e) =>{
                      handlePassengerChange(index, "email", e.target.value)
                      validateField(index, "email", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Typography className="details-listing">Next of Kin</Typography>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  // name="nokFirstName"
                  validateStatus={errors[index]?.["nok.firstname"] ? "error" : ""}
                  help={errors[index]?.["nok.firstname"]}
                >
                  <Input
                    value={details.nok.firstname}
                    onChange={(e) =>{
                      handlePassengerChange(
                        index,
                        "nok.firstname",
                        e.target.value
                      )
                      validateField(index, "nok.firstname", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  // name="nokLastName"
                  validateStatus={errors[index]?.["nok.lastname"] ? "error" : ""}
                  help={errors[index]?.["nok.lastname"]}
                >
                  <Input
                    value={details.nok.lastname}
                    onChange={(e) => {
                      handlePassengerChange(
                        index,
                        "nok.lastname",
                        e.target.value
                      )
                      validateField(index, "nok.lastname", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Phone Number"
                  // name="nokPhoneNumber"
                  validateStatus={errors[index]?.["nok.phone"] ? "error" : ""}
                  help={errors[index]?.["nok.phone"]}
                >
                  <Input
                    value={details.nok.phone}
                    onChange={(e) =>{
                      handlePassengerChange(index, "nok.phone", e.target.value)
                      validateField(index, "nok.phone", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  // name="nokEmail"
                  validateStatus={errors[index]?.["nok.email"] ? "error" : ""}
                  help={errors[index]?.["nok.email"]}
                >
                  <Input
                    value={details.nok.email}
                    onChange={(e) =>{
                      handlePassengerChange(index, "nok.email", e.target.value)
                      validateField(index, "nok.email", e.target.value);}
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      ))}
    </div>
  );
}

export default PersonalDetails;