import React from 'react'
import { Typography} from 'antd'
import HistoryList from './historyList'

function HistoryContent() {
  return (
    <div className='history-wrapper'>
        <div className='history-content'>
            <Typography className='history-header'>Booking history</Typography>
            <HistoryList
            status='In Progress'
            tripType='Round trip'
            origin='ABUJA, UTAKO Terminal'
            destination='LAGOS, JIBOWU Terminal'
            date='Mar-14-2024'/>
            <HistoryList
            status='Completed'
            tripType='One Way'
            origin='ABUJA, UTAKO Terminal'
            destination='LAGOS, JIBOWU Terminal'
            date='Dec-17-2023'/>
            <HistoryList
            status='Completed'
            tripType='Round trip'
            origin='ABUJA, UTAKO Terminal'
            destination='LAGOS, JIBOWU Terminal'
            date='Oct-14-2023'/>
            <HistoryList
            status='Failed'
            tripType='One Way'
            origin='ABUJA, UTAKO Terminal'
            destination='LAGOS, JIBOWU Terminal'
            date='Oct-17-2023'/>
        </div>
    </div>
  )
}

export default HistoryContent
