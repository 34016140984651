import React from "react";
import { Modal } from "antd";
import AccountImage from "../../asset/account-successful-img.png";

const AccountSuccessModal = ({
  isAccountModalVisible,
  accountNumber,
  setIsAccountModalVisible,
}) => {
  const modalStyle = {
    textAlign: "center",
    padding: 0,
  };

  const contentStyle = {
    padding: "0px",
  };

  const titleStyle = {
    color: "#4A90E2",
    fontWeight: 600,
  };

  const imageStyle = {
    maxWidth: "80%",
    height: "auto",
    marginTop: "10px",
  };
  const handleclose = () => {
    setIsAccountModalVisible(false);
    window.location.replace("https://youthplus.ng/");
  };

  return (
    <Modal
      visible={isAccountModalVisible}
      footer={null}
      onCancel={handleclose}
      closable={true}
      bodyStyle={modalStyle}
      centered
    >
      <div style={contentStyle}>
        <h3 style={titleStyle}>Congratulations!</h3>
        <p>
          Your BluEdge Savings Account Number is <b>{accountNumber}</b>
        </p>
        <img src={AccountImage} alt="Account Success" style={imageStyle} />
      </div>
    </Modal>
  );
};

export default AccountSuccessModal;
