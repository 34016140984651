import React from 'react'
import {Layout, Menu, Divider} from "antd";
import {
   
    LogoutOutlined,
    LockOutlined
 
  } from "@ant-design/icons";
  import Logo from "../asset/youthpluswhite.png";
  import Fav from "../asset/ypsmall.png";
  import MSL from "../asset/msl-logo.png";
  import NYSC from "../asset/nysc-logo.png";
  import STANBIC from "../asset/stanbic_icon.png";
  import QTT from "../asset/quicktellerlogo.png";

  const { Sider } = Layout;

  const menuList =[
    {
      key:'1',
      partner:"MSL",
      image:MSL
    },
    {
      key:'2',
      partner:"NYSC",
      image:NYSC
    },
    {
      key:'3',
      partner:"STANBICIBTC",
      image: STANBIC
    },
    {
      key:'4',
      partner:"QUICKTELLER",
      image: QTT
    }
  ]

const SiderComponent = ({userDatafromlocalstorage,handleMenuClick,handleUserMenuClick, selectedMenuItem, collapsed, selectedUserMenu}) => {

  return (
    <div>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          background: 'rgba(8, 131, 155, 1)',
          position: 'fixed',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div className='sider-header'  style={{padding:"1rem" }}>
          <img src={collapsed ? Fav : Logo}
              alt="YouthPlusLogo"
              style={{
                width: collapsed ? "35px" : "170px",
                height: collapsed && "35px",
                marginBottom: "8px",
              }}
            />
        </div>
        <div className='sider-body' style={{ textAlign: "center", padding: "16px 0", marginTop:'2.5rem'}}>
          {!collapsed && (
            <p style={{ color: "#fff", fontSize: collapsed ? "10px" : "16px",fontWeight:collapsed ? "" : "500" }}>
              Partners Dashboard
            </p>
          )}
        
          {/* <div style={{ padding: "0 16px" }}> */}
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedMenuItem]}
            onClick={handleMenuClick}
            style={{ background: "rgba(8, 131, 155, 1)", marginTop:"1rem"}}
          >
            {userDatafromlocalstorage?.user_type === "SUPERADMIN" && (
              <>
              {menuList.map((partners, index) => (
                <Menu.Item
                  key={partners.key}
                  icon={<img src={partners.image} alt="msl" style={{ width: 20 }} />}
                >
                  {`${partners.partner} Dashboard`}
                </Menu.Item>
              ))}
              </>
            )}
            {(userDatafromlocalstorage?.user_type === "MSL" ||
              userDatafromlocalstorage?.user_type === "NYSC" ||
              userDatafromlocalstorage?.user_Type === "STAnBIC" ||
              userDatafromlocalstorage?.user_Type === "QUICKTELLER") && (
              <>
                {(userDatafromlocalstorage?.user_type === "MSL" ||
                  userDatafromlocalstorage?.user_type === "SUPERADMIN") && (
                  <Menu.Item
                    key="1"
                    icon={<img src={MSL} alt="msl" style={{ width: 20 }} />}
                  >
                    MSL Dashboard
                  </Menu.Item>
                )}
                {(userDatafromlocalstorage?.user_type === "NYSC" ||
                  userDatafromlocalstorage?.user_type === "SUPERADMIN") && (
                  <Menu.Item
                    key="2"
                    icon={<img src={NYSC} alt="nysc" style={{ width: 20 }} />}
                  >
                    NYSC Dashboard
                  </Menu.Item>
                )}
                {(userDatafromlocalstorage?.user_type === "STANBIC" ||
                  userDatafromlocalstorage?.user_type === "SUPERADMIN") && (
                  <Menu.Item
                    key="3"
                    icon={<img src={STANBIC} alt="nysc" style={{ width: 20 }} />}
                  >
                    StanbicIBTC Dashboard
                  </Menu.Item>
                )}
                {(userDatafromlocalstorage?.user_type === "QUICKTELLER" ||
                  userDatafromlocalstorage?.user_type === "SUPERADMIN") && (
                  <Menu.Item
                    key="4"
                    icon={<img src={QTT} alt="nysc" style={{ width: 20 }} />}
                  >
                    QiuickTeller Dashboard
                  </Menu.Item>
                )}
              </>
            )}
          </Menu>
        </div>
        <div style={{ position:'absolute',bottom:0,right:0,left:0 }}>
          <Divider style={{ backgroundColor: '#fff', height: '1px', padding: '0 10px' }}/>
          <Menu
            theme="dark"
            mode="inline"
            style={{ background: 'rgba(8, 131, 155, 1)', }}
            onClick={handleUserMenuClick}
          >
            <Menu.Item key="reset-password" icon={<LockOutlined  style={{ width: 20 }}/>}>
              Reset Password
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
    </div>
  )
}

export default SiderComponent