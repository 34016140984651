import React, { useEffect } from "react";
import { Typography, Input, Button, Row, Col } from "antd";
import { useNavigate } from "react-router-dom"; // If you're using react-router for navigation
import Origin from "../asset/origin.svg";
import Destination from "../asset/destination.svg";

function BookingSummary({
  voucher,
  handleVoucher,
  quantity,
  price,
  fee,
  selectedTrip,
  timer,
  onPayNow,
  onSuccess,
}) {
  const navigate = useNavigate(); // Initialize useNavigate

  const totalPassengerPrice = price * quantity;
  const totalPrice = parseInt(totalPassengerPrice) + fee;

  // Effect to handle the timer reaching zero
  useEffect(() => {
    if (timer === 0) {
      navigate("/transport"); // Redirect to the transport page
    }
  }, [timer, navigate]);

  return (
    <div className="second-col">
      <Typography className="summary-header">
        Your Booking{" "}
        {timer !== null && (
          <span
            style={{ fontSize: "24px", fontFamily: "revert", color: "red" }}
          >
            {Math.floor(timer / 60)
              .toString()
              .padStart(2, "0")}
            :{(timer % 60).toString().padStart(2, "0")}
          </span>
        )}
      </Typography>
      <div className="booking-col">
        <div className="route-wrapper">
          <div className="route">
            <div className="route-box" style={{ marginBottom: "0.5rem" }}>
              <img src={Origin} alt="icon" />
              <Typography style={{ marginLeft: "1rem" }}>
                {selectedTrip.origin}
              </Typography>
            </div>
            <Typography>{selectedTrip.departureTime}</Typography>
          </div>
          <div className="route">
            <div className="route-box">
              <img src={Destination} alt="icon" />
              <Typography style={{ marginLeft: "1rem" }}>
                {selectedTrip.destination}
              </Typography>
            </div>
            <Typography>{selectedTrip.arrivalTime}</Typography>
          </div>
        </div>
      </div>
      <div className="summary-col">
        <Typography>{quantity} passenger(s)</Typography>
        <Typography style={{ fontSize: "1rem", fontWeight: "500" }}>
          ₦{totalPassengerPrice}
        </Typography>
      </div>
      <hr />
      <div className="summary-col">
        <Typography>Service Fee</Typography>
        <Typography style={{ fontSize: "1rem", fontWeight: "500" }}>
          ₦{fee}
        </Typography>
      </div>
      <hr />
      <div className="summary-col">
        <Typography style={{ fontSize: "1rem", fontWeight: "500" }}>
          Total Price (incl. TAX)
        </Typography>
        <Typography style={{ fontSize: "1rem", fontWeight: "500" }}>
          ₦{totalPrice}
        </Typography>
      </div>
      <div style={{ marginTop: "2rem" }}>
        <Typography className="input-label">Enter Voucher</Typography>
        <Row gutter={16}>
          <Col md={16} xs={12}>
            <Input
              placeholder="Enter Code"
              value={voucher}
              onChange={handleVoucher}
            />
          </Col>
          <Col md={8} xs={12}>
            <Button
              block
              style={{
                backgroundColor: "#4CAF50",
                borderColor: "#4CAF50",
                color: "#fff",
              }}
            >
              Redeem
            </Button>
          </Col>
        </Row>
      </div>

      <Button
        type="primary"
        onClick={onPayNow}
        style={{
          backgroundColor: "#048181",
          borderColor: "#048181",
          color: "#fff",
          marginTop: "1rem",
        }}
        className={`${onSuccess ? "show" : "hide"}`}
      >
        Pay now
      </Button>
    </div>
  );
}

export default BookingSummary;
