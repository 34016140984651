import { useEffect, React, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Form, Input, Button, Col } from "antd";
import logo from "../../asset/Youthplus3DW.png";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [success, setSuccess] = useState(false);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  // eslint-disable-next-line
  const hasSpecialChar = /[!@#$%^&*(),.?\":{}|<>+=._-]/.test(password);

  const validation =
    hasUppercase && hasNumber && hasSpecialChar && password.length >= 8;

  const formPassRef = useRef(null);
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Must be at least 8 characters");
    }
    if (!hasUppercase) {
      errors.push("Must contain a capital letter");
    }
    if (!hasNumber) {
      errors.push("Must contain a number");
    }
    if (!hasSpecialChar) {
      errors.push("Must contain a special character");
    }
    return errors;
  };

  useEffect(() => {
    if (!hasStartedTyping) return;

    const formPass = formPassRef.current;
    const errors = validatePassword(password);
    formPass.innerHTML = ""; // Clear existing errors
    errors.forEach((error) => {
      const list = document.createElement("p");
      list.classList.add("newp");
      list.textContent = error;
      formPass.appendChild(list);
    });
    // eslint-disable-next-line
  }, [hasStartedTyping, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    if (validation) {
      setLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PARTNER_RESET_PASSWORD}`,
          {
            token: token,
            new_password: password,
          }
        );
        if (response.status === 200) {
          toast.success("Password reset successful");
          setSuccess(true);
        } else {
          toast.error("Password reset failed");
        }
      } catch (error) {
        toast.error("An error occurred while resetting the password");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Fill in a strong password");
    }
  };

  return (
    <div style={{ backgroundColor: "#F1F1F2", height: "100%" }}>
      <div
        style={{
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0.3rem",
        }}
      >
        <img src={logo} style={{ height: "30px" }} alt="" />
        <h2
          style={{
            color: "#0F0F0F",
            fontSize: "1.2rem",
            fontWeight: "700",
            paddingTop: "0.3rem",
          }}
        >
          youthplus
        </h2>
      </div>

      <div
        style={{
          margin: "0 auto",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "1rem",
          gap: "0.4rem",
          maxWidth: "600px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          {success ? (
            <svg
              width="74"
              height="74"
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.25" cx="37" cy="37" r="37" fill="#08839B" />
              <circle opacity="0.4" cx="37" cy="37" r="28" fill="#08839B" />
              <path
                d="M53.6666 35.4764V37.0097C53.6645 40.6037 52.5008 44.1008 50.3488 46.9794C48.1969 49.858 45.1721 51.9638 41.7255 52.9829C38.279 54.0019 34.5954 53.8795 31.224 52.634C27.8527 51.3885 24.9743 49.0865 23.0182 46.0715C21.062 43.0564 20.1329 39.4898 20.3694 35.9036C20.6059 32.3173 21.9953 28.9036 24.3304 26.1715C26.6655 23.4394 29.8212 21.5353 33.3269 20.7433C36.8326 19.9512 40.5004 20.3136 43.7833 21.7764M53.6666 23.6668L36.9999 40.3502L31.9999 35.3502"
                stroke="#08839B"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              style={{ marginBottom: "1rem" }}
              width="64"
              height="64"
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.25" cx="37" cy="37" r="37" fill="#08839B" />
              <circle opacity="0.4" cx="37" cy="37" r="28" fill="#08839B" />
              <path
                d="M45.3333 31.9999C45.3333 31.1469 45.0079 30.2938 44.357 29.643C43.7061 28.9921 42.8531 28.6667 42 28.6667M42 42C47.5228 42 52 37.5228 52 32C52 26.4772 47.5228 22 42 22C36.4772 22 32 26.4772 32 32C32 32.4561 32.0305 32.9051 32.0897 33.3451C32.187 34.0686 32.2356 34.4304 32.2029 34.6593C32.1688 34.8978 32.1253 35.0262 32.0078 35.2365C31.895 35.4383 31.6962 35.6372 31.2986 36.0348L22.781 44.5523C22.4928 44.8405 22.3487 44.9847 22.2456 45.1529C22.1542 45.302 22.0869 45.4646 22.0461 45.6346C22 45.8264 22 46.0303 22 46.4379V49.3333C22 50.2668 22 50.7335 22.1817 51.09C22.3414 51.4036 22.5964 51.6586 22.91 51.8183C23.2665 52 23.7332 52 24.6667 52H28.6667V48.6667H32V45.3333H35.3333L37.9652 42.7014C38.3629 42.3038 38.5617 42.105 38.7635 41.9922C38.9738 41.8747 39.1022 41.8312 39.3407 41.7971C39.5696 41.7644 39.9314 41.813 40.6549 41.9103C41.0949 41.9695 41.5439 42 42 42Z"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}

          <h5 style={{}}>Set new password</h5>
          <p
            style={{
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "15px",
              margin: "0 auto",
              padding: "0",
              maxWidth: "400px",
            }}
          >
            {success
              ? "Your password has been successfully reset. Click the button below to login."
              : "Enter your new password below to complete your password reset process."}
          </p>
        </div>

        {success ? (
          <div className="form-div" style={{ margin: "1rem auto" }}>
            <Form layout="vertical">
              <Col span={25}>
                <Form.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    loading={loading}
                    disabled={loading}
                    style={{
                      backgroundColor: "#08839B",
                      color: "#ffffff",
                      height: "50px",
                      maxWidth: "400px",
                    }}
                    onClick={() => {
                      handleSubmit();
                      navigate("/partner-dashboard");
                    }}
                  >
                    {loading ? "loading" : "Login with new password"}
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </div>
        ) : (
          <div className="form-div" style={{ margin: "1rem auto" }}>
            <Form layout="vertical">
              <Col span={25}>
                <Form.Item label="New password">
                  <Input.Password
                    style={{
                      height: "50px",
                      fontSize: "14px",
                      maxWidth: "400px",
                    }}
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => {
                      setHasStartedTyping(true);
                      setPassword(e.target.value);
                    }}
                  />
                  <div id="formPassword" ref={formPassRef}></div>
                </Form.Item>
                <Form.Item label="Confirm password">
                  <Input.Password
                    style={{
                      height: "50px",
                      fontSize: "14px",
                      maxWidth: "400px",
                    }}
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      if (password !== e.target.value) {
                        setCheck(true);
                      } else {
                        setCheck(false);
                      }
                    }}
                  />
                  {check && (
                    <p
                      style={{
                        textAlign: "left",
                        padding: "0",
                        margin: "0",
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      Must be identical to the password above
                    </p>
                  )}
                </Form.Item>

                <Form.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    loading={loading}
                    disabled={loading}
                    style={{
                      backgroundColor: "#08839B",
                      color: "#ffffff",
                      height: "50px",
                      maxWidth: "400px",
                    }}
                    onClick={handleSubmit}
                  >
                    {loading ? "loading" : "Reset Password"}
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
