import React, { useState } from "react";
import { Card, Row, Col, Button, Modal, Image } from "antd";
// import { useNavigate } from "react-router-dom";
import {
  ArrowRightOutlined,
  UsergroupAddOutlined,
  StopFilled,
} from "@ant-design/icons";
import ArrowLine from "../asset/arrowLine.png";

const BookingDetail = ({ trips = [], onSelectTrip }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);

  // const navigate = useNavigate();

  // const handleContinue = () => {
  //   navigate("/trip-details");
  //   console.log("Continue button clicked");
  // };

  const handleModalOpen = (trip) => {
    setSelectedTrip(trip);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedTrip(null);
  };

  if (!Array.isArray(trips) || trips.length === 0) {
    return (
      <div style={{ padding: "2rem", textAlign: "center" }}>
        <p>No trips available. Please search for trips.</p>
      </div>
    );
  }

  return (
    <>
      {trips.map((trip, index) => (
        <Card
          key={index}
          className="booking-card-styles"
          style={{ padding: "0px", marginBottom: "1rem" }}
        >
          <Button
            style={{
              backgroundColor: "rgba(181, 195, 194, 1)",
              borderRadius: "5rem",
              marginBottom: "1rem",
            }}
          >
            {trip.operatorName}
          </Button>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={17} className="fontam-style">
              <div style={{ margin: "10px" }}>
                <Row align="middle" className="ABC-styles">
                  <Col span={9}>
                    <h4>{trip.origin}</h4>
                  </Col>
                  <Col span={4} style={{ textAlign: "center" }}>
                    <img
                      src={ArrowLine}
                      alt="arrow"
                      style={{ width: "80%", paddingBottom: " 0.5rem" }}
                    />
                  </Col>
                  <Col span={10}>
                    <h4>{trip.destination}</h4>
                  </Col>
                </Row>
                <Row gutter={2} align="middle" className="city-style">
                  <Col span={16}>
                    <span>{trip.departureTime}</span>
                  </Col>
                </Row>
                <Row gutter={[0, 10]} md={24} style={{ marginTop: "1.6rem" }}>
                  <Col md={24} lg={12} xl={12}>
                    <Button
                      onClick={() => handleModalOpen(trip)}
                      className="bus-btn"
                      style={{ margin: "1rem 1rem" }}
                    >
                      <img
                        src={trip.busImage}
                        alt="Bus"
                        style={{ marginRight: "10px", height: "24px" }}
                      />
                      Bus | Direct
                      <ArrowRightOutlined
                        style={{
                          marginLeft: "15px",
                          marginBottom: "2px",
                          marginRight: "5rem",
                        }}
                      />
                    </Button>
                  </Col>
                  <Col
                    style={{
                      gap: "0.7rem",
                      marginTop: "1rem",
                    }}
                    md={24}
                    lg={24}
                    xl={12}
                    className="Icon-style"
                  >
                    {trip.availableSeats <= 5 && (
                      <p style={{ fontWeight: "bold", marginTop: "0.2rem" }}>
                        <span style={{ marginRight: "2px" }}>
                          <UsergroupAddOutlined />{" "}
                        </span>
                        Almost Full
                      </p>
                    )}
                    <p>
                      <p>
                        <span style={{ marginRight: "5px" }}>
                          <StopFilled />
                        </span>
                        They are <b>{trip.noOfStops}</b> number of stop(s)
                      </p>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                margin: "10px",
              }}
              xs={12}
              md={11}
              lg={4}
            >
              <div className="money-price">
                <div>
                  <h4>₦{trip.price || "24,000.00"}</h4>
                  <span style={{ margin: "0.5rem 0rem" }}>per person</span>
                </div>
                <div>
                  <p
                    style={
                      trip.availableSeats <= 5
                        ? { color: "red" }
                        : { color: "black" }
                    }
                  >
                    <b>{trip.availableSeats}</b> seats left at this price
                  </p>

                  <Button
                    style={{ width: "80%", marginTop: "1rem" }}
                    type="primary"
                    onClick={() => onSelectTrip(trip)}
                  >
                    {"Continue >"}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      ))}
      <Modal
        title={selectedTrip?.operatorName}
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        {selectedTrip && (
          <div style={{ textAlign: "center" }}>
            <Image
              src={selectedTrip.operatorLogo}
              alt={selectedTrip.operatorName}
              width={100}
            />
            <p>
              One of Nigeria’s largest bus service connecting thousands of
              communities
            </p>
            <Image src={selectedTrip.busImage} alt="Bus" />
          </div>
        )}
      </Modal>
    </>
  );
};

export default BookingDetail;
