import React from 'react'
import {  Stack, Typography} from '@mui/material'
import Help from '../asset/help.svg'
import Ticket from '../asset/ticket.svg'
import { Link } from 'react-router-dom'
import './header.css'
import SearchTrip from './searchTrip'



function TransportBanner() {
  return (
    <div className='banner-styles'>
        <div className='banner-box'></div>
        <div className='search-box'>
          <SearchTrip
          buttonText="Proceed"
          // destination="/book-trips"
          />
        </div>
        <Stack direction={{ xs: 'column', sm: 'row' }}  spacing={3} className='banner-buttons'>
            <Link to="/booking-history" className='bookings-button'>
                <img src={Ticket} alt='icon' className='icons'/>
                <Typography className='text-buttons'>Manage Booking</Typography>
            </Link>
            <div className='bookings-button'>
                <img src={Help} alt='icon' className='icons'/>
                <Typography className='text-buttons'>Help</Typography>
            </div>
        </Stack>
    </div>
  )
}

export default TransportBanner