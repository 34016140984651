import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Radio,
  Button,
  DatePicker,
  Card,
  notification,
  Spin,
  Select,
  Dropdown,
} from "antd";
import dropdownArrow from '../asset/dropdown arrow.png'
import { SwapOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import English locale
import { useNavigate } from "react-router-dom";
import { useApi } from "../Context/apiContext";

// Make sure to set the locale to English
dayjs.locale("en");
const { Option } = Select;
const Counter = ({ label, count, setCount }) => (
  <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
    <Button
      onClick={(e) => {
        e.stopPropagation();
        setCount(count - 1);
      }}
      disabled={count <= 0}
      style={{ marginRight: "1rem" }}
    >
      -
    </Button>
    <span style={{ marginRight: "1rem" }}>{count}</span>
    <Button
      onClick={(e) => {
        e.stopPropagation();
        setCount(count + 1);
      }}
    >
      +
    </Button>
    <span style={{ marginLeft: "1rem" }}>{label}</span>
  </div>
);

const PassengerDropdown = ({
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
}) => {
  const menu = (
    <div
      style={{
        backgroundColor: "#ffffff",
        border: "#000000",
        padding: "0.5rem",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Counter label="Adults" count={adultCount} setCount={setAdultCount} />
      <Counter label="Children" count={childCount} setCount={setChildCount} />
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button style={{ width: "100%", overflow: "hidden", }}>
        {adultCount} Adults, {childCount} Children
        <img src={dropdownArrow} alt="dropdown-arrow" style={{ marginLeft: '8px', width: '16px', height: '16px' }} />
      </Button>
    </Dropdown>
  );
};

const SearchTrip = (prop) => {
  const { apiCall } = useApi();
  const navigate = useNavigate();
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [tripType, setTripType] = useState("oneWay");
  const [departureDate, setDepartureDate] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [filteredFromLocations, setFilteredFromLocations] = useState([]);
  const [filteredToLocations, setFilteredToLocations] = useState([]);


  useEffect(() => {
    const fetchLocations = async (e) => {
      if (!navigator.onLine) {
        alert("No Internet");
        return;
      } else {
        // setLoading(true); 
      try {
        const response = await apiCall(
          "/api/v1/booking/terminals",
          "GET",
          null,
          true // Use QuickTeller API instance
        );
        setLocations(response);
      } catch (error) {
        console.error("Error fetching locations:", error);
        notification.error({
          message: "Backend Error",
          description:
            error.response?.data?.message ||
            "There was an error fetching locations. Please try again.",
        });
      }
    }
    };

    fetchLocations();
  }, [apiCall]);

  const handleFromLocationSearch = (value) => {
    const filteredLocations = locations.filter(
      (location) =>
        location.name.toLowerCase().includes(value.toLowerCase()) ||
        location.code.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredFromLocations(filteredLocations);
  };
  
  const handleToLocationSearch = (value) => {
    const filteredLocations = locations.filter(
      (location) =>
        location.name.toLowerCase().includes(value.toLowerCase()) ||
        location.code.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredToLocations(filteredLocations);
  };
  
  const handleFromLocationSelect = (value) => {
    setFromLocation(value);
    setFilteredFromLocations([]);
  };
  
  const handleToLocationSelect = (value) => {
    setToLocation(value);
    setFilteredToLocations([]);
  };
  
  const handleSwitchLocations = () => {
    const tempLocation = fromLocation;
    setFromLocation(toLocation);
    setToLocation(tempLocation);
  };

  const handleSearch = async () => {
    if (!fromLocation || !toLocation || !departureDate) {
      notification.error({
        message: "Validation Error",
        description: "Please fill in the required fields.",
      });
      return;
    }

    if (tripType === "roundTrip" && !returnDate) {
      notification.error({
        message: "Validation Error",
        description: "Please select a return date for a round trip.",
      });
      return;
    }

    // Prepare the payload for the API call
    const payload =
      // {
      //   origin: "Asaba",
      //   destination: "Yaba",
      //   departureDate: "2024-06-21",
      //   returnDate: "",
      //   noOfAdults: 1,
      //   noOfChildren: 0,
      // };
      {
        origin: fromLocation,
        destination: toLocation,
        departureDate: departureDate
          ? departureDate.format("YYYY-MM-DD")
          : null,
        returnDate:
          tripType === "roundTrip" ? returnDate.format("YYYY-MM-DD") : "",
        noOfAdults: adultCount,
        noOfChildren: childCount,
      };

    setLoading(true);
    try {
      const response = await apiCall(
        "/onboard/qt/searchtrip/",
        "POST",
        payload
      );
      console.log("Search results:", response);

      const trips = response.message?.oneWay?.content || [];
      setLoading(false);

      if (!Array.isArray(trips) || trips.length === 0) {
        notification.error({
          message: "No Available Trips",
          description: `
            There are no available trips for the selected destination or date of travel. 
          `,
        });
      } else {
        notification.success({
          message: "Search Complete",
          description: "Trips have been successfully fetched.",
        });
      }


      navigate("/book-trips", { state: { trips } });
      prop.setDepartureDate(departureDate)
    } catch (error) {
      setLoading(false);
      console.error("Error searching trips:", error);
      notification.error({
        message: "Error",
        description:
          error.response?.data?.message ||
          "There was an error searching for trips. Please try again.",
      });
    }
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().endOf("day");
  };

  const handleDateChange = (date) => {
    setDepartureDate(date);
  };

  const handleReturnDateChange = (date) => {
    setReturnDate(date);
  };

  return (
    <div style={{ padding: "2rem 10%" }}>
      <Card style={{ width: "100%" }}>
        <div style={{ margin: "1rem 0" }}>
          <Radio.Group
            defaultValue="oneWay"
            buttonStyle="solid"
            onChange={(e) => setTripType(e.target.value)}
          >
            <Radio.Button value="oneWay">One way</Radio.Button>
            <Radio.Button value="roundTrip">Round Trip</Radio.Button>
          </Radio.Group>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={5}>
            <Select
              style={{ width: "100%" }}
              placeholder="From"
              value={fromLocation}
              onChange={handleFromLocationSelect}
              onSearch={handleFromLocationSearch}
              showSearch
            >
              {(filteredFromLocations.length > 0 ? filteredFromLocations : locations).map((location) => (
              <Option key={location.code} value={location.code}>
              {location.name}
              </Option>
              ))}
            </Select>
          </Col>

          <Col xs={24} md={1} style={{ textAlign: "center" }}>
            <Button icon={<SwapOutlined />} onClick={handleSwitchLocations} />
          </Col>

          <Col xs={24} md={5}>
            <Select
              style={{ width: "100%" }}
              placeholder="To"
              value={toLocation}
              onChange={handleToLocationSelect}
              onSearch={handleToLocationSearch}
              showSearch
            >
              {(filteredToLocations.length > 0 ? filteredToLocations : locations).map((location) => (
              <Option key={location.code} value={location.code}>
              {location.name}
              </Option>
              ))}
            </Select>
          </Col>

          <Col xs={24} md={5}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Departure Date"
              format="YYYY/MM/DD"
              disabledDate={disabledDate}
              value={departureDate}
              onChange={handleDateChange}
            />
          </Col>
          {tripType === "roundTrip" && (
            <Col xs={24} md={5}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Return Date"
                format="YYYY/MM/DD"
                disabledDate={disabledDate}
                value={returnDate}
                onChange={handleReturnDateChange}
              />
            </Col>
          )}
          <Col xs={24} md={6}>
            <PassengerDropdown
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
            />
          </Col>

          <Col xs={24} md={4}>
            <Button
              type="primary"
              onClick={handleSearch}
              className="Searchy-btn"
              style={{ width: "100%" }}
              disabled={loading}
            >
              {loading ? <Spin /> : "Search"}
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SearchTrip;
