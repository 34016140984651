import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchTrip from "../../Components/searchTrip";
import BookingDetail from "../../Components/bookingDetail";
import BookingNav from "../../Components/bookingNav";
import TransportHeader from "../../Components/transportheader";
import TransportFooter from "../../Components/transportfooter";

const BookTrip = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const trips = location.state?.trips || [];
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleSelectTrip = (trip) => {
    navigate("/trip-details", { state: { selectedTrip: trip } });
  };

  return (
    <div
      className="book-trip-styles"
      style={{
        backgroundColor: "rgba(241, 241, 241, 1)",
      }}
    >
      <TransportHeader />
      <SearchTrip
        departureDate={selectedDate}
        setDepartureDate={setSelectedDate}
      />
      <BookingNav
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <BookingDetail trips={trips} onSelectTrip={handleSelectTrip} />
      <TransportFooter />
    </div>
  );
};

export default BookTrip;
