import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TransportHeader from "../../Components/transportheader";
import PassangersInfoForm from "../../Components/passangersInfo";
import "./passengersDetails.css"; // Create a CSS file for styling
import { Row, notification } from "antd";
import TicketCard from "../../Components/tripSummary";
import { useApi } from "../../Context/apiContext";
import { decryptData } from "../../utils/encryptionUtils";
import Empty from "../../asset/10.png"
import Loader from "../../Components/pageLoader";


const NoDataAvailable = () => {
  return (
    <div style={{ padding: "2rem", textAlign: "center", margin: "auto" }}>
      <img src={Empty} alt="Empty-illustraqtion" width="50%" />
      <p style={{ fontSize: "1.5rem" }}>Ticket details is not available...</p>
    </div>
  );
};

const PassengersDetails = () => {
  const { apiCall } = useApi();
  const [ticketDets, setTicketDets] = useState(null);
  const [paymentDets, setPaymentDets] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const query = new URLSearchParams(location.search);
  const providerId = query.get("providerId");
  const encodedData = query.get("passengerDetails");
  const message = query.get("message");

  const decryptedData = decryptData(encodedData);

  const initiateQtPayment = async () => {
    const payload = {
      customerId: "knowbot@sidmach.com",
      amount: decryptedData?.amount,
      currency: "NGN",
      authData: "NILL",
      instrumentIdentifier: "",
      customerName: "Youthplus",
      customerEmail: "knowbot@sidmach.com",
      sessionId: decryptedData?.session_Id,
    };

    try {
      setLoading(true); 
      const response = await apiCall(
        "/onboard/qt/initiatepaymentdataview/",
        "POST",
        payload
      );
      setPaymentDets(response);
    } catch (error) {
      notification.error({
        message: "Payment details error",
        description:
          error.response?.data?.message ||
          "There was an error fetching payment details. Please try again.",
      });
    } finally {
      setLoading(false); 
    }
  };
  const getTicketDetails = async () => {
    if (!paymentDets?.message?.transactionReference) return;

    const payload = {
      ticketReference: paymentDets.message.transactionReference,
      providerId: providerId,
    };

    try {
      setLoading(true); 
      const response = await apiCall(
        "/onboard/qt/ticketdetails/",
        "POST",
        payload
      );
      setTicketDets(response);
    } catch (error) {
      notification.error({
        message: "Ticket details error",
        description:
          error.response?.data?.message ||
          "There was an error fetching ticket details. Please try again.",
      });
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    console.log("useEffect triggered with message:", message);
    if (message === "Successful") {
      initiateQtPayment();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (paymentDets?.message?.transactionReference) {
      getTicketDetails();
    }
    // eslint-disable-next-line
  }, [paymentDets]);

  return (
    <div
      style={{ backgroundColor: "rgba(241, 241, 241, 1)", minHeight: "100vh" }}
    >
      <TransportHeader />
    
      {loading ? (
        <Loader/>
      )
      :ticketDets ? (
        <Row style={{ margin: "4rem 1rem" }} gutter={[16, 16]}>
          <PassangersInfoForm ticketDetails={ticketDets} />
          <TicketCard 
          ticketDetails={ticketDets}
          loading={loading}
           />
        </Row>
      ) : (
        <Row style={{ margin: "4rem 1rem" }} gutter={[16, 16]}>
          <NoDataAvailable />
        </Row>
      )}
    </div>
  );
};

export default PassengersDetails;
