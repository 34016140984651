import React from 'react';
import "./Terms.css";
import { Link } from "react-router-dom";



const Terms = () => {
    return (
        <div>
            <div className='youthplus-policy'>
                <h3 className='policy-header1'>TERMS OF USE</h3>
                <p>
                    By using this platform, any of our websites, and/or services, you agree to these Terms of Use. The website Privacy Policy, Terms of Use document, Privacy Policy, No-Refund Policy, Acceptable Use Policy, and Merchant Terms of Service (where applicable) are incorporated by reference into these Terms of Use.
                </p>
                <p>    
                    You must read, agree with, and accept the terms and conditions contained in this Agreement, before you may become a corporate or individual user of the YouthPlus platform or mobile application. The onus is on you to access this Agreement, as well as any amendments made thereto and to contact Sidmach Technologies Nigeria Limited in the event of any queries you may have.
                </p>
                <h3 className='policy-headers3'>1.0 Respect</h3>
                <p>
                    We want to maintain a respectful environment for everyone, which means you must follow these basic rules of conduct:
                </p>
                <ul>
                    <li>comply with applicable laws, sanctions,</li>
                	<li>respect the rights of others, including privacy and intellectual property rights</li>
               	    <li>
                        don’t abuse or harm others or yourself (or threaten or encourage such abuse or harm) — for example, by misleading, defrauding, illegally impersonating, defaming, bullying, harassing, or stalking others
                    </li>
                    <li>
                        don’t abuse, harm, interfere with, or disrupt the services — for example, by accessing or using them in fraudulent or deceptive ways, introducing malware, or spamming, hacking, or bypassing our systems or protective measures. When we index the web to bring you search results, we respect standard usage restrictions that website owners specify in their website's code, so we require the same when others use our services.

                    </li>
                </ul>
                <h3 className='policy-headers3'>2.0 Age Restriction</h3>
                <p>We want you to know that this platform is targeted to the following groups:</p>
                <ul>
                    <li>
                        <b>Youths</b>
                        <p>
                            Secondary School Students, Secondary School Leavers, Admission Seekers, Undergraduates, Graduates, Youth Corps Members, and relevant Employed and Unemployed Young People.
                        </p>
                    </li>

                	<li>
                        <b>Youth Organisations</b>
                        <p>
                            NGOs, Religious Institutions, Academic Institutions, Pressure groups, Social groups, Influencers, etc.
                        </p>
                    </li>

                	<li>
                        <b>Youth Administrators/Partners/Sponsors/Service providers</b>
                        <p>
                            Ministry of Youth & Sports, NYSC, Office of the Special Adviser on Youth or related matters, Sports organizations, Creative Agencies, Corporate organizations, Professional bodies, Government, possible relevant foreign Intervention Agencies
                        </p>
                    </li>
                </ul>
                <h3 className='policy-headers3'>3.0 Accounts</h3>
                <p>

                    Certain features of the YouthPlus platform may require you to be a registered user, requiring you to register an account on the platform and or with our value-added service partners.  You agree that by registering an account on the platform, you will provide information that is accurate, true, and correct and that you will be responsible to maintain the confidentiality of your account and registration details, including your password. You agree not to allow anyone else to have or use your password details and to comply with all reasonable instructions we may issue regarding account access and security. In the event you share your password details, we will not be liable to you for losses or damages. You will also take all reasonable steps to protect the security of the personal electronic device through which you access our services. You agree to notify us immediately of any unauthorized use of your account or password or any other breach of your account. We have the right to disable any user account, at any time, if in our reasonable opinion, you have failed to comply with any of the provisions of these terms.
                </p>
                <h3 className='policy-headers3'>4.0	Limitation of Liability</h3>
                <p>
                    Neither SIDMACH nor any of its subsidiaries, will under any circumstances, be liable to you or any other party for lost profits, third-party claims, or any other special, incidental, punitive, indirect, or consequential damages, regardless of the form of action, even if has been notified of the possibility of such damages or they are foreseeable. The limitations in this section shall apply whether or not the alleged breach or default is a breach of a fundamental condition or term or a fundamental breach. We exclude, in so far as we are allowed, any warranties that would be implied by law of any applicable jurisdiction. Sidmach reserves all legal rights to recover damages or other compensation under these terms or as allowed by law.
                </p>
                <h3 className='policy-headers3'>5.0	Applicable Law</h3>
                <p>
                    These Terms of Use shall be interpreted and governed by the laws of the Federal Republic of Nigeria.
                </p>
                <h3 className='policy-headers3'>6.0	Governing Law; Dispute Resolution</h3>
                <p>
                    You agree that all matters relating to your access to or use of the platform, including all disputes, will be governed by the laws of Nigeria without regard to its conflicts of laws provisions.
                </p>
                <p>
                    We shall make an effort to settle all disputes amicably. Any dispute arising out of this Agreement that cannot be settled, by mutual agreement/negotiation within 1 (one) month shall be referred to arbitration by a single Arbitrator at the Lagos Multi-Door Courthouse (“LMDC”) and governed by the Arbitration and Conciliation Act, Cap A10, Laws of the Federal Republic of Nigeria. The Arbitrator shall be appointed by both of us (we and you), where both of us are unable to agree on the choice of an Arbitrator, the choice of arbitration shall be referred to the LMDC. The findings of the Arbitrator and subsequent award shall be binding on both of us. Each of us shall bear our respective costs in connection with the Arbitration. The venue for the arbitration shall be Lagos, Nigeria.
                </p>
                <h3 className='policy-headers3'>7.0	Disclaimers</h3>
                <p>
                    We try to keep the YouthPlus platform available at all times, bug-free and safe, however, you use it at your own risk.
                </p>
                <p>
                    Our website and services are provided “as is” without any express, implied, and/or statutory warranties (including, but not limited to, any implied or statutory warranties of merchantability, fitness for a particular use or purpose, title, and non-infringement of intellectual property rights). Without limiting the generality of the foregoing, YouthPlus makes no warranty that our website and services will meet your requirements or that our website will be uninterrupted, timely, secure, or error-free. No advice or information, whether oral or written, obtained by you through our website or from our payment platform, its parents, subsidiaries, or other affiliated companies, or its or their suppliers (or the respective officers, directors, employees, or agents of any such entities) (collectively, "YouthPlus parties") shall create any warranty.
                </p>
                <h3 className='policy-headers3'>8.0	Severability</h3>
                <p>
                    If any portion of these Terms of Use is held by any court or tribunal to be invalid or unenforceable, either in whole or in part, then that part shall be severed from these Terms of Use and shall not affect the validity or enforceability of any other part in this Terms of Use.
                </p>
                <h3 className='policy-headers3'>9.0	Confidential Information</h3>
                <p>
                    Parties acknowledge that in the performance of their duties under this Agreement, parties may communicate to each other (or its designees) certain confidential and proprietary information, including without limitation, information concerning each party’s services, know-how, technology, techniques, or business or marketing plans (collectively, the “Confidential Information”) all of which are confidential and proprietary to, and trade secrets of, the disclosing party. Confidential Information does not include information that: (i) is public knowledge at the time of disclosure by the disclosing party; (ii) becomes public knowledge or known to the receiving party after disclosure by the disclosing party other than by breach of the receiving party’s obligations under this section or by breach of a third party’s confidentiality obligations; (iii) was known by the receiving party prior to disclosure by the disclosing party other than by breach of a third party’s confidentiality obligations; or (iv) is independently developed by the receiving party.
                </p>
                <p>
                    As a condition to the receipt of the Confidential Information from the disclosing party, the receiving party shall: (i) not disclose in any manner, directly or indirectly, to any third party any portion of the disclosing party’s Confidential Information; (ii) not use the disclosing party’s Confidential Information in any fashion except to perform its duties under this Agreement or with the disclosing party’s express prior written consent; (iii) disclose the disclosing party’s Confidential Information, in whole or in part, only to employees and agents who need to have access thereto for the receiving party’s internal business purposes; (iv) take all necessary steps to ensure that its employees and agents are informed of and comply with the confidentiality restrictions contained in this Agreement; and (v) take all necessary precautions to protect the confidentiality of the Confidential Information received hereunder and exercise at least the same degree of care in safeguarding the Confidential Information as it would with its own confidential information, and in no event shall apply less than a reasonable standard of care to prevent disclosure.
                </p>


                <h3 className='policy-headers3'>SIDMACH (YOUTHPLUS) POLICY ON CONSENT</h3>
                <p>
                    This platform uses cookies and other methods to process data in order to customize and control your site experience, provide social media features, analyze our traffic and personalize advertising on both our website and our YouthPlus platform.
                </p>
                {/* <p>
                    Please note that clicking ‘Continue’ signifies acceptance of the Terms & Conditions/Privacy Policy of this Site.
                </p> */}
                <p>
                    For more information on how we process your data – please visit our 
                    <Link to='/policy' rel="noreferrer"> privacy policy.</Link>
                </p>
                {/* <button >Continue</button> */}
            </div>
        </div>
    );
};
export default Terms;