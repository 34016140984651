import React from "react";
import './stanbicTerm.css'
function StanbicTerms() {
  return (
    <div className="stanbicTerms">
      <h1>
        Terms and Conditions
      </h1>

      <ol>
        <li>
          I confirm and agree that in addition to the Terms and Conditions
          stated above, my/our account(s) and all banking transactions between
          me (“the Customer”) and Stanbic IBTC Bank PLC (“the Bank”) shall be
          governed by the conditions specified below and/or the terms of any
          specific agreement between me/us and the Bank or where not regulated
          by either the conditions or such agreement, by customary banking
          practices in Nigeria
        </li>
        <li>
          The operations of the account is limited to a maximum single deposit
          amount of N50,000 and maximum cumulative amount of N300,000 for Tier 1
          accounts and at any point in time.
        </li>
        <li>
          
          The operations of the account is limited to a maximum single deposit
          amount of N100,000 and maximum cumulative amount of N500,000 for Tier
          2 accounts at any point in time.
        </li>
        <li>
          Mobile banking is limited to a maximum transaction daily limit of
          N50,000 for Tier 1 accounts, and a maximum transaction daily limit of
          N100,000 for Tier 2 accounts.
        </li>
        <li>International funds transfer is not permitted on this account.</li>
        <li>
          
          You will be required to provide further documents at any point in time
          when transacting above the regulated threshold.
        </li>
        <li> This account is strictly savings.</li>
        <li>
        
          This is an interest-bearing account for individuals who want to save
          for immediate and future needs and earn interest in return on the
          account at 10% per annum of the Central Bank of Nigeria Monetary
          Policy Rate (as published by the CBN from time to time. (interest is
          forfeited at the 5th withdrawal in a month) Interest is paid monthly
          less 10% withholding tax.
        </li>
      </ol>

      <h2>Inactive and Dormant Accounts</h2>

      <p>
        <b>Inactive Accounts:</b> Your account shall become inactive if there
        has been no customer or depositor-initiated transaction for a period of
        six months after the last customer or depositor-initiated transaction.
        You shall not be required to provide any documentation to activate the
        account. A simple deposit or withdrawal shall suffice to activate the
        account.
      </p>
      <p>
        <b>Dormant Accounts:</b> Your account shall be classified as dormant if
        there has been no customer or depositor-initiated transaction in it for
        a period of one (1) year after the last customer or depositor-initiated
        transaction. To make your account active after dormancy you shall
        provide satisfactory evidence of account ownership means of
        identification and present place of residence.
      </p>

      <h2>Declaration</h2>

      <p>
        I hereby declare that the stated particulars are true and correct and
        that I have not withheld any other information which may affect the
        decisions of the Bank.
      </p>
      <p>
        I consent to you carrying out identity and fraud prevention checks and
        sharing information relating to this application through the Fraud
        Prevention Service I further confirm that I will not move my salary
        account whilst I am subscribed to any loan facility with the Bank.
      </p>
      <p>
        I agree to abide by the terms and conditions which govern the operations
        of Stanbic IBTC Bank PLC.
      </p>
      <p>
        I hereby apply for the opening of account(s) with Stanbic IBTC Bank PLC.
        I understand that the information given herein, and the documents
        supplied are basis for opening such account(s) and I therefore warrant
        that such information is correct.
      </p>
      <p>
        I further undertake to indemnify the Bank for any loss suffered as a
        result of any false information or error in the information provided to
        the Bank.
      </p>

      <h2>Foreign Account Tax Compliance</h2>
      <p>
        I/We understand that as part of your obligations in compliance with the
        United States’ (US) Foreign Account Tax Compliance Act (FATCA),
        financial institutions and banks, including the Bank are required to
        obtain my/ our tax related information to determine whether my /our
        account is a US Account, account held by a Recalcitrant Account holder,
        or Non-Participating Financial Institution or bank.
      </p>
      <p>I/We provide the Bank my /our consent to:</p>

      <ol>
        <li>
          obtain from me /us such tax related information as is necessary and in
          the format determined by the Bank to determine whether I / we fall
          within any of the above categories, in which case my /our demographic
          and transactional data (as determined from time to time by the US
          Internal Revenue Service (“IRS”)), will be reportable by the Bank to
          the IRS;
        </li>
        <li>
          disclose my /our information (as referred to in paragraph (a) above)
          to Withholding Agents if and when required under the FATCA
          regulations;
        </li>
        <li>
          withhold on any payments of US Source Income received by me /us to the
          extent not already done by any other Withholding Agent (note that the
          maximum withholding that may apply to impacted US source income under
          FATCA is 30% ); and
        </li>
        <li>
          close, block or transfer (to one of our related entities) my / our
          account within 90 days of a request for my / our tax related
          information (in the format determined by us), being outstanding.
        </li>
      </ol>

      <h2>Definitions</h2>

      <p>
        <b>US Account</b> means, an account held by a US citizen, US tax
        resident, or an account having a substantial US owner that is a
        Specified US person as defined in FATCA, US treasury regulations1.1471.
      </p>
      <p>
        <b>Recalcitrant Account</b> means any account with US indicia that has
        not provided any requested documentation evidencing the account holder’s
        FATCA status or classification.
      </p>
      <p>
        <b>Non-Participating Foreign Financial Institution</b> means any
        financial institution or bank that has not registered with the IRS to
        enter into a Foreign Financial Institution Agreement and is not
        otherwise exempt as a certified-deemed compliant financial institution
        or bank or exempt beneficial owner.
      </p>
      <p>
        <b>US Source Income</b> means fixed or determinable annual or periodic
        income from sources within the US as defined in FATCA, US treasury
        regulation §1.1473-1(a)(2).
      </p>
      <p>
        Withholding Agent means a financial institution or bank empowered to
        withhold US Source Income in terms of an agreement with the US.
      </p>

      <h2>CARD PRODUCTS</h2>

      <ol>
        <li>
          “Card” means the Debit, Credit and Prepaid cards including any
          additional, renewal or replacement card(s) that we issue to you once
          you open an account with us and after we have approved your
          application.
        </li>
        <li>
          The Card must be used for only lawful transactions within Nigeria or
          the country where the goods or services are being purchased.
        </li>
        <li>
          Your Card has an expiry date and is valid until the last day of the
          month shown on the Card.
        </li>
        <li>
          Your Card shall be auto-renewed upon expiration and the card renewal
          fee shall apply unless you indicate your intention to opt out of
          auto-renewal of your Card by ticking and signing the relevant space
          provided at page 1 Section 4 hereof and/or by sending an email to
          customercarenigeria@stanbicibtc.com, or by calling or sending an SMS
          to 0700 909 909 909 (please include your account number and name).
          Note that your Card will not be auto renewed if your account is closed
          and or your card is deactivated.
        </li>
        <li>
          The PIN issued with your card enables you carry out transactions such
          as withdrawals from ATMs, purchases on Point of Sale terminals and
          Web/Online payments.
        </li>
        <li>
          When you use your card for transactions on other channels which are
          not owned by Stanbic IBTC, the rules of use of that channel apply in
          addition to these rules.
        </li>
        <li>
          You are responsible for the safekeeping and proper use of your Card.
          You are strongly advised to memorize your PIN.
        </li>
        <li>
          I/We hereby consent to the Bank destroying the Cards requested by
          me/us if after 3 months, I/we fail to collect the cards and the cost
          of the cards shall be debited into my/our account with the Bank. I/We
          further agree to bear the cost of any Card subsequently requested by
          me/us.
        </li>
      </ol>

      <h2>ATM / INTERNET / MOBILE BANKING CONDITIONS</h2>

      <ol>
        <li>
          I/We hereby request the Bank to grant me/us mobile / internet banking
          access (including viewing & transactional access via the channels) and
          further authorize the Bank to trust the information provided by me/us
          for the service. I/We agree that the Bank shall not be liable for
          placing reliance on the information provided by me/us in the event
          that the information is compromised and I/we incur a loss as a result
          thereof.
        </li>
        <li>
          I/we understand that my/our password is my/our private access control
          to the ATM and/or Mobile / Internet banking systems and hereby
          covenant not to disclose my/our password to any third party or permit
          any third party to have access to my/our password. I/we agree that the
          Bank shall not be liable or responsible for any loss or damage
          incurred by me/us on account of the compromise or such unauthorized
          use of my/our password.
        </li>
      </ol>

      <h2>SMS / EMAIL CONDITION</h2>

      <ol>
        <li>
          I/we understand that the notification alert via SMS or e-mail is
          within my/our private access and hereby agree not to disclose same to
          any third party. I/we agree that the Bank shall not be liable or
          responsible for any loss, or damage incurred by me/us on account of
          such unauthorized access to the information sent to me.
        </li>
        <li>
          I/We shall indemnify the Bank and keep the Bank fully indemnified
          against all losses, damages, claims, demands and expenses whatsoever
          which may be incurred, imposed or incurred by the Bank as well as
          against all actions, proceedings or claims (including attorney’s fees)
          whether civil or criminal, which may be brought against the Bank in
          relation to my/we electing to opt out of the mandatory SMS alert as
          directed by the CBN.
        </li>
      </ol>

      <h2>E-SUBSCRIPTION FOR STATEMENT CONDITION</h2>

      <p>
        I/we understand that the Statement of Account via e-mail is within
        my/our private access and I hereby agree that the Bank shall not be
        liable or responsible for any loss, or damage incurred by me/us on
        account of unauthorized access by any third party to the information
        sent to me.
      </p>

      <h2>DATA PROTECTION</h2>

      <ol>
        <li>
          You consent to us collecting your Personal Information from you and
          where lawful and reasonable, from public sources for credit, fraud and
          compliance purposes, as well as the purposes set out below.
        </li>
        <li>
          If you give us Personal Information about or on behalf of another
          person (including, but not limited to, account signatories,
          shareholders, principal executive officers, trustees and
          beneficiaries), you confirm that you are authorized to: (a) give us
          the Personal Information; (b) consent on their behalf to: the
          Processing of their Personal Information, specifically any
          cross-border transfer of Personal Information into and outside the
          country where the products or services are provided; and (c) receive
          any privacy notices on their behalf.
        </li>
        <li>
          You consent to us Processing your Personal Information:
          <ul>
            <li>
              to provide products and services to you in terms of this agreement
              and any other products and services for which you may apply;
            </li>
            <li>
              to carry out statistical and other analyses to identify potential
              markets and trends, evaluate and improve our business (this
              includes improving existing and developing new products and
              services);
            </li>
            <li>
              in countries outside the country where the products or services
              are provided. These countries may not have the same data
              protection laws as the country where the products or services are
              provided. Where we can, we will ask the receiving party to agree
              to our privacy policies;
            </li>
            <li>
              by sharing your Personal Information with our third-party service
              providers, locally and outside the country where the products or
              services are provided. We ask people who provide services to us to
              agree to our privacy policies if they need access to any Personal
              Information to carry out their services; and Within the Standard
              Bank Group.
            </li>
          </ul>
        </li>
        <li>
          In the event that you fail to repay all fees and charges arising from
          your card transactions as required, the Bank shall have the right to
          report the unpaid fees and charges against you as delinquent credit to
          CBN through the Credit Risk Management System (CRMS) or other Credit
          Bureau Agencies, and request the CBN to exercise its regulatory power
          to direct all banks under its regulatory supervision to set-off your
          indebtedness from any money standing to your credit in any bank
          account and from any other financial assets they may be holding for
          your benefit. You hereby waive any right of confidentiality in this
          regard, whether arising under common law or statute or in any other
          manner whatsoever.
        </li>
        <li>
          You will find our Processing practices in the Group’s and our privacy
          statements. These statements are available on the Group’s websites or
          on request.
        </li>
        <li>
          If you are unsure about your tax or legal position because your
          Personal Information is processed in countries other than where you
          live, you should get independent advice.
        </li>
      </ol>

      <h2>MARKETING AND PROMOTION</h2>

      <p>
        Provided you have agreed to the above <b>Data Protection Clause</b>, we
        may use your personal or other information to share information with you
        on new products and services that may be of interest to you and from
        time to time will mail, email or SMS information to you about us, our
        products and services, or our partners and their products and services.
        If you do not wish to continue receiving this information, you may
        contact us and request that we delete your information from our mailing
        list.
      </p>

      <h2>
        BANK VERIFICATION NUMBER (BVN) OPERATIONS AND WATCH-LIST FOR THE
        NIGERIAN BANKING INDUSTRY
      </h2>

      <ol>
        <li>
          You consent to us applying restrictions on your account and reporting
          to law enforcement agencies if a fraudulent activity is associated
          with the operation of your account.
        </li>
        <li>
          You consent to us reporting to Nigeria Inter-Bank Settlement Systems
          Plc (NIBBS) for update on the Watchlist Data Base of the Nigerian
          Banking Industry and the Central Bank of Nigeria (CBN) if a fraudulent
          activity is associated with the operation of your account.
        </li>
      </ol>

      <p style={{paddingBottom: '4rem'}}>
        For more information on our products and Services, please go to:
        <a href="http://www.stanbicibtcbank.com">www.stanbicibtcbank.com</a>
      </p>
    </div>
  );
}

export default StanbicTerms;
