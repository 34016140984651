import React,{useState} from "react";
import { Modal, Form, Button, Tag, Divider} from "antd";
// import axios from "axios";
// import { toast } from 'react-toastify';
// import { LoadingOutlined } from '@ant-design/icons';


    const FiltersModal = ({ show, onCancel}) => {
    const [selectedFilters, setSelectedFilters] = useState({
        gender: '',
        status: '',
        batch: '',
        // states: [],
      });

      const toggleFilter = (filterType, value) => {
        setSelectedFilters((prev) => {
          const newFilters = { ...prev };
          // if (filterType === 'states') {
          //   if (newFilters.states.includes(value)) {
          //     newFilters.states = newFilters.states.filter((state) => state !== value);
          //   } else {
          //     newFilters.states.push(value);
          //   }
          // } else {
            newFilters[filterType] = value;
          // }
          return newFilters;
        });
      };
    
      const renderTags = (items, filterType) => {
        return items.map((item) => (
          <Tag
            key={item}
            style={{
                borderRadius: '8px',
                // width:'15%',
                marginBottom: '8px',
                cursor: 'pointer',
                color:'#fff',
                backgroundColor: selectedFilters[filterType] === item ? '#08839B' : 'black',
              }}
            onClick={() => toggleFilter(filterType, item)}
          >
            {item}
          </Tag>
        ));
      };
    
      const genderOptions = ['Male', 'Female'];
      const statusOptions = ['Active', 'In-Active'];
      const batchOptions = ['Batch C, November, 2023', 'Undefined', 'Batch B, June, 2024', 'Batch A, January, 2024'];
      // const stateOptions = [
      //   'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno',
      //   'Cross River', 'Delta', 'Ebonyi', 'Edo', 'Ekiti', 'Enugu', 'Gombe', 'Imo', 'Jigawa',
      //   'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos', 'Nasarawa', 'Niger',
      //   'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Others'
      // ];
  
  return (
    <Modal
      centered
      open={show}
      footer={null}
      closable={true}
      maskClosable={true}
      mask={true}
      onCancel={onCancel}
    >
        <Divider orientation="left">Gender</Divider>
        {renderTags(genderOptions, 'gender')}
        <Divider orientation="left">Status</Divider>
        {renderTags(statusOptions, 'status')}
        <Divider orientation="left">Batch Info</Divider>
        {renderTags(batchOptions, 'batch')}
        {/* <Divider orientation="left">Deployed State</Divider>
        {renderTags(stateOptions, 'states')} */}
                  <Form.Item style={{display:'flex', justifyContent:'center' }} >
                  <Button  
                  // onClick={}
                    style={{backgroundColor:'#08839B',color:'#ffffff',  }}>
                    Apply Filter
                  </Button>
                  </Form.Item >
              
    </Modal>
  );
};

export default FiltersModal;
