import React from 'react'
import { Typography,Button } from '@mui/material'


function Map() {
  return (
    <div className='map-styles'>
            <div className='map-image'></div>
            <div className='map-content'>
                <Typography className='map-title'>Discover other Bus Terminals around you</Typography>
                <Typography className='map-text'>Uncover Convenient Travel Hubs by Exploring Bus Terminals in Your Vicinity"</Typography>
                <Button variant='outlined'>Explore Map</Button>
            </div> 
    </div>
  )
}

export default Map