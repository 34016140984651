import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRoutes } from "react-router-dom";
import "./App.css";
// import RegisterLogin from "./Pages/RegistrationForm/Register";
import SignSample from "./Pages/SignSample";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Terms from "./Pages/Terms";
// import Transport from './Pages/TransportIntegration/Transport';
import BookTrip from "./Pages/BookTrip/BookTrip";
import StanbicTerms from "./Components/StanbicTerm";
import PassengersDetails from "./Pages/PassengersDetail/PassengersDetails";
import TripDetails from "./Pages/TransportIntegration/TripDetails";
import History from "./Pages/TransportIntegration/History";
import StanbicAccountForm from "./Pages/StanbicIbtcForm/stanbicForm";
import AdminDashboard from "./Pages/AdminDashboard/Dashboard";
import Transport from "./Pages/TransportIntegration/Transport";
// import ManageBookings from "./Pages/TransportIntegration/ManageBookings";
import ForgotPassword from "./Pages/ForgotPassword/forgotPassword";
import ResetPassword from "./Pages/ForgotPassword/ResetPassword";

// toast.configure();
function App() {
  toast.configure({
    autoClose: 7000, // 5 seconds
    // Other global options...
  });
  const routing = useRoutes([
    // { path: "sign", element: <RegisterLogin /> },
    { path: "/", element: <SignSample /> },
    { path: "/transport", element: <Transport /> },
    { path: "/booking-history", element: <History /> },
    { path: "/trip-details", element: <TripDetails /> },
    // /:callup_no1/:callup_no2/:callup_no3/:callup_no4,
    { path: "/:email/:call", element: <SignSample /> },
    { path: "/:email", element: <SignSample /> },
    { path: "policy", element: <PrivacyPolicy /> },
    { path: "/terms", element: <Terms /> },
    { path: "/stanbicterms", element: <StanbicTerms /> },
    { path: "/partner-dashboard", element: <AdminDashboard /> },
    { path: "/book-trips", element: <BookTrip /> },
    { path: "/trip-summary", element: <PassengersDetails /> },
    {
      path: "/complete-stanbicibtc-registration",
      element: <StanbicAccountForm />,
    },
    { path: "*", element: <SignSample /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> }
  ]);

  return (
    <div>
      <ToastContainer position="top-center" />
      {routing}
    </div>
  );
}

export default App;
