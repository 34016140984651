import React from 'react';
import NYSCLogo from "../asset/nysc-logo.svg";
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <p style={{fontWeight:'600'}}>For support, send a mail to <span style={{color:'blue'}}>support@youthplus.ng</span></p>
      <p className='footer-sub'>Youthplus in Partnership with NYSC<span><img src={NYSCLogo} alt='nysc-logo' width="30px" height="30px" /></span></p>
    </footer>
  );
};

export default Footer;
