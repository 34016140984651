import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Typography } from "antd";
import Nigeria from "../asset/icon-nigeria.svg";

function ContactDetails({
  email,
  phone,
  handleEmailChange,
  passengerDetails,
  handlePhoneChange,
  disabled,
  setValidation, // Added: to set validation state
  
}) {
  const [errors, setErrors] = useState({ email: "", phone: "" });

  useEffect(() => {
    const validPhone = (phoneValue) => {
      const phoneReg = /^[0-9]{8,15}$/;
      return phoneReg.test(phoneValue);
    };
    const validEmail = (emailValue) => {
      const emailReg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailReg.test(emailValue);
    };
    const allFieldsFilled = passengerDetails.every(
      (passenger) =>
        passenger.firstname &&
        passenger.lastname &&
        validEmail(passenger.email) &&
        validPhone(passenger.phone) &&
        passenger.gendervalue && // To Check if gender is filled
        passenger.titlevalue && // To Check if title is filled
        passenger.nok.firstname &&
        passenger.nok.lastname &&
        validPhone(passenger.nok.phone) &&
        validEmail(passenger.nok.email)
    );

    // Validate email and phone
    const isEmailValid = email && validEmail(email);
    const isPhoneValid = phone && validPhone(phone);
    const isValid = isEmailValid && isPhoneValid && allFieldsFilled;    
    setValidation(isValid);
    
  }, [email, phone, setValidation,passengerDetails]); // Dependencies to trigger the effect when email or phone change

  const validateField = (field, value) => {
    let errorMessage = "";
    switch (field) {
      case "email":
        if (!value) {
          errorMessage = "Please enter your email!";
        } else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        ) {
          errorMessage = "Please enter a valid email address!";
        }
        break;
      case "phone":
        if (!value) {
          errorMessage = "Please enter your phone number!";
        } else if (!/^[0-9]{8,15}$/.test(value)) {
          errorMessage = "Please enter a valid phone number!";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [field]: errorMessage }));
  };

  return (
    <div
      style={{
        outline: "2px solid #ffffff",
        backgroundColor: "#ffffff",
        padding: "1rem",
      }}
    >
      <div className="pdetails-header">
        <div className="pdets-title">
          {/* <img src={Three} alt="icon" /> */}
          <Typography className="pdets-text">Contact Details</Typography>
        </div>
      </div>
      <div className="pdets-form">
        <Form layout="vertical">
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Email"
                //  name="email"
                validateStatus={errors.email ? "error" : ""}
                help={errors.email}
              >
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    handleEmailChange(e);
                    validateField("email", e.target.value);
                  }}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Phone Number"
                // name="phoneNumber"
                validateStatus={errors.phone ? "error" : ""}
                help={errors.phone}
              >
                <Input
                  type="tel"
                  value={phone}
                  onChange={(e) => {
                    handlePhoneChange(e);
                    validateField("phone", e.target.value);
                  }}
                  disabled={disabled}
                  prefix={<img src={Nigeria} alt="icon" />}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ContactDetails;

