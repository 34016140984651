import React from "react";
import { useLocation } from "react-router-dom";
import TransportHeader from "../../Components/transportheader";
import Content from "../../Components/content";

function TripDetails() {
  const location = useLocation();
  const selectedTrip = location.state?.selectedTrip;

  if (!selectedTrip) {
    return (
      <div>
        <TransportHeader />
        <div style={{ padding: "2rem", textAlign: "center" }}>
          <p>No trip selected. Please go back and select a trip.</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <TransportHeader />
      <Content selectedTrip={selectedTrip} />
    </div>
  );
}

export default TripDetails;
