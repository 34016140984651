import React from "react";
import { notification } from "antd";
import Seat1 from "../asset/Seat1.svg"; // Your Seat(s)
import Seat3 from "../asset/Seat3.svg"; // Unavailable Seats (Paid)
import Seat4 from "../asset/Seat4.svg"; // Available Seats

function BusSeats({
  totalseats,
  selectedSeats,
  handleSeatSelect,
  availableSeats,
  maxSelectableSeats,
}) {
  const renderSeatImage = (seatNumber) => {
    const isSelected = selectedSeats.includes(seatNumber);
    const seat = availableSeats.find((seat) => seat.seatNumber === seatNumber);

    if (isSelected) {
      return Seat1; // Image for selected seats
    }

    if (!seat || seat.available) {
      return Seat4; // Image for available seats
    } else {
      return Seat3; // Image for unavailable seats (paid)
    }
  };

  const handleSeatClick = (seatNumber) => {
    const seat = availableSeats.find((seat) => seat.seatNumber === seatNumber);
    if (!seat || !seat.available) {
      notification.error({
        message: "You can only select available seats.",
      });
      return;
    }
    if (selectedSeats.includes(seatNumber)) {
      handleSeatSelect(seatNumber);
    } else if (selectedSeats.length < maxSelectableSeats) {
      handleSeatSelect(seatNumber);
    } else {
      notification.error({
        message: `You can only select ${maxSelectableSeats} seat(s).`,
      });
    }
  };

  const seatsArray = Array.from(
    { length: totalseats },
    (_, index) => index + 1
  );

  return (
    <div className="bus-container">
      <div className="bus">
        {seatsArray.map((seatNumber) => (
          <img
            key={seatNumber}
            src={renderSeatImage(seatNumber)}
            alt={`Seat ${seatNumber}`}
            className="seat"
            onClick={() => handleSeatClick(seatNumber)}
          />
        ))}
      </div>
    </div>
  );
}

export default BusSeats;
