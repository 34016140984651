import React from 'react'
import {
    MenuUnfoldOutlined,
    UserOutlined,
    MenuFoldOutlined,
   
  } from "@ant-design/icons";
  import { Layout, Button, Avatar, } from "antd";

  const { Header} = Layout;
const HeaderComponent = ({collapsed, setCollapsed, userDatafromlocalstorage}) => {
  return (
    <div>
      
      <Header style={{ padding: "1rem", backgroundColor: "#fff",display: "flex",
        alignItems: "center",
        justifyContent: "space-between"}}
      >
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{ fontSize: "1rem"}}
        />
        <div style={{ display:"flex"}}>
          <Avatar
            icon={<UserOutlined />}
            style={{ backgroundColor:"#40499d", marginTop:"1rem", marginRight:"0.5rem"}}
          />
          <p className="fs-6" style={{ margin:"auto 0", marginRight:"0.5rem"}}>
              {userDatafromlocalstorage?.username}
          </p>
        </div>
      </Header>
    </div>
  )
}

export default HeaderComponent
