import { Typography } from '@mui/material'
import React from 'react'

function About() {
  return (
    <div className='about-styles'>
        {/* <Typography>About Us</Typography> */}
        <Typography className='about-text'>
        Our reliable transportation is your trusted partner for affordable and convenient rides in your proximity. Our commitment to transparency, safety, and customer satisfaction sets us apart in the transportation industry. With competitive rates and transparent pricing, we offer hassle-free travel arrangements for any occasion. Our professional drivers undergo rigorous background checks and training to ensure safe and comfortable rides in well-maintained vehicles. Whether you're traveling short distances or across town, our proximity-based service minimizes wait times and maximizes convenience. At our reliable transportation, we're dedicated to earning and maintaining your trust, delivering exceptional experiences with every ride.
        </Typography>
    </div>
  )
}

export default About