export const cities = [
    {
      STATE_CODE: "ABIA",
      CITY: "ABA NORTH",
      CITY_CODE: "ABANO",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "ABA SOUTH",
      CITY_CODE: "ABASO",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "AROCHUKWU",
      CITY_CODE: "AROCH",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "BENDE",
      CITY_CODE: "BENDE",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "IKWUANO",
      CITY_CODE: "IKWUA",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "ISIALA NGWA NORTH",
      CITY_CODE: "ISINN",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "ISIALA NGWA SOUTH",
      CITY_CODE: "ISINS",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "ISUIKWUATO",
      CITY_CODE: "ISUIK",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "OBI NGWA",
      CITY_CODE: "OBI N",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "OHAFIA",
      CITY_CODE: "OHAFI",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "OSISIOMA",
      CITY_CODE: "OSISI",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UGWUNAGBO",
      CITY_CODE: "UGWUN",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UKWA EAST",
      CITY_CODE: "UKWA ",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UKWA WEST",
      CITY_CODE: "UKWW",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UMU NNEOCHI",
      CITY_CODE: "UMU N",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UMUAHIA",
      CITY_CODE: "UMUAI",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UMUAHIA NORTH",
      CITY_CODE: "UMUAH",
    },
    {
      STATE_CODE: "ABIA",
      CITY: "UMUAHIA SOUTH",
      CITY_CODE: "UMUAS",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "ABAJI",
      CITY_CODE: "ABAJI",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "ABUJA",
      CITY_CODE: "ABUJA",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "BWARI",
      CITY_CODE: "BWARI",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "GWAGWALADA",
      CITY_CODE: "GWAGW",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "KUJE",
      CITY_CODE: "KUJE",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "KWALI",
      CITY_CODE: "KWALI",
    },
    {
      STATE_CODE: "ABUJA",
      CITY: "MUNICIPAL AREA COUNCIL",
      CITY_CODE: "MUNIC",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "DEMSA",
      CITY_CODE: "DEMSA",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "FUFURE",
      CITY_CODE: "FUFUR",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "GANYE",
      CITY_CODE: "GANYE",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "GAYUK",
      CITY_CODE: "GAYUK",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "GOMBI",
      CITY_CODE: "GOMBI",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "GRIE",
      CITY_CODE: "GRIE",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "HONG",
      CITY_CODE: "HONG",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "JADA",
      CITY_CODE: "JADA",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "LARMURDE",
      CITY_CODE: "LARMU",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "MADAGALI",
      CITY_CODE: "MADAG",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "MAIHA",
      CITY_CODE: "MAIHA",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "MAYO BELWA",
      CITY_CODE: "MAYO ",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "MICHIKA",
      CITY_CODE: "MICHI",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "MUBI NORTH",
      CITY_CODE: "MUBI ",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "MUBI SOUTH",
      CITY_CODE: "MUBS",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "NUMAN",
      CITY_CODE: "NUMAN",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "SHELLENG",
      CITY_CODE: "SHELL",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "SONG",
      CITY_CODE: "SONG",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "TOUNGO",
      CITY_CODE: "TOUNG",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "YOLA",
      CITY_CODE: "YOLA",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "YOLA NORTH",
      CITY_CODE: "YOLN",
    },
    {
      STATE_CODE: "ADAMA",
      CITY: "YOLA SOUTH",
      CITY_CODE: "YOLS",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ABAK",
      CITY_CODE: "ABAK",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "EASTERN OBOLO",
      CITY_CODE: "EASTE",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "EKET",
      CITY_CODE: "EKET",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ESIT EKET",
      CITY_CODE: "ESIT ",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ESSIEN UDIM",
      CITY_CODE: "ESSIE",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ETIM EKPO",
      CITY_CODE: "ETIM ",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ETINAN",
      CITY_CODE: "ETINA",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IBENO",
      CITY_CODE: "IBENO",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IBESIKPO ASUTAN",
      CITY_CODE: "IBESI",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IBIONO-IBOM",
      CITY_CODE: "IBION",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IKA",
      CITY_CODE: "IKA",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IKONO",
      CITY_CODE: "IKONO",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IKOT ABASI",
      CITY_CODE: "IKOT ",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "IKOT EKPENE",
      CITY_CODE: "IKOE",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "INI",
      CITY_CODE: "INI",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ITU",
      CITY_CODE: "ITU",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "MBO",
      CITY_CODE: "MBO",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "MKPAT-ENIN",
      CITY_CODE: "MKPAT",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "NSIT-ATAI",
      CITY_CODE: "NSIT-",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "NSIT-IBOM",
      CITY_CODE: "NSITI",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "NSIT-UBIUM",
      CITY_CODE: "NSITU",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "OBOT AKARA",
      CITY_CODE: "OBOT ",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "OKOBO",
      CITY_CODE: "OKOBO",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ONNA",
      CITY_CODE: "ONNA",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ORON",
      CITY_CODE: "ORON",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "ORUK ANAM",
      CITY_CODE: "ORUK ",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "UDUNG-UKO",
      CITY_CODE: "UDUNG",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "UKANAFUN",
      CITY_CODE: "UKANA",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "URUAN",
      CITY_CODE: "URUAN",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "URUE-OFFONG/ORUKO",
      CITY_CODE: "URUE-",
    },
    {
      STATE_CODE: "AKWAI",
      CITY: "UYO",
      CITY_CODE: "UYO",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "AGUATA",
      CITY_CODE: "AGUAT",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ANAMBRA EAST",
      CITY_CODE: "ANAMB",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ANAMBRA WEST",
      CITY_CODE: "ANAMW",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ANAOCHA",
      CITY_CODE: "ANAOC",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "AWKA NORTH",
      CITY_CODE: "AWKA ",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "AWKA SOUTH",
      CITY_CODE: "AWKS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "AYAMELUM",
      CITY_CODE: "AYAME",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "DUNUKOFIA",
      CITY_CODE: "DUNUK",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "EKWUSIGO",
      CITY_CODE: "EKWUS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "IDEMILI NORTH",
      CITY_CODE: "IDEMI",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "IDEMILI SOUTH",
      CITY_CODE: "IDEMS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "IHIALA",
      CITY_CODE: "IHIAL",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "NJIKOKA",
      CITY_CODE: "NJIKO",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "NNEWI NORTH",
      CITY_CODE: "NNEWI",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "NNEWI SOUTH",
      CITY_CODE: "NNEWS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "OGBARU",
      CITY_CODE: "OGBAR",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ONITSHA NORTH",
      CITY_CODE: "ONITS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ONITSHA SOUTH",
      CITY_CODE: "ONIAS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ORUMBA NORTH",
      CITY_CODE: "ORUMB",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "ORUMBA SOUTH",
      CITY_CODE: "ORUMS",
    },
    {
      STATE_CODE: "ANAMB",
      CITY: "OYI",
      CITY_CODE: "OYI",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "ALKALERI",
      CITY_CODE: "ALKAL",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "BAUCHI",
      CITY_CODE: "BAUCH",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "BOGORO",
      CITY_CODE: "BOGOR",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "DAMBAN",
      CITY_CODE: "DAMBA",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "DARAZO",
      CITY_CODE: "DARAZ",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "DASS",
      CITY_CODE: "DASS",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "GAMAWA",
      CITY_CODE: "GAMAW",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "GANJUWA",
      CITY_CODE: "GANJU",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "GIADE",
      CITY_CODE: "GIADE",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "ITAS GADAU",
      CITY_CODE: "ITAS",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "JAMAARE",
      CITY_CODE: "JAMAA",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "KATAGUM",
      CITY_CODE: "KATAG",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "KIRFI",
      CITY_CODE: "KIRFI",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "MISAU",
      CITY_CODE: "MISAU",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "NINGI",
      CITY_CODE: "NINGI",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "SHIRA",
      CITY_CODE: "SHIRA",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "TAFAWA BALEWA",
      CITY_CODE: "TAFAW",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "TORO",
      CITY_CODE: "TORO",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "WARJI",
      CITY_CODE: "WARJI",
    },
    {
      STATE_CODE: "BAUCH",
      CITY: "ZAKI",
      CITY_CODE: "ZAKI",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "BRASS",
      CITY_CODE: "BRASS",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "EKEREMOR",
      CITY_CODE: "EKERE",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "KOLOKUMA/OPOKUMA",
      CITY_CODE: "KOLOK",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "NEMBE",
      CITY_CODE: "NEMBE",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "OGBIA",
      CITY_CODE: "OGBIA",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "SAGBAMA",
      CITY_CODE: "SAGBA",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "SOUTHERN IJAW",
      CITY_CODE: "SOUTH",
    },
    {
      STATE_CODE: "BAYEL",
      CITY: "YENAGOA",
      CITY_CODE: "YENAG",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "ADO",
      CITY_CODE: "ADO",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "AGATU",
      CITY_CODE: "AGATU",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "APA",
      CITY_CODE: "APA",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "BURUKU",
      CITY_CODE: "BURUK",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "GBOKO",
      CITY_CODE: "GBOKO",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "GUMA",
      CITY_CODE: "GUMA",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "GWER EAST",
      CITY_CODE: "GWER ",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "GWER WEST",
      CITY_CODE: "GWEW",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "KATSINA-ALA",
      CITY_CODE: "KATSK",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "KONSHISHA",
      CITY_CODE: "KONSH",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "KWANDE",
      CITY_CODE: "KWAND",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "LOGO",
      CITY_CODE: "LOGO",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "MAKURDI",
      CITY_CODE: "MAKUR",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "OGBADIBO",
      CITY_CODE: "OGBAD",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "OHIMINI",
      CITY_CODE: "OHIMI",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "OJU",
      CITY_CODE: "OJU",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "OKPOKWU",
      CITY_CODE: "OKPOK",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "OTURKPO",
      CITY_CODE: "OTURK",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "TARKA",
      CITY_CODE: "TARKA",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "UKUM",
      CITY_CODE: "UKUM",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "USHONGO",
      CITY_CODE: "USHON",
    },
    {
      STATE_CODE: "BENUE",
      CITY: "VANDEIKYA",
      CITY_CODE: "VANDE",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "ABADAM",
      CITY_CODE: "ABADA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "ASKIRA/UBA",
      CITY_CODE: "ASKIR",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "BAMA",
      CITY_CODE: "BAMA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "BAYO",
      CITY_CODE: "BAYO",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "BIU",
      CITY_CODE: "BIU",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "CHIBOK",
      CITY_CODE: "CHIBO",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "DAMBOA",
      CITY_CODE: "DAMBO",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "DIKWA",
      CITY_CODE: "DIKWA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "GUBIO",
      CITY_CODE: "GUBIO",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "GUZAMALA",
      CITY_CODE: "GUZAM",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "GWOZA",
      CITY_CODE: "GWOZA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "HAWUL",
      CITY_CODE: "HAWUL",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "JERE",
      CITY_CODE: "JERE",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "KAGA",
      CITY_CODE: "KAGA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "KALA/BALGE",
      CITY_CODE: "KALA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "KONDUGA",
      CITY_CODE: "KONDU",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "KUKAWA",
      CITY_CODE: "KUKAW",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "KWAYA KUSAR",
      CITY_CODE: "KWAYA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "MAFA",
      CITY_CODE: "MAFA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "MAGUMERI",
      CITY_CODE: "MAGUM",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "MAIDUGURI",
      CITY_CODE: "MAIDU",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "MARTE",
      CITY_CODE: "MARTE",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "MOBBAR",
      CITY_CODE: "MOBBA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "MONGUNO",
      CITY_CODE: "MONGU",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "NGALA",
      CITY_CODE: "NGALA",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "NGANZAI",
      CITY_CODE: "NGANZ",
    },
    {
      STATE_CODE: "BORNO",
      CITY: "SHANI",
      CITY_CODE: "SHANI",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "ABI",
      CITY_CODE: "ABI",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "AKAMKPA",
      CITY_CODE: "AKAMK",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "AKPABUYO",
      CITY_CODE: "AKPAB",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "BAKASSI",
      CITY_CODE: "BAKAS",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "BEKWARRA",
      CITY_CODE: "BEKWA",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "BIASE",
      CITY_CODE: "BIASE",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "BOKI",
      CITY_CODE: "BOKI",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "CALABAR",
      CITY_CODE: "CALAR",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "CALABAR MUNICIPAL",
      CITY_CODE: "CALAB",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "CALABAR SOUTH",
      CITY_CODE: "CALAS",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "ETUNG",
      CITY_CODE: "ETUNG",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "IKOM",
      CITY_CODE: "IKOM",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "OBANLIKU",
      CITY_CODE: "OBANL",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "OBUBRA",
      CITY_CODE: "OBUBR",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "OBUDU",
      CITY_CODE: "OBUDU",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "ODUKPANI",
      CITY_CODE: "ODUKP",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "OGOJA",
      CITY_CODE: "OGOJA",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "YAKUUR",
      CITY_CODE: "YAKUU",
    },
    {
      STATE_CODE: "CROSS",
      CITY: "YALA",
      CITY_CODE: "YALA",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ANIOCHA NORTH",
      CITY_CODE: "ANIOC",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ANIOCHA SOUTH",
      CITY_CODE: "ANIOS",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ASABA",
      CITY_CODE: "ASABA",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "BOMADI",
      CITY_CODE: "BOMAD",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "BURUTU",
      CITY_CODE: "BURUT",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ETHIOPE EAST",
      CITY_CODE: "ETHIO",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ETHIOPE WEST",
      CITY_CODE: "ETHIW",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "IKA NORTH EAST",
      CITY_CODE: "IKA N",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "IKA SOUTH",
      CITY_CODE: "IKA S",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ISOKO NORTH",
      CITY_CODE: "ISOKO",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "ISOKO SOUTH",
      CITY_CODE: "ISOKS",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "NDOKWA EAST",
      CITY_CODE: "NDOKW",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "NDOKWA WEST",
      CITY_CODE: "NDOWW",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "OKPE",
      CITY_CODE: "OKPE",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "OSHIMILI NORTH",
      CITY_CODE: "OSHIM",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "OSHIMILI SOUTH",
      CITY_CODE: "OSHIS",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "PATANI",
      CITY_CODE: "PATAN",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "SAPELE",
      CITY_CODE: "SAPEL",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "UDU",
      CITY_CODE: "UDU",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "UGHELLI NORTH",
      CITY_CODE: "UGHEL",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "UGHELLI SOUTH",
      CITY_CODE: "UGHES",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "UKWUANI",
      CITY_CODE: "UKWUA",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "UVWIE",
      CITY_CODE: "UVWIE",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "WARRI NORTH",
      CITY_CODE: "WARRI",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "WARRI SOUTH",
      CITY_CODE: "WARRS",
    },
    {
      STATE_CODE: "DELTA",
      CITY: "WARRI SOUTH WEST",
      CITY_CODE: "WARSW",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "ABAKALIKI",
      CITY_CODE: "ABAKA",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "AFIKPO NORTH",
      CITY_CODE: "AFIKP",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "AFIKPO SOUTH",
      CITY_CODE: "AFIKS",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "EBONYI",
      CITY_CODE: "EBONY",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "EZZA NORTH",
      CITY_CODE: "EZZA ",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "EZZA SOUTH",
      CITY_CODE: "EZZS",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "IKWO",
      CITY_CODE: "IKWO",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "ISHIELU",
      CITY_CODE: "ISHIE",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "IVO",
      CITY_CODE: "IVO",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "IZZI",
      CITY_CODE: "IZZI",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "OHAOZARA",
      CITY_CODE: "OHAOZ",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "OHAUKWU",
      CITY_CODE: "OHAUK",
    },
    {
      STATE_CODE: "EBONY",
      CITY: "ONICHA",
      CITY_CODE: "ONICH",
    },
    {
      STATE_CODE: "EDO",
      CITY: "AKOKO-EDO",
      CITY_CODE: "AKOED",
    },
    {
      STATE_CODE: "EDO",
      CITY: "BENIN CITY",
      CITY_CODE: "BENIN",
    },
    {
      STATE_CODE: "EDO",
      CITY: "EGOR",
      CITY_CODE: "EGOR",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ESAN CENTRAL",
      CITY_CODE: "ESAN ",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ESAN NORTH-EAST",
      CITY_CODE: "ESNE",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ESAN SOUTH-EAST",
      CITY_CODE: "ESSE",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ESAN WEST",
      CITY_CODE: "ESWE",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ETSAKO CENTRAL",
      CITY_CODE: "ETSAK",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ETSAKO EAST",
      CITY_CODE: "ETSAE",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ETSAKO WEST",
      CITY_CODE: "ETSAW",
    },
    {
      STATE_CODE: "EDO",
      CITY: "IGUEBEN",
      CITY_CODE: "IGUEB",
    },
    {
      STATE_CODE: "EDO",
      CITY: "IKPOBA OKHA",
      CITY_CODE: "IKPOB",
    },
    {
      STATE_CODE: "EDO",
      CITY: "OREDO",
      CITY_CODE: "OREDO",
    },
    {
      STATE_CODE: "EDO",
      CITY: "ORHIONMWON",
      CITY_CODE: "ORHIO",
    },
    {
      STATE_CODE: "EDO",
      CITY: "OVIA NORTH-EAST",
      CITY_CODE: "OVIA ",
    },
    {
      STATE_CODE: "EDO",
      CITY: "OVIA SOUTH-WEST",
      CITY_CODE: "OVIS",
    },
    {
      STATE_CODE: "EDO",
      CITY: "OWAN EAST",
      CITY_CODE: "OWAN ",
    },
    {
      STATE_CODE: "EDO",
      CITY: "OWAN WEST",
      CITY_CODE: "OWAW",
    },
    {
      STATE_CODE: "EDO",
      CITY: "UHUNMWONDE",
      CITY_CODE: "UHUNM",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "ADO EKITI",
      CITY_CODE: "ADOEK",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "EFON",
      CITY_CODE: "EFON",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "EKITI EAST",
      CITY_CODE: "EKITE",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "EKITI SOUTH-WEST",
      CITY_CODE: "EKISW",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "EKITI WEST",
      CITY_CODE: "EKITW",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "EMURE",
      CITY_CODE: "EMURE",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "GBONYIN",
      CITY_CODE: "GBONY",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "IDO OSI",
      CITY_CODE: "IDO O",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "IJERO",
      CITY_CODE: "IJERO",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "IKERE",
      CITY_CODE: "IKERE",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "IKOLE",
      CITY_CODE: "IKOLE",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "ILEJEMEJE",
      CITY_CODE: "ILEJE",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "IREPODUN/IFELODUN",
      CITY_CODE: "IREPI",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "ISE/ORUN",
      CITY_CODE: "ISE",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "MOBA",
      CITY_CODE: "MOBA",
    },
    {
      STATE_CODE: "EKITI",
      CITY: "OYE",
      CITY_CODE: "OYE",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "ANINRI",
      CITY_CODE: "ANINR",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "AWGU",
      CITY_CODE: "AWGU",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "ENUGU",
      CITY_CODE: "ENUGM",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "ENUGU EAST",
      CITY_CODE: "ENUGU",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "ENUGU NORTH",
      CITY_CODE: "ENUGN",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "ENUGU SOUTH",
      CITY_CODE: "ENUGS",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "EZEAGU",
      CITY_CODE: "EZEAG",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "IGBO ETITI",
      CITY_CODE: "IGBO ",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "IGBO EZE NORTH",
      CITY_CODE: "IGBN",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "IGBO EZE SOUTH",
      CITY_CODE: "IGBS",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "ISI UZO",
      CITY_CODE: "ISI U",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "NKANU EAST",
      CITY_CODE: "NKANU",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "NKANU WEST",
      CITY_CODE: "NKANW",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "NSUKKA",
      CITY_CODE: "NSUKK",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "OJI RIVER",
      CITY_CODE: "OJI R",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "UDENU",
      CITY_CODE: "UDENU",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "UDI",
      CITY_CODE: "UDI",
    },
    {
      STATE_CODE: "ENUGU",
      CITY: "UZO UWANI",
      CITY_CODE: "UZO U",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "AKKO",
      CITY_CODE: "AKKO",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "BALANGA",
      CITY_CODE: "BALAN",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "BILLIRI",
      CITY_CODE: "BILLI",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "DUKKU",
      CITY_CODE: "DUKKU",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "FUNAKAYE",
      CITY_CODE: "FUNAK",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "GOMBE",
      CITY_CODE: "GOMBE",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "KALTUNGO",
      CITY_CODE: "KALTU",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "KWAMI",
      CITY_CODE: "KWAMI",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "NAFADA",
      CITY_CODE: "NAFAD",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "SHONGOM",
      CITY_CODE: "SHONG",
    },
    {
      STATE_CODE: "GOMBE",
      CITY: "YAMALTU/DEBA",
      CITY_CODE: "YAMAL",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ABOH MBAISE",
      CITY_CODE: "ABOH ",
    },
    {
      STATE_CODE: "IMO",
      CITY: "AHIAZU MBAISE",
      CITY_CODE: "AHIAZ",
    },
    {
      STATE_CODE: "IMO",
      CITY: "EHIME MBANO",
      CITY_CODE: "EHIME",
    },
    {
      STATE_CODE: "IMO",
      CITY: "EZINIHITTE",
      CITY_CODE: "EZINI",
    },
    {
      STATE_CODE: "IMO",
      CITY: "IDEATO NORTH",
      CITY_CODE: "IDEAT",
    },
    {
      STATE_CODE: "IMO",
      CITY: "IDEATO SOUTH",
      CITY_CODE: "IDEAS",
    },
    {
      STATE_CODE: "IMO",
      CITY: "IHITTE/UBOMA",
      CITY_CODE: "IHITT",
    },
    {
      STATE_CODE: "IMO",
      CITY: "IKEDURU",
      CITY_CODE: "IKEDU",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ISIALA MBANO",
      CITY_CODE: "ISIAL",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ISU",
      CITY_CODE: "ISU",
    },
    {
      STATE_CODE: "IMO",
      CITY: "MBAITOLI",
      CITY_CODE: "MBAIT",
    },
    {
      STATE_CODE: "IMO",
      CITY: "NGOR OKPALA",
      CITY_CODE: "NGOR ",
    },
    {
      STATE_CODE: "IMO",
      CITY: "NJABA",
      CITY_CODE: "NJABA",
    },
    {
      STATE_CODE: "IMO",
      CITY: "NKWERRE",
      CITY_CODE: "NKWER",
    },
    {
      STATE_CODE: "IMO",
      CITY: "NWANGELE",
      CITY_CODE: "NWANG",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OBOWO",
      CITY_CODE: "OBOWO",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OGUTA",
      CITY_CODE: "OGUTA",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OHAJI/EGBEMA",
      CITY_CODE: "OHAJI",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OKIGWE",
      CITY_CODE: "OKIGW",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ORLU",
      CITY_CODE: "ORLU",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ORSU",
      CITY_CODE: "ORSU",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ORU EAST",
      CITY_CODE: "ORU E",
    },
    {
      STATE_CODE: "IMO",
      CITY: "ORU WEST",
      CITY_CODE: "ORU W",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OWERRI",
      CITY_CODE: "OWERI",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OWERRI MUNICIPAL",
      CITY_CODE: "OWERR",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OWERRI NORTH",
      CITY_CODE: "OWERN",
    },
    {
      STATE_CODE: "IMO",
      CITY: "OWERRI WEST",
      CITY_CODE: "OWERW",
    },
    {
      STATE_CODE: "IMO",
      CITY: "UNUIMO",
      CITY_CODE: "UNUIM",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "AUYO",
      CITY_CODE: "AUYO",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "BABURA",
      CITY_CODE: "BABUR",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "BIRINIWA",
      CITY_CODE: "BIRIN",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "BIRNIN KUDU",
      CITY_CODE: "BIRNK",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "BUJI",
      CITY_CODE: "BUJI",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "DUTSE",
      CITY_CODE: "DUTSE",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "GAGARAWA",
      CITY_CODE: "GAGAR",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "GARKI",
      CITY_CODE: "GARKI",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "GUMEL",
      CITY_CODE: "GUMEL",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "GURI",
      CITY_CODE: "GURI",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "GWARAM",
      CITY_CODE: "GWARA",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "GWIWA",
      CITY_CODE: "GWIWA",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "HADEJIA",
      CITY_CODE: "HADEJ",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "JAHUN",
      CITY_CODE: "JAHUN",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "KAFIN HAUSA",
      CITY_CODE: "KAFIN",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "KAUGAMA",
      CITY_CODE: "KAUGA",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "KAZAURE",
      CITY_CODE: "KAZAU",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "KIRI KASAMA",
      CITY_CODE: "KIRI ",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "KIYAWA",
      CITY_CODE: "KIYAW",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "MAIGATARI",
      CITY_CODE: "MAIGA",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "MALAM MADORI",
      CITY_CODE: "MALAM",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "MIGA",
      CITY_CODE: "MIGA",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "RINGIM",
      CITY_CODE: "RINGI",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "RONI",
      CITY_CODE: "RONI",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "SULE TANKARKAR",
      CITY_CODE: "SULE ",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "TAURA",
      CITY_CODE: "TAURA",
    },
    {
      STATE_CODE: "JIGAW",
      CITY: "YANKWASHI",
      CITY_CODE: "YANKW",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "BIRNIN GWARI",
      CITY_CODE: "BIRNI",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "CHIKUN",
      CITY_CODE: "CHIKU",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "GIWA",
      CITY_CODE: "GIWA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "IGABI",
      CITY_CODE: "IGABI",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "IKARA",
      CITY_CODE: "IKARA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "JABA",
      CITY_CODE: "JABA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "JEMAA",
      CITY_CODE: "JEMAA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KACHIA",
      CITY_CODE: "KACHI",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KADUNA",
      CITY_CODE: "KADUA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KADUNA NORTH",
      CITY_CODE: "KADUN",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KADUNA SOUTH",
      CITY_CODE: "KADUS",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KAGARKO",
      CITY_CODE: "KAGAR",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KAJURU",
      CITY_CODE: "KAJUR",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KAURA",
      CITY_CODE: "KAURA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KAURU",
      CITY_CODE: "KAURU",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KUBAU",
      CITY_CODE: "KUBAU",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "KUDAN",
      CITY_CODE: "KUDAN",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "LERE",
      CITY_CODE: "LERE",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "MAKARFI",
      CITY_CODE: "MAKAR",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "SABON GARI",
      CITY_CODE: "SABOG",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "SANGA",
      CITY_CODE: "SANGA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "SOBA",
      CITY_CODE: "SOBA",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "ZANGON KATAF",
      CITY_CODE: "ZANGK",
    },
    {
      STATE_CODE: "KADUN",
      CITY: "ZARIA",
      CITY_CODE: "ZARIA",
    },
    {
      STATE_CODE: "KANO",
      CITY: "AJINGI",
      CITY_CODE: "AJING",
    },
    {
      STATE_CODE: "KANO",
      CITY: "ALBASU",
      CITY_CODE: "ALBAS",
    },
    {
      STATE_CODE: "KANO",
      CITY: "BAGWAI",
      CITY_CODE: "BAGWA",
    },
    {
      STATE_CODE: "KANO",
      CITY: "BEBEJI",
      CITY_CODE: "BEBEJ",
    },
    {
      STATE_CODE: "KANO",
      CITY: "BICHI",
      CITY_CODE: "BICHI",
    },
    {
      STATE_CODE: "KANO",
      CITY: "BUNKURE",
      CITY_CODE: "BUNKU",
    },
    {
      STATE_CODE: "KANO",
      CITY: "DALA",
      CITY_CODE: "DALA",
    },
    {
      STATE_CODE: "KANO",
      CITY: "DAMBATTA",
      CITY_CODE: "DAMBT",
    },
    {
      STATE_CODE: "KANO",
      CITY: "DAWAKIN KUDU",
      CITY_CODE: "DAWAK",
    },
    {
      STATE_CODE: "KANO",
      CITY: "DAWAKIN TOFA",
      CITY_CODE: "DAWAT",
    },
    {
      STATE_CODE: "KANO",
      CITY: "DOGUWA",
      CITY_CODE: "DOGUW",
    },
    {
      STATE_CODE: "KANO",
      CITY: "FAGGE",
      CITY_CODE: "FAGGE",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GABASAWA",
      CITY_CODE: "GABAS",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GARKO",
      CITY_CODE: "GARKO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GARUN MALLAM",
      CITY_CODE: "GARUN",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GAYA",
      CITY_CODE: "GAYA",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GEZAWA",
      CITY_CODE: "GEZAW",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GWALE",
      CITY_CODE: "GWALE",
    },
    {
      STATE_CODE: "KANO",
      CITY: "GWARZO",
      CITY_CODE: "GWARZ",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KABO",
      CITY_CODE: "KABO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KANO",
      CITY_CODE: "KANO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KARAYE",
      CITY_CODE: "KARAY",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KIBIYA",
      CITY_CODE: "KIBIY",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KIRU",
      CITY_CODE: "KIRU",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KUMBOTSO",
      CITY_CODE: "KUMBO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KUNCHI",
      CITY_CODE: "KUNCH",
    },
    {
      STATE_CODE: "KANO",
      CITY: "KURA",
      CITY_CODE: "KURA",
    },
    {
      STATE_CODE: "KANO",
      CITY: "MADOBI",
      CITY_CODE: "MADOB",
    },
    {
      STATE_CODE: "KANO",
      CITY: "MAKODA",
      CITY_CODE: "MAKOD",
    },
    {
      STATE_CODE: "KANO",
      CITY: "MINJIBIR",
      CITY_CODE: "MINJI",
    },
    {
      STATE_CODE: "KANO",
      CITY: "RANO",
      CITY_CODE: "RANO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "RIMIN GADO",
      CITY_CODE: "RIMIN",
    },
    {
      STATE_CODE: "KANO",
      CITY: "ROGO",
      CITY_CODE: "ROGO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "SHANONO",
      CITY_CODE: "SHANO",
    },
    {
      STATE_CODE: "KANO",
      CITY: "SUMAILA",
      CITY_CODE: "SUMAI",
    },
    {
      STATE_CODE: "KANO",
      CITY: "TAKAI",
      CITY_CODE: "TAKAI",
    },
    {
      STATE_CODE: "KANO",
      CITY: "TARAUNI",
      CITY_CODE: "TARAU",
    },
    {
      STATE_CODE: "KANO",
      CITY: "TOFA",
      CITY_CODE: "TOFA",
    },
    {
      STATE_CODE: "KANO",
      CITY: "TSANYAWA",
      CITY_CODE: "TSANY",
    },
    {
      STATE_CODE: "KANO",
      CITY: "TUDUN WADA",
      CITY_CODE: "TUDUN",
    },
    {
      STATE_CODE: "KANO",
      CITY: "UNGOGO",
      CITY_CODE: "UNGOG",
    },
    {
      STATE_CODE: "KANO",
      CITY: "WARAWA",
      CITY_CODE: "WARAW",
    },
    {
      STATE_CODE: "KANO",
      CITY: "WUDIL",
      CITY_CODE: "WUDIL",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "BAKORI",
      CITY_CODE: "BAKOR",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "BATAGARAWA",
      CITY_CODE: "BATAG",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "BATSARI",
      CITY_CODE: "BATSA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "BAURE",
      CITY_CODE: "BAURE",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "BINDAWA",
      CITY_CODE: "BINDA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "CHARANCHI",
      CITY_CODE: "CHARA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "DAN MUSA",
      CITY_CODE: "DAN M",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "DANDUME",
      CITY_CODE: "DANDU",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "DANJA",
      CITY_CODE: "DANJA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "DAURA",
      CITY_CODE: "DAURA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "DUTSI",
      CITY_CODE: "DUTSI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "DUTSIN MA",
      CITY_CODE: "DUTSD",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "FASKARI",
      CITY_CODE: "FASKA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "FUNTUA",
      CITY_CODE: "FUNTU",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "INGAWA",
      CITY_CODE: "INGAW",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "JIBIA",
      CITY_CODE: "JIBIA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KAFUR",
      CITY_CODE: "KAFUR",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KAITA",
      CITY_CODE: "KAITA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KANKARA",
      CITY_CODE: "KANKA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KANKIA",
      CITY_CODE: "KANKI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KATSINA",
      CITY_CODE: "KATSI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KURFI",
      CITY_CODE: "KURFI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "KUSADA",
      CITY_CODE: "KUSAD",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "MAIADUA",
      CITY_CODE: "MAIAD",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "MALUMFASHI",
      CITY_CODE: "MALUM",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "MANI",
      CITY_CODE: "MANI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "MASHI",
      CITY_CODE: "MASHI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "MATAZU",
      CITY_CODE: "MATAZ",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "MUSAWA",
      CITY_CODE: "MUSAW",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "RIMI",
      CITY_CODE: "RIMI",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "SABUWA",
      CITY_CODE: "SABUW",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "SAFANA",
      CITY_CODE: "SAFAN",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "SANDAMU",
      CITY_CODE: "SANDA",
    },
    {
      STATE_CODE: "KATSI",
      CITY: "ZANGO",
      CITY_CODE: "ZANGO",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "ALEIRO",
      CITY_CODE: "ALEIR",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "AREWA DANDI",
      CITY_CODE: "AREWA",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "ARGUNGU",
      CITY_CODE: "ARGUN",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "AUGIE",
      CITY_CODE: "AUGIE",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "BAGUDO",
      CITY_CODE: "BAGUD",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "BIRNIN KEBBI",
      CITY_CODE: "BIRKB",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "BUNZA",
      CITY_CODE: "BUNZA",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "DANDI",
      CITY_CODE: "DANDI",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "FAKAI",
      CITY_CODE: "FAKAI",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "GWANDU",
      CITY_CODE: "GWAND",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "JEGA",
      CITY_CODE: "JEGA",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "KALGO",
      CITY_CODE: "KALGO",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "KOKO/BESSE",
      CITY_CODE: "KOKO",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "MAIYAMA",
      CITY_CODE: "MAIYA",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "NGASKI",
      CITY_CODE: "NGASK",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "SAKABA",
      CITY_CODE: "SAKAB",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "SHANGA",
      CITY_CODE: "SHANG",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "SURU",
      CITY_CODE: "SURU",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "WASAGU/DANKO",
      CITY_CODE: "WASAG",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "YAURI",
      CITY_CODE: "YAURI",
    },
    {
      STATE_CODE: "KEBBI",
      CITY: "ZURU",
      CITY_CODE: "ZURU",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "ADAVI",
      CITY_CODE: "ADAVI",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "AJAOKUTA",
      CITY_CODE: "AJAOK",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "ANKPA",
      CITY_CODE: "ANKPA",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "DEKINA",
      CITY_CODE: "DEKIN",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "IBAJI",
      CITY_CODE: "IBAJI",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "IDAH",
      CITY_CODE: "IDAH",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "IGALAMELA ODOLU",
      CITY_CODE: "IGALA",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "IJUMU",
      CITY_CODE: "IJUMU",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "KABBA BUNU",
      CITY_CODE: "KABBA",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "KOGI",
      CITY_CODE: "KOGI",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "LOKOJA",
      CITY_CODE: "LOKOJ",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "MOPA MURO",
      CITY_CODE: "MOPA ",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "OFU",
      CITY_CODE: "OFU",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "OGORI/MAGONGO",
      CITY_CODE: "OGORI",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "OKEHI",
      CITY_CODE: "OKEHI",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "OKENE",
      CITY_CODE: "OKENE",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "OLAMABORO",
      CITY_CODE: "OLAMA",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "OMALA",
      CITY_CODE: "OMALA",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "YAGBA EAST",
      CITY_CODE: "YAGBA",
    },
    {
      STATE_CODE: "KOGI",
      CITY: "YAGBA WEST",
      CITY_CODE: "YAGBW",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "ASA",
      CITY_CODE: "ASA",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "BARUTEN",
      CITY_CODE: "BARUT",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "EDU",
      CITY_CODE: "EDU",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "EKITI",
      CITY_CODE: "EKITI",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "ILORIN",
      CITY_CODE: "ILORN",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "ILORIN EAST",
      CITY_CODE: "ILORI",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "ILORIN SOUTH",
      CITY_CODE: "ILORS",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "ILORIN WEST",
      CITY_CODE: "ILORW",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "ISIN",
      CITY_CODE: "ISIN",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "KAIAMA",
      CITY_CODE: "KAIAM",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "MORO",
      CITY_CODE: "MORO",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "OFFA",
      CITY_CODE: "OFFA",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "OKE ERO",
      CITY_CODE: "OKE E",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "OYUN",
      CITY_CODE: "OYUN",
    },
    {
      STATE_CODE: "KWARA",
      CITY: "PATEGI",
      CITY_CODE: "PATEG",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "AGEGE",
      CITY_CODE: "AGEGE",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "AJAH",
      CITY_CODE: "AJAH",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "AJEROMI-IFELODUN",
      CITY_CODE: "AJERO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "ALIMOSHO",
      CITY_CODE: "ALIMO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "AMUWO-ODOFIN",
      CITY_CODE: "AMUWO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "APAPA",
      CITY_CODE: "APAPA",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "BADAGRY",
      CITY_CODE: "BADAG",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "EPE",
      CITY_CODE: "EPE",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "ETI OSA",
      CITY_CODE: "ETI O",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "FESTAC",
      CITY_CODE: "FESTA",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "IBEJU-LEKKI",
      CITY_CODE: "IBEJU",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "IFAKO-IJAIYE",
      CITY_CODE: "IFAKO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "IKEJA",
      CITY_CODE: "IKEJA",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "IKORODU",
      CITY_CODE: "IKORO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "KOSOFE",
      CITY_CODE: "KOSOF",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "LAGOS ISLAND",
      CITY_CODE: "LAGOS",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "LAGOS MAINLAND",
      CITY_CODE: "LAGOM",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "MUSHIN",
      CITY_CODE: "MUSHI",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "OJO",
      CITY_CODE: "OJO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "OSHODI-ISOLO",
      CITY_CODE: "OSHOD",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "SHOMOLU",
      CITY_CODE: "SHOMO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "SURULERE",
      CITY_CODE: "SURUL",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "VICTORIA ISLAND",
      CITY_CODE: "VICTO",
    },
    {
      STATE_CODE: "LAGOS",
      CITY: "YABA",
      CITY_CODE: "YABA",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "AKWANGA",
      CITY_CODE: "AKWAN",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "AWE",
      CITY_CODE: "AWE",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "DOMA",
      CITY_CODE: "DOMA",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "KARU",
      CITY_CODE: "KARU",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "KEANA",
      CITY_CODE: "KEANA",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "KEFFI",
      CITY_CODE: "KEFFI",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "KOKONA",
      CITY_CODE: "KOKON",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "LAFIA",
      CITY_CODE: "LAFIA",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "NASARAWA",
      CITY_CODE: "NASAR",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "NASARAWA EGON",
      CITY_CODE: "NASAE",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "OBI",
      CITY_CODE: "OBI",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "TOTO",
      CITY_CODE: "TOTO",
    },
    {
      STATE_CODE: "NASSA",
      CITY: "WAMBA",
      CITY_CODE: "WAMBA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "AGAIE",
      CITY_CODE: "AGAIE",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "AGWARA",
      CITY_CODE: "AGWAR",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "BIDA",
      CITY_CODE: "BIDA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "BORGU",
      CITY_CODE: "BORGU",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "BOSSO",
      CITY_CODE: "BOSSO",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "CHANCHAGA",
      CITY_CODE: "CHANC",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "EDATI",
      CITY_CODE: "EDATI",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "GBAKO",
      CITY_CODE: "GBAKO",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "GURARA",
      CITY_CODE: "GURAR",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "KATCHA",
      CITY_CODE: "KATCH",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "KONTAGORA",
      CITY_CODE: "KONTA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "LAPAI",
      CITY_CODE: "LAPAI",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "LAVUN",
      CITY_CODE: "LAVUN",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "MAGAMA",
      CITY_CODE: "MAGAM",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "MARIGA",
      CITY_CODE: "MARIG",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "MASHEGU",
      CITY_CODE: "MASHE",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "MINNA",
      CITY_CODE: "MINNA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "MOKWA",
      CITY_CODE: "MOKWA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "MOYA",
      CITY_CODE: "MOYA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "PAIKORO",
      CITY_CODE: "PAIKO",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "RAFI",
      CITY_CODE: "RAFI",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "RIJAU",
      CITY_CODE: "RIJAU",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "SHIRORO",
      CITY_CODE: "SHIRO",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "SULEJA",
      CITY_CODE: "SULEJ",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "TAFA",
      CITY_CODE: "TAFA",
    },
    {
      STATE_CODE: "NIGER",
      CITY: "WUSHISHI",
      CITY_CODE: "WUSHI",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "ABEOKUTA",
      CITY_CODE: "ABEKU",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "ABEOKUTA NORTH",
      CITY_CODE: "ABEOK",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "ABEOKUTA SOUTH",
      CITY_CODE: "ABEOS",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "ADOODO OTA",
      CITY_CODE: "ADOOD",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "EGBADO NORTH",
      CITY_CODE: "EGBAD",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "EGBADO SOUTH",
      CITY_CODE: "EGBAS",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "EWEKORO",
      CITY_CODE: "EWEKO",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IFO",
      CITY_CODE: "IFO",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IJEBU EAST",
      CITY_CODE: "IJEBU",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IJEBU NORTH",
      CITY_CODE: "IJEBN",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IJEBU NORTH EAST",
      CITY_CODE: "IJENE",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IJEBU ODE",
      CITY_CODE: "IJEEO",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IKENNE",
      CITY_CODE: "IKENN",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IMEKO AFON",
      CITY_CODE: "IMEKO",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "IPOKIA",
      CITY_CODE: "IPOKI",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "OBAFEMI OWODE",
      CITY_CODE: "OBAFE",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "ODEDA",
      CITY_CODE: "ODEDA",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "ODOGBOLU",
      CITY_CODE: "ODOGB",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "OGUN WATERSIDE",
      CITY_CODE: "OGUN ",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "REMO NORTH",
      CITY_CODE: "REMO ",
    },
    {
      STATE_CODE: "OGUN",
      CITY: "SHAGAMU",
      CITY_CODE: "SHAGA",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKOKO NORTH-EAST",
      CITY_CODE: "AKOKO",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKOKO NORTH-WEST",
      CITY_CODE: "AKONW",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKOKO SOUTH-EAST",
      CITY_CODE: "AKOSE",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKOKO SOUTH-WEST",
      CITY_CODE: "AKOSW",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKURE",
      CITY_CODE: "AKURM",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKURE NORTH",
      CITY_CODE: "AKURE",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "AKURE SOUTH",
      CITY_CODE: "AKURS",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "ESE ODO",
      CITY_CODE: "ESE O",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "IDANRE",
      CITY_CODE: "IDANR",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "IFEDORE",
      CITY_CODE: "IFEDO",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "ILAJE",
      CITY_CODE: "ILAJE",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "ILE OLUJI/OKEIGBO",
      CITY_CODE: "ILE O",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "IRELE",
      CITY_CODE: "IRELE",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "ODIGBO",
      CITY_CODE: "ODIGB",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "OKITIPUPA",
      CITY_CODE: "OKITI",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "ONDO EAST",
      CITY_CODE: "ONDO ",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "ONDO WEST",
      CITY_CODE: "ONDW",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "OSE",
      CITY_CODE: "OSE",
    },
    {
      STATE_CODE: "ONDO",
      CITY: "OWO",
      CITY_CODE: "OWO",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "AIYEDAADE",
      CITY_CODE: "AIYED",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "AIYEDIRE",
      CITY_CODE: "AIYEA",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ATAKUNMOSA EAST",
      CITY_CODE: "ATAKU",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ATAKUNMOSA WEST",
      CITY_CODE: "ATAKW",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "BOLUWADURO",
      CITY_CODE: "BOLUW",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "BORIPE",
      CITY_CODE: "BORIP",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "EDE NORTH",
      CITY_CODE: "EDE N",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "EDE SOUTH",
      CITY_CODE: "EDE S",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "EGBEDORE",
      CITY_CODE: "EGBEE",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "EJIGBO",
      CITY_CODE: "EJIGB",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IFE CENTRAL",
      CITY_CODE: "IFE C",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IFE EAST",
      CITY_CODE: "IFE E",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IFE NORTH",
      CITY_CODE: "IFE N",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IFE SOUTH",
      CITY_CODE: "IFE S",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IFEDAYO",
      CITY_CODE: "IFEDA",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IFELODUN",
      CITY_CODE: "IFELO",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ILA",
      CITY_CODE: "ILA",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ILESA EAST",
      CITY_CODE: "ILESA",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ILESA WEST",
      CITY_CODE: "ILESW",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IREPODUN",
      CITY_CODE: "IREIR",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IREWOLE",
      CITY_CODE: "IREWO",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ISOKAN",
      CITY_CODE: "ISOKA",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "IWO",
      CITY_CODE: "IWO",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "OBOKUN",
      CITY_CODE: "OBOKU",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ODO OTIN",
      CITY_CODE: "ODO O",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "OLA OLUWA",
      CITY_CODE: "OLA O",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "OLORUNDA",
      CITY_CODE: "OLORU",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "ORIADE",
      CITY_CODE: "ORIAD",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "OROLU",
      CITY_CODE: "OROLU",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "OSHOGBO",
      CITY_CODE: "OSHOG",
    },
    {
      STATE_CODE: "OSUN",
      CITY: "OSOGBO",
      CITY_CODE: "OSOGB",
    },
    {
      STATE_CODE: "OYO",
      CITY: "AFIJIO",
      CITY_CODE: "AFIJI",
    },
    {
      STATE_CODE: "OYO",
      CITY: "AKINYELE",
      CITY_CODE: "AKINY",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ATIBA",
      CITY_CODE: "ATIBA",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ATIGBO",
      CITY_CODE: "ATIGB",
    },
    {
      STATE_CODE: "OYO",
      CITY: "EGBEDA",
      CITY_CODE: "EGBED",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBADAN",
      CITY_CODE: "IBADN",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBADAN NORTH",
      CITY_CODE: "IBADA",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBADAN NORTH-EAST",
      CITY_CODE: "IBANE",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBADAN NORTH-WEST",
      CITY_CODE: "IBANW",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBADAN SOUTH-EAST",
      CITY_CODE: "IBASE",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBADAN SOUTH-WEST",
      CITY_CODE: "IBASW",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBARAPA CENTRAL",
      CITY_CODE: "IBARA",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBARAPA EAST",
      CITY_CODE: "IBARE",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IBARAPA NORTH",
      CITY_CODE: "IBARN",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IDO",
      CITY_CODE: "IDO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IREPO",
      CITY_CODE: "IREPO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ISEYIN",
      CITY_CODE: "ISEYI",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ITESIWAJU",
      CITY_CODE: "ITESI",
    },
    {
      STATE_CODE: "OYO",
      CITY: "IWAJOWA",
      CITY_CODE: "IWAJO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "KAJOLA",
      CITY_CODE: "KAJOL",
    },
    {
      STATE_CODE: "OYO",
      CITY: "LAGELU",
      CITY_CODE: "LAGEL",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OGBOMOSHO NORTH",
      CITY_CODE: "OGBOM",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OGBOMOSHO SOUTH",
      CITY_CODE: "OGBOO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OGO OLUWA",
      CITY_CODE: "OGO O",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OLORUNSOGO",
      CITY_CODE: "OLORO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OLUYOLE",
      CITY_CODE: "OLUYO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ONA ARA",
      CITY_CODE: "ONA A",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ORELOPE",
      CITY_CODE: "ORELO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "ORI IRE",
      CITY_CODE: "ORI I",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OYO",
      CITY_CODE: "OYO",
    },
    {
      STATE_CODE: "OYO",
      CITY: "OYO EAST",
      CITY_CODE: "OYO E",
    },
    {
      STATE_CODE: "OYO",
      CITY: "SAKI EAST",
      CITY_CODE: "SAKI ",
    },
    {
      STATE_CODE: "OYO",
      CITY: "SAKI WEST",
      CITY_CODE: "SAKW",
    },
    {
      STATE_CODE: "OYO",
      CITY: "SURULERE, OYO STATE",
      CITY_CODE: "SURUO",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "BARKIN LADI",
      CITY_CODE: "BARKI",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "BASSA",
      CITY_CODE: "BASSA",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "BOKKOS",
      CITY_CODE: "BOKKO",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "JOS",
      CITY_CODE: "JOS",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "JOS EAST",
      CITY_CODE: "JOS E",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "JOS NORTH",
      CITY_CODE: "JOS N",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "JOS SOUTH",
      CITY_CODE: "JOS S",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "KANAM",
      CITY_CODE: "KANAM",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "KANKE",
      CITY_CODE: "KANKE",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "LANGTANG NORTH",
      CITY_CODE: "LANGT",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "LANGTANG SOUTH",
      CITY_CODE: "LANGL",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "MANGU",
      CITY_CODE: "MANGU",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "MIKANG",
      CITY_CODE: "MIKAN",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "PANKSHIN",
      CITY_CODE: "PANKS",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "QUAAN PAN",
      CITY_CODE: "QUAAN",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "RIYOM",
      CITY_CODE: "RIYOM",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "SHENDAM",
      CITY_CODE: "SHEND",
    },
    {
      STATE_CODE: "PLATE",
      CITY: "WASE",
      CITY_CODE: "WASE",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "ABUA ODUAL",
      CITY_CODE: "ABUA",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "AHOADA EAST",
      CITY_CODE: "AHOAD",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "AHOADA WEST",
      CITY_CODE: "AHOAW",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "AKUKU-TORU",
      CITY_CODE: "AKUKU",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "ANDONI",
      CITY_CODE: "ANDON",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "ASARI-TORU",
      CITY_CODE: "ASARI",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "BONNY",
      CITY_CODE: "BONNY",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "DEGEMA",
      CITY_CODE: "DEGEM",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "ELEME",
      CITY_CODE: "ELEME",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "EMUOHA",
      CITY_CODE: "EMUOH",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "ETCHE",
      CITY_CODE: "ETCHE",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "GOKANA",
      CITY_CODE: "GOKAN",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "IKWERRE",
      CITY_CODE: "IKWER",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "KHANA",
      CITY_CODE: "KHANA",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OBIO/AKPOR",
      CITY_CODE: "OBIO",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OGBA/EGBEMA/NDONI",
      CITY_CODE: "OGBA",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OGU/BOLO",
      CITY_CODE: "OGU",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OKRIKA",
      CITY_CODE: "OKRIK",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OMUMA",
      CITY_CODE: "OMUMA",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OPOBO/NKORO",
      CITY_CODE: "OPOBO",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "OYIGBO",
      CITY_CODE: "OYIGB",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "PORT HARCOURT",
      CITY_CODE: "PORTM",
    },
    {
      STATE_CODE: "RIVER",
      CITY: "TAI",
      CITY_CODE: "TAI",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "BINJI",
      CITY_CODE: "BINJI",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "BODINGA",
      CITY_CODE: "BODIN",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "DANGE SHUNI",
      CITY_CODE: "DANGE",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "GADA",
      CITY_CODE: "GADA",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "GORONYO",
      CITY_CODE: "GORON",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "GUDU",
      CITY_CODE: "GUDU",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "GWADABAWA",
      CITY_CODE: "GWADA",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "ILLELA",
      CITY_CODE: "ILLEL",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "ISA",
      CITY_CODE: "ISA",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "KEBBE",
      CITY_CODE: "KEBBE",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "KWARE",
      CITY_CODE: "KWARE",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "RABAH",
      CITY_CODE: "RABAH",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "SABON BIRNI",
      CITY_CODE: "SABON",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "SHAGARI",
      CITY_CODE: "SHAGS",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "SILAME",
      CITY_CODE: "SILAM",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "SOKOTO",
      CITY_CODE: "SOKTO",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "SOKOTO NORTH",
      CITY_CODE: "SOKOT",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "SOKOTO SOUTH",
      CITY_CODE: "SOKOS",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "TAMBUWAL",
      CITY_CODE: "TAMBU",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "TANGAZA",
      CITY_CODE: "TANGA",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "TURETA",
      CITY_CODE: "TURET",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "WAMAKO",
      CITY_CODE: "WAMAK",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "WURNO",
      CITY_CODE: "WURNO",
    },
    {
      STATE_CODE: "SOKOT",
      CITY: "YABO",
      CITY_CODE: "YABO",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "ARDO KOLA",
      CITY_CODE: "ARDO ",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "BALI",
      CITY_CODE: "BALI",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "DONGA",
      CITY_CODE: "DONGA",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "GASHAKA",
      CITY_CODE: "GASHA",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "GASSOL",
      CITY_CODE: "GASSO",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "IBI",
      CITY_CODE: "IBI",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "JALINGO",
      CITY_CODE: "JALIN",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "KARIM LAMIDO",
      CITY_CODE: "KARIM",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "KUMI",
      CITY_CODE: "KUMI",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "LAU",
      CITY_CODE: "LAU",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "SARDAUNA",
      CITY_CODE: "SARDA",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "TAKUM",
      CITY_CODE: "TAKUM",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "USSA",
      CITY_CODE: "USSA",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "WUKARI",
      CITY_CODE: "WUKAR",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "YORRO",
      CITY_CODE: "YORRO",
    },
    {
      STATE_CODE: "TARAB",
      CITY: "ZING",
      CITY_CODE: "ZING",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "BADE",
      CITY_CODE: "BADE",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "BURSARI",
      CITY_CODE: "BURSA",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "DAMATURU",
      CITY_CODE: "DAMAT",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "FIKA",
      CITY_CODE: "FIKA",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "FUNE",
      CITY_CODE: "FUNE",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "GEIDAM",
      CITY_CODE: "GEIDA",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "GUJBA",
      CITY_CODE: "GUJBA",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "GULANI",
      CITY_CODE: "GULAN",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "JAKUSKO",
      CITY_CODE: "JAKUS",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "KARASUWA",
      CITY_CODE: "KARAS",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "MACHINA",
      CITY_CODE: "MACHI",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "NANGERE",
      CITY_CODE: "NANGE",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "NGURU",
      CITY_CODE: "NGURU",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "POTISKUM",
      CITY_CODE: "POTIS",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "TARMUWA",
      CITY_CODE: "TARMU",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "YUNUSARI",
      CITY_CODE: "YUNUS",
    },
    {
      STATE_CODE: "YOBE",
      CITY: "YUSUFARI",
      CITY_CODE: "YUSUF",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "ANKA",
      CITY_CODE: "ANKA",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "BAKURA",
      CITY_CODE: "BAKUR",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "BIRNIN MAGAJI/KIYAW",
      CITY_CODE: "BIRNM",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "BUKKUYUM",
      CITY_CODE: "BUKKU",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "BUNGUDU",
      CITY_CODE: "BUNGU",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "CHAFE",
      CITY_CODE: "CHAFE",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "GUMMI",
      CITY_CODE: "GUMMI",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "GUSAU",
      CITY_CODE: "GUSAU",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "KAURA NAMODA",
      CITY_CODE: "KAURN",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "MARADUN",
      CITY_CODE: "MARAD",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "MARU",
      CITY_CODE: "MARU",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "SHINKAFI",
      CITY_CODE: "SHINK",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "TALATA MAFARA",
      CITY_CODE: "TALAT",
    },
    {
      STATE_CODE: "ZAMFA",
      CITY: "ZURMI",
      CITY_CODE: "ZURMI",
    },
  ];