import { format } from 'date-fns';
// MSL COLUMN
const mslColumns = [
    {
      title: "S/N",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Status",
      dataIndex: "email_verified_at",
      key: "email_verified_at",
      render: (email_verified_at) => {
        return email_verified_at !== null ? "Active" : "In-Active";
      },
      sorter: (a, b) => {
        return a.email_verified_at === b.email_verified_at ? 0 : a.email_verified_at ? 1 : -1;
      },
    },
  ];

// NYSC COLUMN
  const nyscColumns = [
    {
      title: "S/N",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => a.gender.localeCompare(b.gender),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username ",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (active) => {
        return active ? "Active" : "In-Active";
      },
      sorter: (a, b) => {
        return a.active === b.active ? 0 : a.active ? 1 : -1;
      },
    },
    {
      title: "Callup Number",
      dataIndex: "callup_no",
      key: "callup_no",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Batch Info",
      dataIndex: "full_bacth_info",
      key: "full_bacth_info",
    },
    {
      title: "Deployed State",
      dataIndex: "deployed_state",
      key: "deployed_state",
    },
  ];
  const stanbicColumns = [
    {
      title: "S/N",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (date_created) => {
        if (!date_created) {
          return "N/A"; // or some other default value
        }
        const parsedDate = new Date(date_created);
        
        if (isNaN(parsedDate.getTime())) {
          return "Invalid Date"; // or handle the error in a way that fits your app
        }
        
        return format(parsedDate, 'yyyy-MM-dd');
      },
      sorter: (a, b) => new Date(b.date_created) - new Date(a.date_created),
    },
  ];
  const transportColumns = [
    {
      title: "S/N",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Origin",
      dataIndex: "email_verified_at",
      key: "email_verified_at",
    },
    {
      title: "Destination",
      dataIndex: "email_verified_at",
      key: "email_verified_at",
      render: (email_verified_at) => {
        return email_verified_at !== null ? "Active" : "In-Active";
      },
      sorter: (a, b) => {
        return a.email_verified_at === b.email_verified_at ? 0 : a.email_verified_at ? 1 : -1;
      },
    },
    {
      title: "No of seats",
      dataIndex: "email_verified_at",
      key: "email_verified_at",
    },
    {
      title: "Amount",
      dataIndex: "email_verified_at",
      key: "email_verified_at",
    },
  ];

  export {mslColumns, nyscColumns, stanbicColumns, transportColumns};
