import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Button } from "antd";
import axios from "axios";

const PhoneOtpModal = ({
  isModalVisible,
  setIsModalVisible,
  setOtpSuccess,
  phoneNumber,
  reference,
  setReference,
  maskPhoneNumber,
}) => {
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(60);
  const [disableResend, setDisableResend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLocalhost = window.location.hostname === "localhost1";

  const clientIdApi = isLocalhost
    ? process.env.REACT_APP_TEST_BVN_CLIENT_ID
    : process.env.REACT_APP_BVN_CLIENT_ID;
  const clientSecretApi = isLocalhost
    ? process.env.REACT_APP_TEST_BVN_CLIENT_SECRET_KEY
    : process.env.REACT_APP_BVN_CLIENT_SECRET_KEY;
  useEffect(() => {
    let interval;
    if (isModalVisible && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0 || !isModalVisible) {
      clearInterval(interval);
      setDisableResend(false);
    }
    return () => clearInterval(interval);
  }, [timer, disableResend, isModalVisible]);

  useEffect(() => {
    if (isModalVisible) {
      setTimer(60); // Reset timer every time the modal is opened
    }
  }, [isModalVisible]);

  const resendOTP = async () => {
    const otpValidation = isLocalhost
      ? process.env.REACT_APP_TEST_BVN_REQUEST_OTP_VALIDATION
      : process.env.REACT_APP_BVN_REQUEST_OTP_VALIDATION;

    setLoading(true);
    setDisableResend(true);
    try {
      const otpResponse = await axios.post(
        otpValidation,
        {
          phoneNumber: phoneNumber,
          reasonCode: "99",
          reasonDescription: "YOUTHPLUS ACCOUNT CREATION",
          channelId: 6,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Client-Id": clientIdApi,
            "Client-Secret": clientSecretApi,
          },
        }
      );
      setTimer(60);
      message.success(otpResponse.data.responseMsg);
      setReference(otpResponse?.data?.responseDetails?.reference);
    } catch (otpError) {
      console.error("OTP Validation Error", otpError);
      message.error("Failed to resend OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOk = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      const apiUrl = isLocalhost
        ? process.env.REACT_APP_TEST_BVN_VALIDATE_OTP
        : process.env.REACT_APP_BVN_VALIDATE_OTP;
      const response = await axios.post(
        apiUrl,
        {
          token: values.otp,
          phoneNumber: phoneNumber,
          customerIdType: "phoneNumber",
          reference: reference,
          channelId: 6,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Client-Id": clientIdApi,
            "Client-Secret": clientSecretApi,
          },
        }
      );

      if (response.data.responseMsg === "OTP validated successfully") {
        message.success("OTP verified successfully!");
        setIsModalVisible(false);
        setOtpSuccess(true);
      } else {
        message.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      message.error("OTP verification failed: " + error.message);
      console.log("API call failed: ", error);
    } finally {
      setIsLoading(false);
      form.resetFields();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Correct this to actually close the modal
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <>
      <Modal
        title={`Enter OTP Code Sent to ${maskPhoneNumber(phoneNumber)}`}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="resend"
            onClick={resendOTP}
            disabled={timer > 0}
            loading={loading}
          >
            Resend OTP {timer > 0 && `(${formatTimer()}s)`}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            loading={isLoading}
          >
            Submit OTP
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" name="phoneOtpForm">
          <Form.Item
            name="otp"
            label="OTP"
            rules={[{ required: true, message: "Please input the OTP!" }]}
          >
            <Input.OTP length={5} required />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PhoneOtpModal;
