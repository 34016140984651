import React from "react";
import { Link } from "react-router-dom";
import ArrowDown from "../asset/arrow-down.png";
import ArrowUp from "../asset/arrow-up.png";
import PartnerOptionsModal from "./partnersOptions";

function SignSmallScreens(
  {
    show,
    email,
    emailChange,
    handleEmailChange,
    firstLand,
    handleFirstLand,
    handleCallUpNumberChange,
    handleShowBenefits,
    YouthPlusLogo,
    showBenefits,
    callUpNumber,
    profile,
    isChecked,
    handleCheckboxChange,
    toggle,
    isSignIn,
    handleUserNameChange,
    username,
    handlePasswordChange,
    password,
    errors,
    handleConfirmPasswordChange,
    confirmPassword,
    loginDetailsIsChecked,
    handleLoginDetailsCheckboxChange,
    handleShow,
    stanbicIsChecked,
    createYouthPlusAccount,
    handleStanbicCheckboxChange,
    handleClose,
    hasQuicktellerConsent,
    handleHasQuicktellerConsentChange,
    hasCowryConsent,
    handleHasCowryConsentChange,
    hasMSLConsent,
    handleHasMSLConsentChange,
    hasProductDiveConsent,
    handleHasProductDiveConsentChange,
  },
  props
) {
  return (
    <div>
      <div className="row" style={{height:'90vh'}}>
        <div
          style={{ position: "relative" }}
          className="col-sm-6 mb-2 left-col "
        >
          <div>
            <img
              src={YouthPlusLogo}
              alt="YouthPlus Logo"
              className="logo-image"
            />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            className="left-text"
          >
            <h4 className="header-text">
              Connect with Fellow NYSC Corp Members
            </h4>
            {showBenefits && <h6>What We Offer Corp Members.</h6>}
          </div>
          {showBenefits && (
            <div style={{ padding: "1rem" }} className="offers">
              <ul>
                <li>
                  <b>Community Development Project Ideas:</b> A platform to
                  brainstorm and collaborate on community service projects.
                </li>
                <li>
                  <b>Marketplace:</b> A marketplace for corp members to buy and
                  sell items they need for camp or their service year.
                </li>
                <li>
                  <b>Job and Career Opportunities:</b> A job board featuring
                  employment opportunities and internships suitable for NYSC
                  graduates.
                </li>
                <li>
                  <b>Feedback System:</b> Allowing corp members to provide
                  feedback on their experiences and suggest improvements to the
                  NYSC program.
                </li>
              </ul>
            </div>
          )}
          <img
            onClick={handleShowBenefits}
            style={{
              width: "4rem",
              position: "absolute",
              bottom: -32,
              cursor: "pointer",
            }}
            src={showBenefits ? ArrowUp : ArrowDown}
            alt="Arrow Down"
          />
        </div>
        <div className="col right-col">
          {/* Sign-Up Form */}
          <div className={isSignIn ? "right-signup" : "hidden"}>
            {firstLand === true ? (
              <div>
                <div className="input-group form-fields">
                  {/* <i class="fa fa-envelope" aria-hidden="true"></i> */}

                  <input
                    type="email"
                    placeholder="Email"
                    value={email === undefined ? emailChange : email}
                    readOnly={email === undefined ? false : true}
                    onChange={email === undefined ? handleEmailChange : ""}
                  />
                </div>
                <div className="form-fields">
                  {/* <i class="fa fa-id-badge" aria-hidden="true"></i> */}
                  <input
                    type="text"
                    placeholder="Call Up Number or Phone Number"
                    onChange={handleCallUpNumberChange}
                    value={callUpNumber}
                  />
                </div>

                <div>
                  <div class="checkbox-container">
                    <label htmlFor="checkbox">
                      <input type="checkbox" id="termsCheckbox" />
                      <span className="spaced-element">
                        By signing in to Youthplus, you agree to our
                        <Link
                          to="/terms"
                          target="_blank"
                          className="links-style"
                        >
                          <b> Terms </b>
                        </Link>
                        and
                        <Link
                          to="/policy"
                          target="_blank"
                          className="links-style"
                        >
                          <b> Privacy Policy </b>
                        </Link>
                        .
                      </span>
                    </label>
                  </div>
                </div>
                <button onClick={handleFirstLand}>Confirm</button>
              </div>
            ) : (
              <div>
                <div className="form-fields">
                  {/* <i class="fa fa-user-circle" aria-hidden="true"></i> */}
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={profile?.CMName}
                    readOnly
                  />
                </div>
                <div className="form-fields">
                  {/* <i class="fa fa-envelope" aria-hidden="true"></i> */}
                  <input
                    type="email"
                    placeholder="Email"
                    value={profile?.EmailAddress}
                    readOnly
                  />
                </div>
                <div className="form-fields">
                  {/* <i class="fa fa-mobile" aria-hidden="true"></i> */}
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={profile?.GSMNo}
                    readOnly
                  />
                </div>
                <div className="form-fields">
                  {/* <i class="fa fa-id-badge" aria-hidden="true"></i> */}
                  <input
                    type="text"
                    placeholder="Call Up Number"
                    value={profile?.Callupno}
                    readOnly
                  />
                </div>
                <div className="input-group">
                  <i class="fa fa-user-circle" aria-hidden="true"></i>
                  <input
                    type="gender"
                    placeholder="Gender"
                    value={
                      profile?.Gender === "M"
                        ? "Male"
                        : profile?.Gender === "F"
                        ? "Female"
                        : ""
                    }
                    readOnly
                  />
                </div>
                <div>
                  <div class="checkbox-container">
                    <label htmlFor="checkbox">
                      <input
                        type="checkbox"
                        id="termsCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <span className="spaced-element">
                        By signing in to Youthplus, you agree to our
                        <Link
                          to="/terms"
                          target="_blank"
                          className="links-style"
                        >
                          <b> Terms </b>
                        </Link>
                        and
                        <Link
                          to="/policy"
                          target="_blank"
                          className="links-style"
                        >
                          <b> Privacy Policy </b>
                        </Link>
                        .
                      </span>
                    </label>
                  </div>
                </div>
                <button onClick={toggle}>Confirm Details</button>
              </div>
            )}
          </div>
          {/* Sign-In Form */}
          <div className={isSignIn ? "hidden" : "right-signup"}>
            <div className="form-fields">
              {/* <i className="bx bxs-user"></i> */}
              <input
                type="text"
                placeholder="Username"
                onChange={handleUserNameChange}
                value={username}
              />
            </div>
            <div className="form-fields">
              {/* <i className="bx bxs-user"></i> */}
              <input
                type="password"
                placeholder="Password"
                onChange={handlePasswordChange}
                value={password}
              />
            </div>
            <div className="error-messages">
              {Object.entries(errors.password).map(([key, value], index) => (
                <p
                  key={index}
                  style={{
                    color: value ? "green" : "red",
                    fontSize: "x-small",
                    textAlign: "left",
                    marginBottom: "0.3em",
                  }}
                >
                  {value
                    ? `Password meets ${key} condition.`
                    : key === "minLength"
                    ? " Password must be a minimum length of 6"
                    : "Password must contain at least one " + key}
                </p>
              ))}
            </div>
            <div className="form-fields">
              {/* <i className="bx bx-mail-send"></i> */}
              <input
                type="password"
                placeholder="Confirm Password"
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
              />
            </div>
            <div className="error-messages">
              <p
                style={{
                  color: errors.confirmPassword ? "red" : "green",
                  fontSize: "x-small",
                  textAlign: "left",
                  marginBottom: "0.3em",
                }}
              >
                {errors.confirmPassword}
              </p>
            </div>
            <div>
              <div class="checkbox-container">
                <label htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={loginDetailsIsChecked}
                    onChange={handleLoginDetailsCheckboxChange}
                  />
                  <span className="spaced-element">
                    By signing in to Youthplus, you agree to our
                    <Link to="/terms" target="_blank" className="links-style">
                      <b> Terms </b>
                    </Link>
                    and
                    <Link to="/policy" target="_blank" className="links-style">
                      <b> Privacy Policy </b>
                    </Link>
                    .
                  </span>
                </label>
              </div>
            </div>
            <button
              onClick={handleShow}
              disabled={
                username === ""
                  ? true
                  : password === ""
                  ? true
                  : confirmPassword === ""
                  ? true
                  : false
              }
              style={{
                cursor:
                  username === ""
                    ? "not-allowed"
                    : password === ""
                    ? "not-allowed"
                    : confirmPassword === ""
                    ? "not-allowed"
                    : confirmPassword !== password
                    ? "not-allowed"
                    : loginDetailsIsChecked === false
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              Confirm
            </button>
            <PartnerOptionsModal
              show={show}
              handleClose={handleClose}
              stanbicIsChecked={stanbicIsChecked}
              handleStanbicCheckboxChange={handleStanbicCheckboxChange}
              hasQuicktellerConsent={hasQuicktellerConsent}
              handleHasQuicktellerConsentChange={
                handleHasQuicktellerConsentChange
              }
              hasCowryConsent={hasCowryConsent}
              handleHasCowryConsentChange={handleHasCowryConsentChange}
              hasMSLConsent={hasMSLConsent}
              handleHasMSLConsentChange={handleHasMSLConsentChange}
              hasProductDiveConsent={hasProductDiveConsent}
              handleHasProductDiveConsentChange={
                handleHasProductDiveConsentChange
              }
              createYouthPlusAccount={createYouthPlusAccount}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignSmallScreens;
