import React from "react";
import { Modal, Button, Divider} from "antd";
import one from "../asset/one-icon.svg";
import two from "../asset/two-icon.svg";

const ConfirmDetailsModal = ({
  visible,
  onClose,
  onUpdateDetails,
  onConfirm,
  passengerDetails,
  selectedSeats,
  contactDetails,
}) => {
  // console.log("passengerDetails",passengerDetails);
  return (
    <Modal
      title="Confirm details"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onUpdateDetails}>
          Update details
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Confirm
        </Button>,
      ]}
    > 
      <div style={{ color: "red", marginBottom: "10px" }}>
        Please carefully review all the information you have entered. Once you
        proceed, you will not be able to modify these details. Ensure everything
        is correct before clicking on the "Confirm" button.
      </div>
      
      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <div className="align-cont" style={{marginBottom:"1rem", display:"flex", alignContent:"center",padding:"0.2rem",gap:"1rem"}}>
            <img src={one} alt="icon" style={{width:30,height:30, margin:"0"}} />
            <p className="pdets-text" style={{fontWeight: 600, margin:"0"}}>Passengers Details</p>
        </div>
          {passengerDetails.map((passenger, index) =>{
            return(
              <>
            <p style={{fontSize:16,color:"#222",fontWeight:500,marginBottom:0}}>{index + 1}. Passenger</p>
            <div style={{paddingLeft:"1rem", marginTop:"0.3rem"}}>  
            <div style={{display:"flex", gap:"1rem"}}>
              
              <div style={{display:"flex", flexDirection:"column", gap:""}}>
                <div style={{marginBottom:15}}><p className="grey">Gender</p><p className="p-size"> {passenger.gendervalue}</p></div>
                <div style={{marginBottom:15}}><p className="grey">First Name</p><p className="p-size"> {passenger.firstname}</p></div>
                <div style={{marginBottom:15}}><p className="grey">Email</p><p className="p-size"> {passenger.email}</p></div>
                <div style={{marginBottom:15}}><p className="grey">Seat Number</p><p className="p-size"> {selectedSeats[index]}</p></div>
              </div>
              <div style={{display:"flex", flexDirection:"column", gap:""}}>
                <div style={{marginBottom:15}}><p className="grey">Title</p><p className="p-size"> {passenger.titlevalue}</p></div>
                <div style={{marginBottom:15}}><p className="grey">Last Name</p><p className="p-size"> {passenger.lastname}</p></div>
                <div style={{marginBottom:15}}><p className="grey">Phone Number</p><p className="p-size"> {passenger.phone}</p></div>
              </div>
            </div>
              
              </div>
              </>
              )
          }) }
           
        <Divider />
        <div className="align-cont" style={{marginBottom:"1rem", display:"flex", alignContent:"center",padding:"0.2rem",gap:"1rem"}}>
            <img src={two} alt="icon" style={{width:30,height:30, margin:"0"}} />
            <p className="pdets-text" style={{fontWeight: 600, margin:"0"}}>Contact Details</p>
        </div>
        <div style={{ paddingLeft:"1rem"}}>
           <div style={{marginBottom:15}}> <p className="grey">Email</p><p className="p-size"> {contactDetails.email}</p></div>
           <div style={{marginBottom:15}}><p className="grey">Phone Number</p><p className="p-size"> {contactDetails.phone}</p></div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDetailsModal;
