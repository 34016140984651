import React from 'react';
import "./Terms.css";
import { Link } from "react-router-dom";



const PrivacyPolicy = () => {
    return (
        <div>
            <div className='youthplus-policy'>
                <h2 className='policy-header1'>
                    YOUTHPLUS SOLUTION
                </h2>
                <hr className='horizontal' />
                <h3 className='policy-header1'>
                    PRIVACY POLICY, TERMS & CONDITIONS OF USE & NDPR COMPLIANCE
                </h3>
                <h3 className='policy-header1'>PRIVACY POLICY </h3>
                <p>
                    Sidmach Technologies Nigeria Limited (Sidmach) and its subsidiaries (together “Sidmach”, “our”, “us”, or “we”) recognize the importance of protecting your personal information and are committed to processing it responsibly and in compliance with applicable data protection laws.
                </p>
                <p>
                    This Privacy Notice describes the types of information Sidmach may collect from you and others and our practices for using, maintaining, protecting, and disclosing that information.
                </p>
                <p>
                    We are committed to managing your Personal Information in line with global industry best practices. Please read our Privacy Policy to understand how we use your information and the steps we take to protect your information.
                </p>
                <h3 className='policy-headers3'>About Us</h3>
                <p>
                    The YouthPlus Solution is a platform (website, apps, and all related sites) that addresses the informational, educational, career, and transformational needs of all categories of youths, youth organizations, youth administrators, and other stakeholders by availing them of relevant accessible opportunities and essential services.
                </p>
                <p>
                    The platform essentially offers strategic value-added services to youths especially those in the underserved and unserved areas of the country.
                </p>
                <p>
                    The platform also provides the youths with an avenue to interact and connect with strategic top brands, thus giving them an understanding and enhanced experience of what the future holds.
                </p>
                <h3 className='policy-headers3'>1.0	    Consent</h3>
                <p>
                    You accept and consent to the terms of this Privacy Policy when you sign up for, access, or use the YouthPlus Solution and services offered on this platform and or with our value-added service partners (collectively “Services”). If you continue to use this platform, you are agreeing to comply with and be bound by the terms and conditions of use, which together with our Privacy Policy govern our relationship with you about this platform. If you disagree with any part of these terms and conditions, you are ,not permitted to use this platform.
                </p>
                <h3 className='policy-headers3'>2.0 	Changes to our Privacy Policy</h3>
                <p>
                    We may need to update, modify or amend our Terms of Use as our technology evolves. We reserve the right to make changes to this Terms of Use at any time by giving notice to users on this page.
                </p>
                <p>
                    We advise that you check this page often, referring to the date of the last modification on the page If a user objects to any of the changes to the Terms of Use, the User must cease using our website and/or services immediately.
                </p>
                <h3 className='policy-headers3'>3.0	    Information We May Collect When You Browse on this Platform</h3>
                <ul>
                    <li>
                        <b className='list-headers'>Non-Personally Identifiable Information</b>
                        <p>
                            When you visit the platform, we may passively collect non-personally identifiable information about you via certain Internet technology tools. This information consists of your IP address, cookies, and web beacons, as well as information regarding the type of browser and operating system that you use, along with other similar analytic data. Your "IP address" is usually associated with the network location and physical location from which you enter the Internet.
                        </p>
                    </li>
                    <li>
                        <b className='list-headers'>Personally Identifiable Information</b>
                        <p>
                            In your use of the platform, we may collect any information that you voluntarily share with us. This information may be collected when you send us emails or voluntarily submit information to us via the platform or inquiry page. This information may include your (1) contact information (such as name, address, email address, and phone number), (2) age, (3) gender, (4) date of birth, (5) card details, and (6) any other information you choose to provide us, subject to applicable laws and the terms of this Privacy Policy. We may use third-party service providers to collect, provide additional services to you the user and maintain this personally identifiable information. However, we require such service providers to maintain the confidentiality of such information.
                        </p>
                    </li>
                </ul>
                <h3 className='policy-headers3'>4.0 	How We Use the Information We Collect</h3>
                <ul>
                    <li>
                        <b className='list-headers'>Non-Personally Identifiable Information</b>
                        <p>
                            We use non-personally identifiable information in the aggregate to determine how much traffic the platform receives, to statistically analyze its usage, to improve our content, and to customize the platform's content, layout, and services. In addition, we may use your IP address to help diagnose problems with our server, to manage and to enhance the platform based on the usage pattern data we receive.
                        </p>
                    </li>
                    <li>
                        <b className='list-headers'>Personally Identifiable Information</b>
                        <p>
                            Personal Data shall be collected and processed by us and our value-added service partners, in accordance with legitimate and lawful purposes consented to by you, for archiving, scientific research, historical research, or statistical purposes for the public interest. We will legitimately and lawfully process personal data if at least one of the following applies: (i) you have given consent to the processing of your Personal Data for one or more specific purposes; (ii) processing is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior to entering a contract; (iii) processing is necessary for compliance with a legal obligation to which we are subject; (iv) processing is necessary in order to protect your vital interests or of another natural person and (v) processing is necessary for the performance of a task carried out in the public interest or the exercise of official public mandate vested in us.
                        </p>
                        <p>
                        Where disclosure of any information referred to in this section would be in the public interest, and if the public interest in the disclosure of such information outweighs the protection of the privacy of the individual to whom such information relates, we shall disclose such information lawfully.
                        </p>
                    </li>
                </ul>
                <h3 className='policy-headers3'>5.0 	Access to Your Personally Identifiable Information</h3>
                <p>
                    Upon written request and verification of your identity, we will provide you with your personal information in our possession as well as the personal information, if any, that we have disclosed to third parties. Requests for such information should be sent to the contact information below.
                </p>
                <h3 className='policy-headers3'>6.0 	Handling of Electronic Records and Backup</h3>
                <p>
                    In general, we will retain all information collected through the platform for, at a minimum, the length of time permitted by law. However, we will delete any personally identifiable information in our database upon your request or as otherwise required by law. We may, however, retain non-personally identifiable information indefinitely.
                    We maintain backup files as a protection against natural disasters, equipment failures, or other disruptions. Backup files protect you and us because they lower the risk of losing valuable data. Backup files may contain records with your personal information. Removing a record from our active files and databases does not remove that record from any backup systems. Such backup data will eventually be passively deleted as backup records are erased through the normal recycling of backup files. In the meantime, as long as backup records exist, they receive the same security protections as our other records.
                </p>
                <h3 className='policy-headers3'>7.0	    Security</h3>
                <p>
                    Communications between your browser and portions of the YouthPlus platform containing personally identifiable information are protected with Secure Socket Layer (SSL) encryption. This encryption is to help protect your information while it is being transmitted. Once we receive your information, we strive to maintain the physical and electronic security of your personal information using commercially reasonable efforts.
                    No data transmission over the internet or any wireless network can be guaranteed to be perfectly secured. As a result, while we strive to protect your personal information using commercially available and standard encryption technology, we cannot ensure or guarantee the security of any information you transmit to us, and you do so at your own risk.
                </p>
                <h3 className='policy-headers3'>8.0     In the Event of a Security Breach of Your Personal Information</h3>
                <p>
                    If we determine that, your personal information has or may reasonably have been disclosed due to a security breach of our systems, we will notify you, at the contact information provided to us, in accordance with and to the extent required by applicable state and federal law.
                </p>
                <h3 className='policy-headers3'>9.0     Third-Party Privacy Practices</h3>
                <p>
                    This Privacy Policy applies only to this platform. This platform may contain links to other platforms, which may be subject to a different privacy policy or are otherwise maintained or provided by our value-added service partners. We are not responsible for the privacy practices of any third-party platform you access from this platform. You should review the privacy policy of every platform before using the platform or submitting any information to the platform.
                </p>
                <h3 className='policy-headers3'>10.0    Links to Other Websites</h3>
                <p>
                    Certain transaction processing channels may require links to other websites by our value-added service partners. Please note that we are not responsible for and have no control over third-party websites. Opinions expressed or materials appearing on such websites are not necessarily shared or endorsed by us, and should not be regarded as the publisher of such opinions or materials. Please be aware that we are not responsible for the privacy practices, or content of any third-party sites and we encourage our users to read the privacy statements of these sites. You should evaluate the security and trustworthiness of any other site connected to this platform or accessed through this platform yourself, before disclosing any personal information to it. SIDMACH will not accept any responsibility for any loss or damage in whatever manner, howsoever caused, resulting from your disclosure to third parties of personal information.
                </p>
                <h3 className='policy-headers3'>11.0    Cookies</h3>
                <p>
                    The term "cookies" refers to electronic data stored by your computer browser. Cookies are small pieces of text used to store information in web browsers. Cookies are used to store and receive identifiers and other information on computers, phones, and other devices. Other technologies, including data that we store on your web browser or device, identifiers associated with your device, and other software, are used for similar purposes. In this policy, we refer to all of these technologies as "cookies".
                    The cookies that we use include session cookies, which are deleted when you close your browser, and persistent cookies, which stay in your browser until they expire or you delete them.
                </p>
                <p>
                    We use cookies to recognize your browser or device, learn more about your interests, protect, improve, and provide you with essential features and services and for additional purposes, including:
                </p>
                <ul>
                    <li>
                        Recognizing you when you sign in to our services. This allows us to provide you with content recommendations, display personalized content, and provide other customized features and services.
                    </li>
                    <li>
                        Keeping track of your specified preferences. This allows us to honour your preferences, such as whether or not you would like to see interest-based ads.
                    </li>
                    <li>Conducting research and diagnostics to improve content and services.</li>
                    <li>Improving security.</li>
                    <li>Delivering content, including ads.</li>
                    <li>Reporting. This allows us to measure and analyse the performance of our services.
                        The cookies that we use and how we use them may change over time as we improve and update our website.</li>
                </ul>
                <h3 className='policy-headers3'>12.0    Refund/Return Policy</h3>
                <p>SIDMACH has a no refund/return policy on payment of online transactions made by users of the platform.</p>
                <h3 className='policy-headers3'>13.0    Enforcement</h3>
                <p>
                    If for some reason you believe SIDMACH has not adhered to the above principles, please notify us by email at corporate@sidmach.com and we will do our best to determine and correct the problem promptly. Be certain the words Privacy Policy are in the Subject line.
                </p>
                <h3 className='policy-headers3'>14.0    Contact Information</h3>
                <p>If you have any questions or concerns about the YouthPlus’s Privacy Policy or privacy practice, please contact us at: corporate@sidmach.com
                </p>
                <p>
                    For more information about our terms – please visit our 
                    <Link to='/terms' rel="noreferrer"> Terms page.</Link>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
