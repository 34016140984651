import React from "react";
import { Button } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import English locale

// Make sure to set the locale to English
dayjs.locale("en");

const BookingNav = ({ selectedDate, setSelectedDate }) => {
  // Ensure currentDate is set to selectedDate or fallback to the current date
  const currentDate = selectedDate || new Date();

  // Handler for previous date button click
  const handlePrevDate = () => {
    const prevDate = dayjs(currentDate).subtract(1, "day").toDate();
    setSelectedDate(prevDate);
    console.log("Selected Date:", dayjs(prevDate).format("YYYY-MM-DD"));
  };

  // Handler for next date button click
  const handleNextDate = () => {
    const nextDate = dayjs(currentDate).add(1, "day").toDate();
    setSelectedDate(nextDate);
    console.log("Selected Date:", dayjs(nextDate).format("YYYY-MM-DD"));
  };

  return (
    <div style={{ padding: "0 10%" }}>
      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
        }}
      >
        <Button
          style={{ width: "33%", overflow: "hidden" }}
          onClick={handlePrevDate}
        >
          {dayjs(currentDate).subtract(1, "day").format("MMMM DD, YYYY")}
        </Button>
        <Button style={{ width: "33%", overflow: "hidden" }}>
          {dayjs(currentDate).format("MMMM DD, YYYY")}
        </Button>
        <Button
          style={{ width: "33%", overflow: "hidden" }}
          onClick={handleNextDate}
        >
          {dayjs(currentDate).add(1, "day").format("MMMM DD, YYYY")}
        </Button>
      </div>
    </div>
  );
};

export default BookingNav;