import React, { createContext, useContext, useCallback } from "react";
import { api, quickTellerApi } from "../api";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const apiCall = useCallback(
    async (
      endpoint,
      method = "GET",
      data = null,
      useQuickTellerApi = false,
      headers = {}
    ) => {
      // debugger;
      const axiosInstance = useQuickTellerApi ? quickTellerApi : api;
      try {
        const response = await axiosInstance({
          url: endpoint,
          method,
          data,
          headers,
        });
        return response.data;
      } catch (error) {
        console.error(`API call to ${endpoint} failed:`, error);
        throw error;
      }
    },
    []
  );

  return (
    <ApiContext.Provider value={{ apiCall }}>{children}</ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
