import React, { useState } from "react";
import { Modal, Form, Input, Button, Typography, } from "antd";
import axios from "axios";
import { toast } from 'react-toastify';
import passwordImg from '../asset/forgot password.png';
import { useNavigate } from 'react-router-dom';

// import { LoadingOutlined } from '@ant-design/icons';

const PartnerLoginModal = ({ show, onCancel, onLoginSuccess, formData, setFormData}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [resetEmail, setResetEmail] = useState()

  //   const [loggedIn, setLoggedIn] = useState(false);
  const [errors, setErrors] = useState({});
  const handleResetEmailChange = (e) =>{
    setResetEmail(e.target.value)
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "email is required";
    }else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid, please input a valid email';
    }
    if (!formData.password) {
      errors.password = "password is required";
    }
    return errors;
  };

  const handleLogin = async (e) => {
    // debugger
    if (!navigator.onLine) {
      alert("No Internet");
      return;
    } else {
      e.preventDefault();
      setLoading(true); // Set loading to true before making the request
      const validationErrors = validateForm();

      if (Object.keys(validationErrors).length === 0) {
        try {
          // Make Login  request
          const response = await axios.post(
            `${process.env.REACT_APP_PARTNER_LOGIN}`,
            formData
          );
          if (response.status === 200) {
            toast.success("Login successful");
            setFormData({
              email:"",
              password:"",
            });
            const responseData = response.data;
            onLoginSuccess(responseData);
            onCancel();
          }
        } catch (response) {
          console.log("response",response);
          toast.error(
            `${response.response.data.message}`
          );
        }
      } else {
        // Form has errors, update state to display error messages
        setErrors(validationErrors);
      }

      setLoading(false); // Set loading back to false after request completion
    }
  };
  const handleResetPassword = async (e) => {
    // debugger
    if (!navigator.onLine) {
      alert("No Internet");
      return;
    } else {
      e.preventDefault();
      setLoading(true); // Set loading to true before making the request
      // const validationErrors = validateForm();

      
        try {
          // Make Login  request
          const response = await axios.post(
            `${process.env.REACT_APP_PARTNER_FORGOT_PASSWORD}`,
            { email:resetEmail },
          );
          ;
          if (response.status === 200) {
            toast.success("Password reset link sent to email");
            // console.log(response);
            navigate('/forgot-password');
            
            onCancel();
          }
        } catch (response) {
          console.log("response",response);
          toast.error(
            `${response.response.data.error}`
          );

        } 
     

      setLoading(false); // Set loading back to false after request completion
    }
  };
    
  const [forgotPassword, setForgotPassword] = useState(true);
  function handleForgotPassword (){
    setForgotPassword(false)
  }

  return (
    <>
    {forgotPassword ? (<Modal
      centered
      open={show}
      footer={null}
      closable={false}
      maskClosable={false}
      mask={true}
      onCancel={onCancel}
    >
          <div className='form-modal'>
            <div style={{ textAlign: 'center',marginBottom: '2rem'}}>
                <Typography style={{color:'#08839B',fontSize: '1.3rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal'
                    }}>Login
                </Typography>
                <Typography style={{fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal'}}>Enter your partner credentials</Typography>
            </div>
              <Form layout="vertical">
                  <Form.Item label="Email" validateStatus={errors.email ? 'error' : ''} help={errors.email}>
                      <Input
                          type='email'
                          name='email'
                          onChange={handleChange}
                          placeholder="Enter your email"
                    />   
                  </Form.Item>
                  <Form.Item label="Password" validateStatus={errors.password ? 'error' : ''} help={errors.password}>
                      <Input.Password
                          name='password'
                          type='password'
                          onChange={handleChange}
                          placeholder="Enter your password"
                      />
                      
                  </Form.Item >
                  <div style={{display:"flex", justifyContent:"flex-end"}}>
                  <p style={{textAlign:"right", cursor:"pointer", margin:"0", padding:"0", maxWidth:"170px"}} onClick={handleForgotPassword} >Forgot Password?</p>
                  </div>
                  <Form.Item style={{display:'flex', justifyContent:'center' }} >
                  <Button loading={loading} disabled={loading} onClick={handleLogin} style={{backgroundColor:'#08839B',color:'#ffffff',  }}>
                  {/* {loading ? <LoadingOutlined /> : 'Login'} */}
                  {loading ? 'Loading': 'Login'}
                    {/* Login */}
                  </Button>
                  </Form.Item >
              </Form>
          </div>
    </Modal>): (
      <Modal
      centered
      open={show}
      footer={null}
      closable={false}
      maskClosable={false}
      mask={true}
      onCancel={onCancel}
      >
         <div className='form-modal'>
            <div style={{ textAlign: 'center',marginBottom: '1rem'}}>
              <img src={passwordImg} alt="a key" style={{width:"70px", height:"70px"}} />
                <Typography style={{color:'black',fontSize: '1.3rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal'
                    }}>Forgot Password?
                </Typography>
                <Typography style={{fontSize: '1rem',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    }}>To reset your password, please enter your email address below. You will receive a link to create a new password via email.</Typography>
            </div>
              <Form layout="vertical">
                   {/* label="Email Address" > */}
                      <p>Email Address</p>
                      <Input
                          type='email'
                          name='resetEmail'
                          onChange={handleResetEmailChange}
                          placeholder="Enter your email"/>
                   
            
                  <Form.Item style={{display:'flex', justifyContent:'center', marginTop:"1rem" }} >
                  <Button loading={loading} disabled={loading} onClick={handleResetPassword} style={{backgroundColor:'#08839B',color:'#ffffff', }}>
                  {/* {loading ? <LoadingOutlined /> : 'Login'} */}
                  {loading ? 'Loading': 'Reset Password'}
                    {/* Login */}
                  </Button>
                  
                  </Form.Item >
                  <div style={{display:"flex", justifyContent:"center"}}>
                  <p  style={{ textAlign:"center", cursor:"pointer", maxWidth:"150px"}} onClick={()=> setForgotPassword(true) }> 
                     <span style={{paddingRight:"0.7rem"}}>
                     <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path fillRule="evenodd" clipRule="evenodd" d="M5.87304 0.366779C6.31977 -0.0617165 7.02492 -0.061717 7.47165 0.366778C7.94549 0.821284 7.94549 1.57891 7.47165 2.03342L3.33617 6.0001L7.47165 9.96678C7.94549 10.4213 7.94549 11.1789 7.47165 11.6334C7.02492 12.0619 6.31977 12.0619 5.87304 11.6334L0 6.0001L5.87304 0.366779Z" fill="#232931"/>
                     </svg> 
                     </span> Back to Login </p>
                    </div>
              </Form>
          </div>
      
      </Modal>
    )
} 
</>
 );
};

export default PartnerLoginModal;