import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./SignSample.css";
import { toast } from "react-toastify";
// import SignSmallScreen from "./SignSmallsceen";
import YouthPlusLogo from "../asset/YouthPlus3D.svg";
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import SignSmallScreens from "./SignSmallScreen";
import PartnerOptionsModal from "./partnersOptions";

export default function SignSample(props) {
  // const navigate = useNavigate();
  const [showBenefits, setshowBenefits] = useState(false);
  const [isSignIn, setIsSignIn] = useState(true);
  const [firstLand, setFirstLand] = useState(true);
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { email, call } = useParams();
  // const [readOnly, setReadOnly] = useState(!!email);
  const [emailChange, setEmailChange] = useState("");
  // const [emailValid, setEmailValid] = useState(true);
  const [profile, setProfile] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [loginDetailsIsChecked, setLoginDetailsIsChecked] = useState(false);
  const [stanbicIsChecked, setStanbicIsChecked] = useState(true);
  const [hasQuicktellerConsent, setHasQuicktellerConsent] = useState(true);
  const [hasCowryConsent, setHasCowryConsent] = useState(true);
  const [hasMSLConsent, setHasMSLConsent] = useState(true);
  const [hasProductDiveConsent, setHasProductDiveConsent] = useState(true);
  const [username, setUserName] = useState("");
  const [callUpNumber, setCallUpNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    password: {
      length: "",
      lowercase: "",
      uppercase: "",
      specialCharacter: "",
    },
    confirmPassword: "",
  });
  // console.log(email)
  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
    // console.log(event.target.value)
  };

  const handleCallUpNumberChange = (event) => {
    setCallUpNumber(event.target.value);
  };

  // const validateEmail =(emailChange) => {
  //   const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  //   return regex.test(emailChange);
  // };

  const handleEmailChange = (event) => {
    setEmailChange(event.target.value);
  };

  // Email validation
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleFirstLand = () => {
    if (!email && !emailChange) {
      toast.error("Please add an email");
    } else if (!regex.test(emailChange || email)) {
      toast.error("please input a valid email");
    } else if (callUpNumber === "") {
      toast.error("Please insert a Call Up Number or PhoneNumber");
    } else {
      getUserProfile();
      setLoading(true);
    }
  };

  // ...

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    // Check if the new password meets the criteria
    const isValid = passwordRegex.test(newPassword);

    // Check individual conditions and update error messages and styles
    const errors = {
      password: {
        lowercase: /^(?=.*[a-z])/.test(newPassword),
        uppercase: /^(?=.*[A-Z])/.test(newPassword),
        number: /^(?=.*\d)/.test(newPassword),
        symbol: /^(?=.*[@$!%*?&])/.test(newPassword),
        minLength: newPassword.length >= 6,
      },
    };

    setErrors(errors);
    setPassword(newPassword);
    setIsPasswordValid(isValid);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;

    // Validate the confirm password
    const newErrors = validateConfirmPassword(newConfirmPassword);

    // Set the confirm password and errors state
    setConfirmPassword(newConfirmPassword);
    setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: newErrors }));
  };

  const validateConfirmPassword = (confirmPassword) => {
    return confirmPassword !== password ? "Passwords does not match." : "";
  };

  const handleShow = () => {
    if (loginDetailsIsChecked === true) {
      setShow(true);
    } else {
      return null;
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state
  };
  const handleShowBenefits = () => {
    setshowBenefits(!showBenefits); // Toggle the checkbox state
  };

  const handleLoginDetailsCheckboxChange = () => {
    setLoginDetailsIsChecked(!loginDetailsIsChecked); // Toggle the checkbox state
  };

  const handleStanbicCheckboxChange = () => {
    setStanbicIsChecked(!stanbicIsChecked); // Toggle the checkbox state
  };

  const handleHasQuicktellerConsentChange = () => {
    setHasQuicktellerConsent(!hasQuicktellerConsent); // Toggle the checkbox state
    // console.log(hasQuicktellerContent)
  };

  const handleHasCowryConsentChange = () => {
    setHasCowryConsent(!hasCowryConsent); // Toggle the checkbox state
    // console.log(hasQuicktellerContent)
  };

  const handleHasMSLConsentChange = () => {
    setHasMSLConsent(!hasMSLConsent); // Toggle the checkbox state
    // console.log(hasQuicktellerContent)
  };

  const handleHasProductDiveConsentChange = () => {
    setHasProductDiveConsent(!hasProductDiveConsent); // Toggle the checkbox state
    // console.log(hasQuicktellerContent)
  };

  useEffect(() => {
    setTimeout(() => {
      setIsSignIn(true);
    }, 200);
  }, []);

  // useEffect(() => {
  //   if (email) {
  //     setReadOnly(true);
  //   } else {
  //     setReadOnly(false);
  //   }
  // }, [email]);

  const getUserProfile = () => {
    // debugger
    try {
      const url = `${process.env.REACT_APP_GET_CORP_MEMBER_DETAILS}`;
      const username = emailChange !== "" ? emailChange : email;
      const callupno = callUpNumber;
      // const callupno = callup_no1 + "/" + callup_no2 + "/" + callup_no3 + "/" + callup_no4;

      axios
        .post(
          url,
          {
            username,
            callupno,
          },
          {
            headers: {
              // Authorization: `Bearer ${userDatafromlocalstorage.access}`
            },
          }
        )
        .then((res) => {
          const profile = res.data.data;
          // console.log('res', dashboardData);
          if (profile === null) {
            toast.warning("Details not Found, please input correct details");
            setLoading(false);
          } else {
            setProfile(profile);
            setFirstLand(!firstLand);
          }
        })
        .catch((error) => {
          toast.warning("No call up number available yet for this user");
          // console.log(error)
        });
    } catch (error) {
      // console.log("error", error);
    }
  };

  const createMSLaccount = () => {
    // debugger
    try {
      const url = `${process.env.REACT_APP_CREATE_MSL_ACCOUNT_URL}`;

      let data = {
        first_name: firstName,
        last_name: lastName,
        email: profile?.EmailAddress,
        phone_number: profile?.GSMNo,
        referral_source: 11,
      };

      axios
        .post(url, data, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "multipart/form-data", // Set the content type to FormData
            // Authorization: `Bearer *`
          },
        })
        .then((response) => {
          // if (response.status === 200 || response.status === 201 || response.status === "success") {
          //   toast.success(response?.message);
          // }
          // console.log(response)
        })
        .catch((error) => {
          // toast.error(
          //   error.response?.data.message.toString()
          // );
        });
    } catch (error) {
      // console.log("error", error);
    }
  };

  const createYouthPlusAccount = () => {
    // debugger
    try {
      const url = `${process.env.REACT_APP_CREATE_YOUTHPLUS_ACCOUNT}`;

      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName + " " + middleName);
      formData.append("username", username);
      formData.append("email", profile?.EmailAddress);
      formData.append("password", password);
      // formData.append('confirm_password', confirmPassword);
      formData.append(
        "gender",
        profile?.Gender === "M"
          ? "male"
          : profile?.Gender === "F"
            ? "female"
            : ""
      );
      formData.append("e_accepted", stanbicIsChecked === true ? "s" : "s");
      formData.append("callup_no", profile?.Callupno);
      formData.append("phone_number", profile?.GSMNo);
      formData.append("has_stanbic_consent", stanbicIsChecked === true ? "Yes" : "No");
      formData.append("has_msl_consent", hasMSLConsent === true ? "Yes" : "No");
      formData.append("has_cowry_consent", hasCowryConsent === true ? "Yes" : "No");
      formData.append("has_productdive_consent", hasProductDiveConsent === true ? "Yes" : "No");
      formData.append("has_quicktellertransport_consent", hasQuicktellerConsent === true ? "Yes" : "No");
      formData.append("batch", profile?.Batch);
      formData.append("full_bacth_info", profile?.FullBatchInfo);
      formData.append("country_of_study", profile?.CountryOfStudy);
      formData.append("deployed_state", profile?.deployedState);
      formData.append("marital", profile?.Marital);
      formData.append("dob", profile?.dob);
      formData.append("course", profile?.Course);
      formData.append("institution", profile?.Institution);
      formData.append("dog", profile?.dog);

      axios
        .post(url, formData, {
          headers: {
            // "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data", // Set the content type to FormData
            // Authorization: `Bearer *`
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            toast.success("Congratulations!!! Account Created Successfully.");
            setTimeout(() => {
              // window.location = "https://www.youthplus.ng/welcome";
              window.open('https://www.youthplus.ng/welcome', '_blank');
              if (stanbicIsChecked === true) {
                // Open a new tab without redirecting
                window.location = "https://nysc.youthplus.ng/complete-stanbicibtc-registration";

                // Optionally, modify the content of the new tab
                // newTab.document.write('<h1>Welcome to Stanbic IBTC!</h1>');
              } else {
                // If the condition is not met, return null or perform other actions
                return null;
              }
              if (hasMSLConsent === true) {
                // debugger
                createMSLaccount();
              } else {
                // If the condition is not met, return null or perform other actions
                return null;
              }
            }, 500);
          }
          // console.log(response)
        })
        .catch((error) => {
          if (error.response.data.email) {
            toast.error(
              error.response.data.email[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else if (error.response.data.username) {
            toast.error(
              error.response.data.username[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else if (error.response.data.first_name) {
            toast.error(
              error.response.data.first_name[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else if (error.response.data.last_name) {
            toast.error(
              error.response.data.last_name[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else if (error.response.data.gender) {
            toast.error(
              error.response.data.gender[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else if (error.response.data.password) {
            toast.error(
              error.response.data.password[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else if (error.response.data.e_accepted) {
            toast.error(
              error.response.data.e_accepted[0]
                .toString()
                .replace(/\bwo\b/, "YouthPlus")
            );
          } else {
            toast.error(error.response.data);
            // console.log(error)
          }
        });
    } catch (error) {
      // console.log("error", error);
    }
  };

  // console.log('logincred', loginDetailsIsChecked);

  const toggle = () => {
    // debugger
    if (isChecked === true) {
      setIsSignIn((prevValue) => !prevValue);

      function splitName(name) {
        const nameParts = name.split(" ");

        // Assuming that the last part is the middleName
        const middleName = nameParts.pop();

        // Assuming that the first part is the lastName
        const lastName = nameParts.shift();

        // Whatever remains is considered the firstName
        const firstName = nameParts.join(" ");

        return {
          firstName,
          middleName,
          lastName,
        };
      }

      // const fullName = "Yinusa Mustapha Olayemi";
      // eslint-disable-next-line no-unused-vars
      const { firstName, middleName, lastName } = splitName(profile?.CMName);

      // console.log("First Name:", firstName);
      // console.log("Middle Name:", middleName);
      // console.log("Last Name:", lastName);
      setFirstName(firstName);
      setLastName(lastName);
      setMiddleName(middleName)
    } else {
      toast.error("Please check the terms and policy box");
      return null;
    }
  };

  const handleClose = () => setShow(false);
  const useMatchMedia = (mediaQuery, initialValue) => {
    const [isMatching, setIsMatching] = useState(initialValue);
    useEffect(() => {
      const watcher = window.matchMedia(mediaQuery);
      setIsMatching(watcher.matches);
      const listener = (matches) => {
        setIsMatching(matches.matches);
      };
      if (watcher.addEventListener) {
        watcher.addEventListener("change", listener);
      } else {
        watcher.addListener(listener);
      }
      return () => {
        if (watcher.removeEventListener) {
          return watcher.removeEventListener("change", listener);
        } else {
          return watcher.removeListener(listener);
        }
      };
    }, [mediaQuery]);

    return isMatching;
  };

  const TabScreen = useMatchMedia("(max-width:750px)", true);
  // const smallScreen = useMatchMedia('(max-width:599px)', true);

  return (
    <>
      <div className="container-body">
        {TabScreen ? (
          <SignSmallScreens
            show={show}
            handleClose={handleClose}
            email={email}
            emailChange={emailChange}
            handleEmailChange={handleEmailChange}
            firstLand={firstLand}
            handleFirstLand={handleFirstLand}
            handleCallUpNumberChange={handleCallUpNumberChange}
            handleShowBenefits={handleShowBenefits}
            YouthPlusLogo={YouthPlusLogo}
            showBenefits={showBenefits}
            callUpNumber={callUpNumber}
            profile={profile}
            isChecked={isChecked}
            handleCheckboxChange={handleCheckboxChange}
            toggle={toggle}
            isSignIn={isSignIn}
            handleUserNameChange={handleUserNameChange}
            username={username}
            handlePasswordChange={handlePasswordChange}
            password={password}
            errors={errors}
            handleConfirmPasswordChange={handleConfirmPasswordChange}
            confirmPassword={confirmPassword}
            loginDetailsIsChecked={loginDetailsIsChecked}
            handleLoginDetailsCheckboxChange={handleLoginDetailsCheckboxChange}
            handleShow={handleShow}
            stanbicIsChecked={stanbicIsChecked}
            createYouthPlusAccount={createYouthPlusAccount}
            handleStanbicCheckboxChange={handleStanbicCheckboxChange}
            hasQuicktellerConsent={hasQuicktellerConsent}
            handleHasQuicktellerConsentChange={handleHasQuicktellerConsentChange}
            hasCowryConsent={hasCowryConsent}
            handleHasCowryConsentChange={handleHasCowryConsentChange}
            hasMSLConsent={hasMSLConsent}
            handleHasMSLConsentChange={handleHasMSLConsentChange}
            hasProductDiveConsent={hasProductDiveConsent}
            handleHasProductDiveConsentChange={handleHasProductDiveConsentChange}
          />
        ) : (
          <div
            id="container"
            className={`container ${isSignIn ? "sign-up" : "sign-in"}`}
          >
            {/* FORM SECTION */}
            <div className="signup-row">
              {/* SIGN UP */}
              <div className="col align-items-center flex-col sign-up">
                <div className="form-wrapper align-items-center">
                  {firstLand === true ? (
                    <div className="form sign-up">
                      <div className="input-group">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <input
                          type="email"
                          placeholder="Email"
                          value={email === undefined ? emailChange : email}
                          readOnly={email === undefined ? false : true}
                          onChange={
                            email === undefined ? handleEmailChange : ""
                          }
                        />
                      </div>

                      <div className="input-group">
                        <i class="fa fa-id-badge" aria-hidden="true"></i>
                        <input
                          type="text"
                          placeholder="Call Up Number or Phone Number"
                          onChange={handleCallUpNumberChange}
                          value={callUpNumber}
                        />
                      </div>

                      <div>
                        <div class="checkbox-container">
                          <label htmlFor="checkbox">
                            <input type="checkbox" id="termsCheckbox" />
                            <span className="spaced-element">
                              By signing in to Youthplus, you agree to our
                              <Link
                                to="/terms"
                                target="_blank"
                                className="links-style"
                              >
                                <b> Terms </b>
                              </Link>
                              and
                              <Link
                                to="/policy"
                                target="_blank"
                                className="links-style"
                              >
                                <b> Privacy Policy </b>
                              </Link>
                              .
                            </span>
                          </label>
                        </div>
                      </div>
                      <button
                        className="animated-button"
                        onClick={handleFirstLand}
                        disabled={loading}
                        style={{ cursor: loading ? "not-allowed" : "pointer" }}
                      >
                        {loading ? "Loading..." : "Confirm"}
                      </button>
                    </div>
                  ) : (
                    <div className="form sign-up">
                      <div className="input-group">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                        <input
                          type="text"
                          placeholder="Full Name"
                          value={profile?.CMName}
                          readOnly
                        />
                      </div>
                      <div className="input-group">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <input
                          type="email"
                          placeholder="Email"
                          value={profile?.EmailAddress}
                          readOnly
                        />
                      </div>
                      <div className="input-group">
                        <i class="fa fa-mobile" aria-hidden="true"></i>
                        <input
                          type="tel"
                          placeholder="Phone Number"
                          value={profile?.GSMNo}
                          readOnly
                        />
                      </div>
                      <div className="input-group">
                        <i class="fa fa-id-badge" aria-hidden="true"></i>
                        <input
                          type="text"
                          placeholder="Call Up Number"
                          value={profile?.Callupno}
                          readOnly
                        />
                      </div>
                      <div className="input-group">
                        <i class="fa fa-user-circle" aria-hidden="true"></i>
                        <input
                          type="gender"
                          placeholder="Gender"
                          value={
                            profile?.Gender === "M"
                              ? "Male"
                              : profile?.Gender === "F"
                                ? "Female"
                                : ""
                          }
                          readOnly
                        />
                      </div>
                      <div>
                        <div class="checkbox-container">
                          <label htmlFor="checkbox">
                            <input
                              type="checkbox"
                              id="termsCheckbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <span className="spaced-element">
                              By signing in to Youthplus, you agree to our
                              <Link
                                to="/terms"
                                target="_blank"
                                className="links-style"
                              >
                                <b> Terms </b>
                              </Link>
                              and
                              <Link
                                to="/policy"
                                target="_blank"
                                className="links-style"
                              >
                                <b> Privacy Policy </b>
                              </Link>
                              .
                            </span>
                          </label>
                        </div>
                      </div>

                      <button onClick={toggle}>Confirm Details</button>
                    </div>
                  )}
                </div>
              </div>
              {/* END SIGN UP */}
              {/* SIGN IN */}
              <div className="col align-items-center flex-col sign-in">
                <div className="form-wrapper align-items-center">
                  <div className="form sign-in">
                    <div className="input-group">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <input
                        type="text"
                        placeholder="Username"
                        onChange={handleUserNameChange}
                        value={username}
                      />
                    </div>
                    <div className="input-group">
                      <i className="fa fa-lock" aria-hidden="true"></i>
                      <input
                        type="password"
                        placeholder="Password"
                        onChange={handlePasswordChange}
                        value={password}
                      />
                    </div>
                    <div className="error-messages">
                      {Object.entries(errors.password).map(
                        ([key, value], index) => (
                          <p
                            key={index}
                            style={{
                              color: value ? "green" : "red",
                              fontSize: "x-small",
                              textAlign: "left",
                              marginBottom: "0.3em",
                            }}
                          >
                            {value
                              ? `Password meets ${key} condition.`
                              : key === "minLength"
                                ? " Password must be a minimum length of 6"
                                : "Password must contain at least one " + key}
                          </p>
                        )
                      )}
                    </div>
                    <div className="input-group">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                      <input
                        type="password"
                        placeholder="Confirm password"
                        onChange={handleConfirmPasswordChange}
                        value={confirmPassword}
                      />
                    </div>
                    <div className="error-messages">
                      <p
                        style={{
                          color: errors.confirmPassword ? "red" : "green",
                          fontSize: "x-small",
                          textAlign: "left",
                          marginBottom: "0.3em",
                        }}
                      >
                        {errors.confirmPassword}
                      </p>
                    </div>
                    <div>
                      <div class="checkbox-container">
                        <label htmlFor="checkbox">
                          <input
                            type="checkbox"
                            id="termsCheckbox"
                            checked={loginDetailsIsChecked}
                            onChange={handleLoginDetailsCheckboxChange}
                          />
                          <span className="spaced-element">
                            By signing in to Youthplus, you agree to our
                            <Link
                              to="/terms"
                              target="_blank"
                              className="links-style"
                            >
                              <b> Terms </b>
                            </Link>
                            and
                            <Link
                              to="/policy"
                              target="_blank"
                              className="links-style"
                            >
                              <b> Privacy Policy </b>
                            </Link>
                            .
                          </span>
                        </label>
                      </div>
                    </div>
                    <button
                      onClick={handleShow}
                      disabled={
                        username === "" ||
                        password === "" ||
                        confirmPassword === "" ||
                        confirmPassword !== password ||
                        !isPasswordValid ||
                        !loginDetailsIsChecked
                      }
                      style={{
                        cursor:
                          username === "" ||
                            password === "" ||
                            confirmPassword === "" ||
                            confirmPassword !== password ||
                            !isPasswordValid ||
                            !loginDetailsIsChecked
                            ? "not-allowed"
                            : "pointer",
                      }}
                    >
                      Confirm
                    </button>
                    <PartnerOptionsModal
                      show={show}
                      handleClose={handleClose}
                      stanbicIsChecked={stanbicIsChecked}
                      handleStanbicCheckboxChange={handleStanbicCheckboxChange}
                      hasQuicktellerConsent={hasQuicktellerConsent}
                      handleHasQuicktellerConsentChange={handleHasQuicktellerConsentChange}
                      hasCowryConsent={hasCowryConsent}
                      handleHasCowryConsentChange={handleHasCowryConsentChange}
                      hasMSLConsent={hasMSLConsent}
                      handleHasMSLConsentChange={handleHasMSLConsentChange}
                      hasProductDiveConsent={hasProductDiveConsent}
                      handleHasProductDiveConsentChange={handleHasProductDiveConsentChange}
                      createYouthPlusAccount={createYouthPlusAccount}
                    />
                  </div>
                </div>
                <div className="form-wrapper"></div>
              </div>
              {/* END SIGN IN */}
            </div>
            {/* END FORM SECTION */}
            {/* CONTENT SECTION */}
            <div className="signup-row content-row">
              {/* SIGN IN CONTENT */}
              <div className="col align-items-center flex-col">
                <div className="img sign-in">
                  <img src={YouthPlusLogo} alt="YouthPlus Logo" />
                </div>
                <div className="text sign-in">
                  <h3>Connect with Fellow NYSC Corp Members</h3>
                  <h4>What We Offer Corp Members.</h4>
                </div>
                <div className="offers sign-in">
                  <ul>
                    <li>
                      <b>Community Development Project Ideas:</b> A platform to
                      brainstorm and collaborate on community service projects.
                    </li>
                    <li>
                      <b>Marketplace:</b> A marketplace for corp members to buy
                      and sell items they need for camp or their service year.
                    </li>
                    <li>
                      <b>Job and Career Opportunities:</b> A job board featuring
                      employment opportunities and internships suitable for NYSC
                      graduates.
                    </li>
                    <li>
                      <b>Feedback System:</b> Allowing corp members to provide
                      feedback on their experiences and suggest improvements to
                      the NYSC program.
                    </li>
                  </ul>
                </div>
              </div>
              {/* END SIGN IN CONTENT */}
              {/* SIGN UP CONTENT */}
              <div className="col align-items-center flex-col">
                <div className="img sign-up">
                  <img src={YouthPlusLogo} alt="YouthPlus Logo" />
                </div>
                <div className="text sign-up">
                  <h3>Connect with Fellow NYSC Corp Members</h3>
                  <h4>What We Offer Corp Members.</h4>
                </div>
                <div className="offers sign-up">
                  <ul>
                    <li>
                      <b>Information Hub:</b> A one-stop platform for all
                      NYSC-related information, including orientation camp
                      details, deployment information, and program guidelines.
                    </li>
                    <li>
                      <b>Orientation Camp Updates:</b> Real-time updates on camp
                      activities, schedules, and important announcements.
                    </li>
                    <li>
                      <b>Community Building:</b> Facilitating connections among
                      NYSC participants, allowing them to interact and network.
                    </li>
                    <li>
                      <b>Deployment Feedback:</b> Providing a space for corp
                      members to share and discuss their deployment experiences
                      and locations.
                    </li>
                  </ul>
                </div>
              </div>
              {/* END SIGN UP CONTENT */}
            </div>
            {/* END CONTENT SECTION */}
          </div>
        )}
      </div>
      <Footer/>
    </>
  );
}
