import React, { useState, useEffect, useRef  } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import axios from "axios";
import { Layout, notification } from "antd";
import DashboardContent from "./dashboardContent";
import PartnerLoginModal from "../../Components/partnerDashboardLogin";
import { toast } from "react-toastify";
import "./dashboard.css";
import SiderComponent from "../../Components/adminsider";
import HeaderComponent from "../../Components/adminheader";
import ContentComponent from "../../Components/admincontent";
import { mslColumns, nyscColumns, stanbicColumns, transportColumns} from "./Columns";

const AdminDashboard = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // const [visible, setVisible] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("0");
  const [msldata, setMslData] = useState([]);
  const [mslactive, setMslActive] = useState(0);
  const [mslinactive, setMslInactive] = useState(0);
  const [nyscdata, setNyscData] = useState([]);
  const [stanbicdata, setStanbicData] = useState([]);
  // const [quicktellerdata, setQuicktellerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [stanbicpaginationInfo, setStanbicPaginationInfo] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  // const [quicktellerPaginationInfo, setQuicktellerPaginationInfo] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 0,
  // });
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [nyscpaginationInfo, setNyscPaginationInfo] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const [loggedIn, setLoggedIn] = useState(false); // State to track login status
  const logoutTimerRef = useRef(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [nyscactiveUsers, setNyscActiveUsers] = useState(0);
  const [nyscinactiveUsers, setNyscInActiveUsers] = useState(0);
  const [nyscTotalUsers, setNyscTotalUsers] = useState(0);
  const [stanbicTotalUsers, setStanbicTotalUsers] = useState(0);
  // const [QuicktellerTotalUsers, setQuicktellerTotalUsers] = useState(0);
  // const [searchText, setSearchText] = useState('');


  // eslint-disable-next-line no-unused-vars
  const [dateRange, setDateRange] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedUserMenu, setSelectedUserMenu] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");
  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  // HANDLING PARTNER MENU CLICK
  const handleMenuClick = (e) => {
    setSelectedMenuItem(e.key);
  };

  // HANDLING FILTER CHANGE
  const handleBatchChange = (value) => {
    setSelectedBatch(value);
  };
 
  const handleLogout = () => {
    // Clear local storage
    localStorage.removeItem("userData");
    setLoggedIn(false);
    setFormData({
      email:"",
      password:"",
    });
    window.location.reload();
    // setLoggedIn(false);
  };

  // HANDLING TIME-OUT
  useEffect(() => {
    const resetTimer = () => {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      logoutTimerRef.current = setTimeout(handleLogout, 2 * 60 * 60 * 1000); // timeout if inactive after 2 hours
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    resetTimer();

    // Add event listeners for user activity
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    // Clean up event listeners and timers on component unmount
    return () => {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  // HANDLING USER PROFILE OPTIONS
  const handleUserMenuClick = (e) => {
    setSelectedUserMenu(e.key);
    if (e.key === "reset-password") {
      handleResetPassword();
    } else if (e.key === "logout") {
      handleLogout();
    }
  };
//RESETTING PASSWORD

const handleResetPassword = async () => {
  setLoading(true);
  try{
  // eslint-disable-next-line no-unused-vars
  const response = await axios.post('https://nysc.youthplus.ng/onboard/forgot-password/',
     { email: userDatafromlocalstorage?.email });

  notification.success({
    message: "Reset email sent",
    description: "Check your registered email for a link to reset your password.",
  });
  }catch (error) {
    console.error("Error sending email:", error);
  notification.error({
    message: "Error sending reset email",
    description:
      error.response?.data?.message ||
      "There was an error sending a reset email. Please try again.",
  });
} finally {
  setLoading(false);
}
  };

  
// GETTING DATA FROM API
  const fetchData = async (page = 1) => {
    setLoading(true);
    if (!userDatafromlocalstorage) {
      setShowLoginModal(true);
    } else {
      try {
        switch (selectedMenuItem) {
          case "1":
            const mslResponse = await axios.get(
              `${process.env.REACT_APP_MSL_DASHBOARD}?page=${page}`
            );
            const mslResponse2 = await axios.get(
              `${process.env.REACT_APP_MSL_REPORT}`
            );
            const mslreport = mslResponse2.data;

            setMslData(mslResponse.data.data);
            setMslActive(mslreport.active_merchant);
            setMslInactive(mslreport.inactive_merchant);
            setPaginationInfo({
              current: mslResponse.data.meta.current_page,
              pageSize: mslResponse.data.meta.per_page,
              total: mslResponse.data.meta.total,
            });
            setTotalUsers(mslResponse.data.meta.total);
            // Set other state variables for MSL data
            break;
          case "2":
            const nyscResponse = await axios.get(
              `${process.env.REACT_APP_NYSC_DASHBOARD}?page=${page}&full_bacth_info=${selectedBatch}`,
              {
                params: { userType: userDatafromlocalstorage?.user_type },
              }
            );

            const nyscDashboardData = nyscResponse.data;
            setNyscData(nyscDashboardData.results.users);
            setNyscPaginationInfo({
              current: page,
              pageSize: 10,
              total: nyscDashboardData.count,
            });

            setNyscTotalUsers(nyscDashboardData.results.count);
            setNyscActiveUsers(nyscDashboardData.results.active_corpers);
            setNyscInActiveUsers(nyscDashboardData.results.inactive_corpers);
            break;
            case "3":
            const stanbicResponse = await axios.get(
              `${process.env.REACT_APP_STANBIC_DASHBOARD}?page=${page}`,
            );

            const stanbicDashboardData = stanbicResponse.data;
            // Process the data
            const processedData = stanbicDashboardData.users.map(user => {
            const fullName = user.name;
            const nameParts = fullName.split(' ');

            // Assuming the first word is the first name and the next word is the last name (BVN convention).
            const firstName = nameParts[0];
            const lastName = nameParts[1];

            return {
              key: user.email,  // Using email as a unique key for the table
              first_name: firstName,
              last_name: lastName,
              email: user.email,
              date_created: user.date_created,
            };
          });

            setStanbicData(processedData);
            setStanbicTotalUsers(stanbicDashboardData.count)
            setStanbicPaginationInfo({
              current: page,
              pageSize: 10,
              total: stanbicDashboardData.count,
            });
            break;
            // case "4":
            // const quicktellerResponse = await axios.get(
            //   `${process.env.REACT_APP_STANBIC_DASHBOARD}?page=${page}`,
            // );

            // const quicktellerDashboardData = quicktellerResponse.data;


            // setQuicktellerData(quicktellerDashboardData);
            // setQuicktellerTotalUsers(quicktellerDashboardData.count)
            // setQuicktellerPaginationInfo({
            //   current: page,
            //   pageSize: 10,
            //   total: quicktellerDashboardData.count,
            // });
            // break;
          case "0":
            if (userDatafromlocalstorage?.user_type === "MSL") {
              setSelectedMenuItem("1");
            } else if (userDatafromlocalstorage?.user_type === "SUPERADMIN") {
              setSelectedMenuItem("1");
            } else if (userDatafromlocalstorage?.user_type === "NYSC") {
              setSelectedMenuItem("2");
            } else {
              setSelectedMenuItem("3");
            }
            break;
          default:
            return null;
        }
      } catch (error) {
        toast.error(`An error occurred: ${error.message}`);
      } finally {
        setLoading(false);
      }
    }
  };
  // SETTING DATA TO LOCAL STORAGE AND AUTO SELECTION OF MENU BASED ON USERTYPE ON LOGIN SUCCESS
  const handleLoginSuccess = (userData) => {
    setLoggedIn(true);
    localStorage.setItem("userData", JSON.stringify(userData));
    if (userData.user_type === "NYSC") {
      setSelectedMenuItem("2");
    } else if (userData.user_type === "MSL") {
      setSelectedMenuItem("1");
    }else if (userData.user_type === "STANBIC") {
        setSelectedMenuItem("3");
    }else if (userData.user_type === "QUICKTELLER") {
          setSelectedMenuItem("4");
    } else {
      setSelectedMenuItem("1");
    }
    fetchData();
  };

  // HANDLING USERDATA RETRIEVAL FROM LOCAL STORAGE
  const getUserDataFromLocalStorage = () => {
    const userDataString = localStorage.getItem("userData");
    return JSON.parse(userDataString);
  };
  const userDatafromlocalstorage = getUserDataFromLocalStorage();

// USING USERDATA FROM STORAGE TO HANDLE MODAL POP UP

  useEffect(() => {
    if (!userDatafromlocalstorage) {
      setShowLoginModal(true);
    } else {
      setLoggedIn(true);
    }
  }, [userDatafromlocalstorage]);

// CALLING FETCHDATA FUNCTION ON SELECT OF PARTNER FOR SUPER ADMIN USECASE

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItem]);


// HANDLING PAGINATION
  const handlePaginationChange = (page) => {
    fetchData(page);
  };
//HANDLING PAGINATION SIZE
const handlePageSizeChange = (current, size) => {
  setPaginationInfo(prev => ({
    ...prev,
    pageSize: size,
    current: current,
  }));
  fetchData({ page: current, pageSize: size });
}
// const handleSearch = (e) => {
//   setSearchText(e.target.value);
//   const FetchSearchData = async (searchText)=>{
//     const SearchResponse = await axios.get(
//       `${process.env.REACT_APP_STANBIC_DASHBOARD}?page=${page}`,
//     );

//     const stanbicDashboardData = stanbicResponse.data;

//   }
// };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case "1":
        return (
          <DashboardContent
            title="MSL Dashboard"
            columns={mslColumns}
            data={msldata}
            totalUsers={totalUsers}
            activeUsers={mslactive}
            inactiveUsers={mslinactive}
            paginationInfo={paginationInfo}
            handlePageSizeChange={handlePageSizeChange}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
          />
        );
      case "2":
        return (
          <DashboardContent
            title="NYSC Dashboard"
            columns={nyscColumns}
            data={nyscdata}
            totalUsers={nyscTotalUsers}
            activeUsers={nyscactiveUsers}
            inactiveUsers={nyscinactiveUsers}
            paginationInfo={nyscpaginationInfo}
            handlePaginationChange={handlePaginationChange}
            loading={loading}
            fetchData={fetchData}
            selectedBatch={selectedBatch}
            setSelectedBatch={setSelectedBatch}
            handleBatchChange={handleBatchChange}
            // handleSearch={handleSearch}
          />
        );
        case "3":
          return (
            <DashboardContent
              title="Stanbic Dashboard"
              columns={stanbicColumns}
              data={stanbicdata}
              totalUsers={stanbicTotalUsers}
              paginationInfo={stanbicpaginationInfo}
              handlePaginationChange={handlePaginationChange}
              loading={loading}
            />
          );
          case "4":
          return (
            <DashboardContent
              title="Qickteller Dashboard"
              columns={transportColumns}
              // data={stanbicdata}
              // totalUsers={stanbicTotalUsers}
              // paginationInfo={stanbicpaginationInfo}
              handlePaginationChange={handlePaginationChange}
              loading={loading}
            />
          );
      default:
        return <DashboardContent title="YouthPlus Partners Dashboard" />;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", opacity: showLoginModal ? 0.02 : 1 }}>
      <SiderComponent
      userDatafromlocalstorage={userDatafromlocalstorage}
      handleLogout={handleLogout}
      handleMenuClick={handleMenuClick}
      handleUserMenuClick={handleUserMenuClick}
      collapsed={collapsed}
      selectedMenuItem={selectedMenuItem}
      selectedUserMenu={selectedUserMenu}/>
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? "80px" : "200px" }}
      >
        <HeaderComponent
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        userDatafromlocalstorage={userDatafromlocalstorage}
        />
        <ContentComponent
        renderContent={renderContent}
        />
       
        <PartnerLoginModal
              show={showLoginModal}
              onCancel={() => setShowLoginModal(false)}
              onLoginSuccess={handleLoginSuccess}
              formData={formData}
              setFormData={setFormData}
            />
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
