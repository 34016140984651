import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seat from '../asset/seat.svg'
import Payment from '../asset/payment.svg'
import Connect from '../asset/people.svg'
import Trip from '../asset/bus.svg'
import { Typography } from '@mui/material'

const features = [
    {
      id: 1,
      title: 'Reserve a seat',
      content: 'Pick a seat that suites your convenience and meets satisfaction',
      image: Seat,
    },
    {
      id: 2,
      title: 'Convenient Payment Option',
      content: 'Pay for your trips without much hassle ',
      image: Payment,
    },
    {
      id: 3,
      title: 'Connect with people',
      content: 'Connect with other corpers going to the same camp with you',
      image: Connect,
    },
    {
      id: 4,
      title: 'Stress free journey',
      content: 'Get the option to be dropped directly at your orientation camp',
      image: Trip,
    }
  ];

function TransportFeatures() {
  return (
    <div className='transport-features'>
      {/* <Typography>What We Offer</Typography> */}
      <Row>
        {features && features.map(item => {
            return(
            <Col md={6} lg={3}>
                <div className='tfeatures-wrapper' key={item.id} >
                    <img src={item.image} alt='' className='tfeatures-img' />
                    <Typography className='tfeatures-title'>{item.title}</Typography>
                    <Typography className='tfeatures-content'>{item.content}</Typography>
                </div>
            </Col>
            )
        })}
      </Row>
    </div>
  )
}

export default TransportFeatures