import React, { useRef } from 'react';
import { Card, Col, Divider, Button } from "antd";

const TicketCard = ({ ticketDetails }) => {
  const sectionRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  return (
    <Col md={{ span: 12 }} xs={{ span: 24 }}>
      <Card ref={sectionRef} id="section-to-print" className="ticket-card">
        {/* Ticket Details */}
        <div className="ticket-details">
          <div className="detail-pair">
            <div className="left-details">
              <h3 style={{ textAlign: "center" }}>Trip Summary</h3>
              <div className="info-pair">
                <p>Ticket Number:</p>
                <p>{ticketDetails?.message?.ticketNumber}</p>
              </div>
              <div className="info-pair">
                <p>From:</p>
                <p>{ticketDetails?.message?.origin}</p>
              </div>
              <div className="info-pair">
                <p>To:</p>
                <p>{ticketDetails?.message?.destination}</p>
              </div>
              <div className="info-pair">
                <p>Date:</p>
                <p>{ticketDetails?.message?.departureDate}</p>
              </div>
              <div className="info-pair">
                <p>Time:</p>
                <p>{ticketDetails?.message?.departureTime}</p>
              </div>
              <div className="info-pair">
                <p>Adult(s):</p>
                <p>{ticketDetails?.message?.noOfAdults}</p>
              </div>
              <div className="info-pair">
                <p>Children:</p>
                <p>{ticketDetails?.message?.noOfChildren}</p>
              </div>
              {ticketDetails?.message?.passengers?.map((passenger, index) => (
                <div key={index}>
                  <p>Passenger {index + 1}</p>
                  <div className="info-pair">
                    <p>Seat Number:</p>
                    <p>{passenger.seatNumber}</p>
                  </div>
                  <div className="info-pair">
                    <p>Price:</p>
                    <p>{passenger.amount}</p>
                  </div>
                  <div className="info-pair">
                    <p>Total Amount:</p>
                    <h5>{passenger.discountedAmount}</h5>
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Cut effect */}
        <div className="cut-effect"></div>

        {/* Bus Details */}
        <div className="bus-details">
          <Divider />
          <div className="bus-details-content">
            <div className="detail-pair">
              <div className="left-details">
                <h3 style={{ textAlign: "center" }}>Bus Details</h3>
                <div className="info-pair">
                  <p>Number of Seats Paid for:</p>
                  <p>{ticketDetails?.message?.noOfSeats}</p>
                </div>
                <div className="info-ppair">
                  <p>Number of Adults:</p>
                  <p>{ticketDetails?.message?.noOfAdults}</p>
                </div>
                <div className="info-pair">
                  <p>Number of Children:</p>
                  <p>{ticketDetails?.message?.noOfChildren}</p>
                </div>
                <div className="info-pair">
                  <p>Vehicle Type:</p>
                  <p>{ticketDetails?.message?.vehicleType}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
        <Button
          type="primary"
          style={{
            backgroundColor: "#048181",
            fontSize: "16px",
            padding: "0.5rem 0 2rem 0",
            width: "80%",
          }}
          onClick={handlePrint}
        >
          Save
        </Button>
      </div>
    </Col>
  );
};

export default TicketCard;
