import React from 'react'
import { Layout } from "antd";

const {Content } = Layout;

const ContentComponent = ({renderContent}) => {
  return (
    <div>
       <Content style={{ margin: "16px", overflowY: "auto" }}>
          <div className="site-layout-content">{renderContent()}</div>
        </Content>
    </div>
  )
}

export default ContentComponent
