import React,{useState} from "react";
import {Table, Card, Row, Col, Input, Button,
    // Form,
    // Select,
  } from "antd";
  import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import { SearchOutlined, DownloadOutlined, FilterOutlined} from '@ant-design/icons';
import FiltersModal from "../../Components/modals/adminfilters";

function DashboardContent({
    title,data,totalUsers,activeUsers,inactiveUsers,columns,loading,selectedBatch,handleBatchChange,fetchData,page,handlePageSizeChange, handlePaginationChange,paginationInfo}) {
      const components = {
        header: {
          cell: (props) => (
            <th {...props} style={{ backgroundColor: '#08839B', color: '#ffffff',  width: '80px' }}>
              {props.children}
            </th>
          ),
        },
        body: {
          cell: (props) => (
            <td {...props} style={{ whiteSpace: 'normal', wordWrap: 'break-word', width: '80px'}}>
              {props.children}
            </td>
          ),
        },
      };
      const [show, setShow] = useState(false);
      
      // download as an excel file
      const handleDownload = () => {
        // console.log('button clicked')
        const workSheet = utils.json_to_sheet(data);
        const workBook = utils.book_new();
        utils.book_append_sheet(workBook, workSheet, `${title} Table page${paginationInfo.current}`);
    
        const excelBuffer = write(workBook, { bookType: 'xlsx', type: 'array' });
        const converteddata = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(converteddata, `${title} Table Data page${paginationInfo.current}.xlsx`);
      };
  return (
    <div>
      <h2>{title}</h2>
      <p>Welcome to the admin dashboard</p>

      <Row gutter={[66, 16]}>
      {totalUsers && (
        <Col xs={24} sm={8} >
          <Card>
            <h6>Total Users:</h6>
            <h3>{totalUsers?.toLocaleString()}</h3>
          </Card>
        </Col>
      )}
        {activeUsers &&(
        <Col xs={24} sm={8} >
          <Card>
            <h6>Active Users:</h6>
            <h3>{activeUsers?.toLocaleString()}</h3>
          </Card>
        </Col>
      )}
      {inactiveUsers &&(
        <Col xs={24} sm={8} >
          <Card>
            <h6>Inactive Users:</h6>
            <h3>{inactiveUsers?.toLocaleString()}</h3>
          </Card>
        </Col>
      )}
      </Row>
      <br />
      {/* <Form layout="vertical" style={{display: title !== "NYSC Dashboard" ? 'none' : 'block'}}> */}
      <Row  gutter={[16, 16]} style={{display:'flex',justifyContent:'space-between',backgroundColor:"#ffffff", marginBottom:'1.5rem', padding:'0.5rem',borderRadius:'0.5rem'}}>
        <Col xs={24} lg={10}>
          <Input
            placeholder="Search"
            // value={searchText}
            // onChange={handleSearch}
            prefix={<SearchOutlined/>}
            style={{backgroundColor:'#F1F1F2'}}
          />
        </Col>
        <Col xs={24} lg={7} style={{ display: 'flex', flexDirection: 'column' }}>
        <Row gutter={[16, 16]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Col xs={24} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" style={{ marginBottom: '8px', width: '100%' }}
            onClick={handleDownload}>
              <DownloadOutlined />
              Download Table
            </Button>
          </Col>
          {title === "NYSC Dashboard" &&
          <Col xs={24} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" style={{ width: '100%' }}
            onClick={()=>setShow(true)}>
              <FilterOutlined />
              Filters
            </Button>
            <FiltersModal
            show={show}
            onCancel={()=>setShow(!show)}/>
          </Col>
          }
        </Row>
      </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          ...paginationInfo,
          onChange: handlePaginationChange,
          responsive: true,
          onShowSizeChange: handlePageSizeChange,
          pageSizeOptions: ['10', '20', '50', '100','500'],
          size: "small",
        }}
        components={components}
        tableLayout="fixed"
        scroll={{
          x: "cal(500px + 50%)",
        }}

      />
    </div>
  );
}

export default DashboardContent
