import React, { useState } from 'react';
import { Modal, Form, Input, Button, Typography } from 'antd';

const LoginModal = ({ visible, onCancel }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Perform login authentication here
    // For demonstration, just pass username and password to the onLogin function
    // onLogin({ username, password });
  };

  return (
    <Modal
      centered
      open={visible}
      footer={null}
      closable={false}
      maskClosable={true}
      onCancel={onCancel}
    >
          <div className='form-modal'>
            <div className='login-header-wrapper'>
                <Typography className='login-header'>Login</Typography>
                <Typography className='login-sub'>login with your youthplus credentials</Typography>
            </div>
              <Form layout="vertical">
                  <Form.Item label="Username">
                      <Input
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="Enter your username"
                      />
                  </Form.Item>
                  <Form.Item label="Password">
                      <Input.Password
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter your password"
                      />
                  </Form.Item>
                  <Button className="login-button" type="primary" onClick={handleLogin} style={{backgroundColor:'#08839B'}}>
                      Login
                  </Button>
              </Form>
          </div>
    </Modal>
  );
};

export default LoginModal;
