import {React, useState} from 'react'
import {Button, Typography} from 'antd';
import Logo from '../asset/youthpluswhite.png'
import LoginModal from './transportLogin';
import './header.css'
import { Link } from 'react-router-dom';

export default function TransportHeader() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleLogin = (credentials) => {
    // Perform login logic here with the provided credentials
    console.log('Logging in with:', credentials);
    // Close the modal after login attempt
    setModalVisible(false);
  };

  return (
    <div className='header-container'>
      <Link to="/transport" >
      <img src={Logo} alt='logo' className='header-logo' />
      </Link>
      <div className='header-box'>
        <Typography className='route-text'>Suggest a route</Typography>
        <Button onClick={() => setModalVisible(true)} className='transport-login'>
            Login
        </Button>
        <LoginModal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onLogin={handleLogin}
      />
      </div>
    </div>
  );
}
