import React, { useEffect, useState } from "react";
import { Button, Spin, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import BusSeats from "./BusSeats";
import Seat1 from "../asset/Seat1.svg";
import Seat3 from "../asset/Seat3.svg";
import Seat4 from "../asset/Seat4.svg";
import { useApi } from "../Context/apiContext";
import "./SeatReserve.css"; // Ensure to import your CSS file for styling

function SeatReserve({
  onClose,
  quantity,
  selectedSeats,
  setSelectedSeats,
  tripId,
  providerId,
  setValidation,
  selectedTrip,
  disabled,
}) {
  const { apiCall } = useApi();
  const [availableSeats, setAvailableSeats] = useState([]);
  const [totalSeatCount, setTotalSeatCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAvailableSeats = async () => {
      setLoading(true);
      try {
        const response = await apiCall("/onboard/qt/availableseats/", "POST", {
          tripId: tripId,
          providerId: providerId,
        });
        setAvailableSeats(response.message.seats);
        setTotalSeatCount(response.message.totalSeatCount);
      } catch (error) {
        console.error("Error fetching available seats:", error);
        notification.error({
          message: "Error",
          description:
            error.response?.data?.message ||
            "There was an error fetching available seats. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableSeats();
  }, [apiCall, tripId, providerId]);

  const handleSeatSelect = (seatNumber) => {
    if (disabled) return;

    setSelectedSeats((prevSeats) => {
      if (prevSeats.includes(seatNumber)) {
        return prevSeats.filter((seat) => seat !== seatNumber);
      } else {
        return [...prevSeats, seatNumber];
      }
    });
  };

  return (
    <div className={`seat-reservation-container ${disabled ? "disabled" : ""}`}>
      <h3 style={{ textAlign: "center" }}>Seat Reservation</h3>
      <p style={{ textAlign: "center", marginBottom: "20px" }}>
        {selectedTrip.origin} - {selectedTrip.destination}
      </p>
      <div className="seat-types">
        <div className="seat-type">
          <img src={Seat1} alt="Your Seat(s)" width="20px" height="20px" />
          <p>Your Seat(s)</p>
        </div>
        <div className="seat-type">
          <img
            src={Seat3}
            alt="Unavailable Seats (Paid)"
            width="20px"
            height="20px"
          />
          <p>Unavailable Seats (Paid)</p>
        </div>
        <div className="seat-type">
          <img src={Seat4} alt="Available Seats" width="20px" height="20px" />
          <p>Available Seats</p>
        </div>
      </div>
      <div className="seat-layout">
        {loading ? (
          <div style={{ textAlign: "center", margin: "2rem 0" }}>
            <Spin size="large" />
          </div>
        ) : (
          <BusSeats
            totalseats={totalSeatCount}
            selectedSeats={selectedSeats}
            handleSeatSelect={handleSeatSelect}
            availableSeats={availableSeats}
            maxSelectableSeats={quantity}
            setValidation={setValidation}
          />
        )}
      </div>
      <div className="reservation-notice">
        <ExclamationCircleOutlined
          style={{ color: "red", marginRight: "1rem" }}
        />
        <p style={{ color: "red" }}>
          Please note that if you cancel or attempt to go back after confirming
          your seat reservation, the reserved seat will be unavailable for 30
          minutes. This policy is in place to manage reservations effectively
          and ensure availability for all users. Consider your selection
          carefully before confirming.
        </p>
      </div>
      <div className="reservation-summary">
        <p>
          Reserved: {selectedSeats.length}/{quantity}
        </p>
      </div>
      <Button
        type="primary"
        block
        onClick={onClose}
        style={{
          backgroundColor: "#048181",
          borderColor: "#048181",
          marginTop: "20px",
        }}
      >
        Continue
      </Button>
    </div>
  );
}

export default SeatReserve;
