import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification, Spin } from "antd";
import PersonalDetails from "./personalDetails";
import SeatReserve from "./seatReserve";
import ContactDetails from "./contactDetails";
import BookingSummary from "./bookingColumn";
import PassengerDropdown from "./passengerDropdown";
import ConfirmDetailsModal from "./ConfirmDetailsModal";
import { useApi } from "../Context/apiContext";
import { encryptData } from "../utils/encryptionUtils";

function Content({ selectedTrip }) {
  const { apiCall } = useApi();

  const [passengerDetails, setPassengerDetails] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [voucher, setVoucher] = useState("");
  const [visible, setVisible] = useState(false);
  const [seatsLeft, setSeatsLeft] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [price, setPrice] = useState(0);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [timer, setTimer] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [validation, setValidation] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [showReservation, setShowReservation] = useState(false);
  const [bookingSuccessful, setBookingSuccessful] = useState(false);

  const fee = 0;

  useEffect(() => {
    if (selectedTrip) {
      setSeatsLeft(selectedTrip.availableSeats);
      setPrice(selectedTrip.price);
    }
  }, [selectedTrip]);

  useEffect(() => {
    setQuantity(adultCount + childCount);
    setSelectedSeats([]);
    setPassengerDetails(
      Array.from({ length: quantity }, (_, i) => ({
        firstname: "",
        lastname: "",
        gendervalue: "",
        titlevalue: "",
        phone: "",
        email: "",
        passengerType: i < adultCount ? "Adult" : "Child",
        nok: {
          firstname: "",
          lastname: "",
          phone: "",
          email: "",
          gendervalue: "",
          titlevalue: "",
        },
      }))
    );
  }, [adultCount, childCount, quantity]);

  useEffect(() => {
    if (timer !== null && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const showDrawer = () => {
    setVisible(true);
  };
  // eslint-disable-next-line
  const onClose = () => {
    setVisible(false);
  };

  const handlePassengerChange = (index, field, value) => {
    const updatedDetails = [...passengerDetails];
    if (field.startsWith("nok")) {
      const nokField = field.split(".")[1];
      updatedDetails[index].nok[nokField] = value;
    } else {
      updatedDetails[index][field] = value;
    }
    setPassengerDetails(updatedDetails);
  };

  const handleContinue = () => {
    if (!validation) {
      notification.error({
        message: "Incomplete Details",
        description: "Please fill out all the required fields.",
      });
      return;
    }

    setShowReservation(true);
  };

  const handleBooking = async () => {
    hideConfirmationModal();
    if (selectedSeats.length === 0) {
      notification.error({
        message: "No Seat Selected",
        description: "Please select at least one seat before confirming.",
      });
      return;
    }

    if (!validation) {
      notification.error({
        message: "A Tab wasn't filled completely",
        description: "Please fill up the blank tabs",
      });
      return;
    }

    if (!selectedTrip) {
      notification.error({
        message: "No Trip Selected",
        description: "Please select a trip before proceeding with booking.",
      });
      return;
    }

    setLoading(true);

    const payload = {
      providerId: selectedTrip.providerId,
      scheduleId: selectedTrip.id,
      travelDate: selectedTrip.departureDate,
      operatorCode: selectedTrip.operatorCode,
      noOfSeats: quantity,
      email:"knowbot@sidmach.com",
      adultMetaData: selectedTrip.adultMetaData,
      childMetaData: selectedTrip.childMetaData,
      passengers: selectedSeats.map((seat, index) => ({
        seatNumber: seat,
        amount: price.toString(),
        title: passengerDetails[index].titlevalue,
        firstName: passengerDetails[index].firstname,
        lastName: passengerDetails[index].lastname,
        phoneNumber: passengerDetails[index].phone,
        email: passengerDetails[index].email,
        gender: passengerDetails[index].gendervalue,
        isPrimary: index === 0,
        passengerType: passengerDetails[index].passengerType,
        discountedAmount: price.toString(),
        nok: {
          firstName: passengerDetails[index].nok.firstname,
          lastName: passengerDetails[index].nok.lastname,
          mobileNumber: passengerDetails[index].nok.phone,
          email: passengerDetails[index].nok.email,
        },
      })),
    };

    try {
      const response = await apiCall("/onboard/qt/bookings/", "POST", payload);
      console.log("Booking response:", response);
      notification.success({
        message: "Booking Complete",
        description: "Your booking was successful.",
      });

      setSessionId(response.message.sessionId);
      setTimer(Math.floor(response.message.deadLineInMilliSecs / 1000));
      setBookingSuccessful(true); // Set booking success state
      setConfirmationVisible(false); // Close confirmation modal
    } catch (error) {
      console.error("Error booking trip:", error);
      notification.error({
        message: "Booking Error",
        description:
          error.response?.data?.message ||
          "There was an error processing your booking. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async () => {
    if (!sessionId) {
      notification.error({
        message: "No Session ID",
        description:
          "No reservation cached for the session. Please try booking again.",
      });
      return;
    }

    setLoading(true);

    // Combine data for encryption
    const combinedData = {
      session_Id:sessionId,
      amount: (price * quantity + fee),
    };
    const encryptedCombinedData = encryptData(combinedData);

    const paymentPayload = {
      amount: (price * quantity + fee).toString(),
      fee: fee.toString(),
      fullName: `${passengerDetails[0]?.firstname} ${passengerDetails[0]?.lastname}`,
      mobileNumber: phone,
      currency: "NGN",
      paymentReference: sessionId,
      email: email,
      productId: selectedTrip?.id || "N/A",
      // callbackURL: `http://localhost:3000/trip-summary?providerId=${
      callbackURL: `http://nysc.youthplus.ng/trip-summary?providerId=${

        selectedTrip.providerId}&passengerDetails=${encodeURIComponent(encryptedCombinedData)}`,
      status: "Pending",
    };

    try {
      const response = await apiCall(
        "/onboard/qt/initiate-payment/",
        "POST",
        paymentPayload,
        false
      );
      console.log("Payment response:", response);
      notification.success({
        message: "Payment Initiated",
        description: "Your payment has been successfully initiated.",
      });
      window.location.replace(response.result.data.payments.redirectLink);
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
      notification.error({
        message: "Payment Error",
        description:
          error.response?.data?.message ||
          "There was an error initiating your payment. Please try again.",
      });
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const handleSeatReserveClose = () => {
    if (selectedSeats.length === quantity) {
      setConfirmationVisible(true); // Show the confirmation modal on seat reserve close
    } else {
      if (selectedSeats.length === 0) {
        notification.error({
          message: "No Seat Selected",
          description:
            "Please select your seat position(s) to complete your reservation.",
        });
        return;
      }
      notification.error({
        message: "Error",
        description: `Please select ${
          quantity - selectedSeats.length
        } more seat position to complete your reservation `,
      });
    }
  };

  const hideConfirmationModal = () => {
    setConfirmationVisible(false);
  };

  return (
    <div className="content-styles">
      {loading && (
        <div
          className="loading-overlay"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <Spin size="large" />
        </div>
      )}

      <Row gutter={45}>
        {!showReservation ? (
          <Col span={24}>
            <div>
              <PassengerDropdown
                seatsLeft={seatsLeft}
                quantity={quantity}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                setValidation={setValidation}
              />
              <PersonalDetails
                seatsLeft={seatsLeft}
                passengerDetails={passengerDetails}
                handlePassengerChange={handlePassengerChange}
                quantity={quantity}
                setValidation={setValidation}
              />
              <ContactDetails
                email={email}
                phone={phone}
                handleEmailChange={(e) => setEmail(e.target.value)}
                handlePhoneChange={(e) => setPhone(e.target.value)}
                passengerDetails={passengerDetails}
                setValidation={setValidation}
              />
              <Button
                block
                style={{
                  backgroundColor: " #048181",
                  borderColor: "#048181",
                  color: "#fff",
                  marginTop: "2rem",
                }}
                onClick={handleContinue}
                disabled={loading}
              >
                {loading ? <Spin /> : "Continue"}
              </Button>
            </div>
          </Col>
        ) : (
          <Col md={{ span: 14 }} xs={{ span: 24 }}>
            <SeatReserve
              showDrawer={showDrawer}
              onClose={handleSeatReserveClose} // Use this function to show confirmation modal
              visible={visible}
              quantity={quantity}
              selectedSeats={selectedSeats}
              setSelectedSeats={setSelectedSeats}
              tripId={selectedTrip.id}
              providerId={selectedTrip.providerId}
              setValidation={setValidation}
              selectedTrip={selectedTrip}
              disabled={bookingSuccessful} // Disable seat reservation after successful booking
            />
          </Col>
        )}
        {showReservation && (
          <Col md={{ span: 10 }} xs={{ span: 24 }}>
            <BookingSummary
              voucher={voucher}
              handleVoucher={(e) => setVoucher(e.target.value)}
              quantity={quantity}
              price={price}
              fee={fee}
              selectedTrip={selectedTrip}
              timer={timer}
              onPayNow={handlePayment} // Add pay now handler
              onSuccess={bookingSuccessful}
            />
          </Col>
        )}
      </Row>
      <ConfirmDetailsModal
        visible={confirmationVisible}
        onClose={hideConfirmationModal}
        onUpdateDetails={() => {
          setConfirmationVisible(false);
          setShowReservation(false);
        }} // Hide modal and show reservation form
        onConfirm={handleBooking}
        passengerDetails={passengerDetails}
        selectedSeats={selectedSeats}
        contactDetails={{ email, phone }}
      />
    </div>
  );
}

export default Content;
