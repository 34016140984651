import React from "react";
import { Card, Row, Col, Badge, Typography, Divider } from "antd";

const { Title, Paragraph, Text } = Typography;

const PassangersInfoForm = ({ ticketDetails }) => {
  return (
    <Col md={{ span: 12 }} xs={{ span: 24 }} id="section-noprint">
      <Col span={24}>
        <Card
          style={{ background: "#fff", marginBottom: 20 }}
          title={
            <>
              <Badge count={1} style={{ backgroundColor: "#08839B", marginRight: 8 }} />
              <span style={{ color: "#08839B" }}>Passengers Details</span>
            </>
          }
        >
          {ticketDetails?.message?.passengers?.map((person, index) => (
            <Row key={index} gutter={10}>
              <Col xs={24}>
                <Title level={4}>{`Passenger ${index + 1} Details`}</Title>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Paragraph>
                  <Text strong>Title: </Text>
                </Paragraph>
                <Paragraph>
                  <Text>{person.title}</Text>
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Paragraph>
                  <Text strong>First Name: </Text>
                </Paragraph>
                <Paragraph>
                  <Text>{person.firstName}</Text>
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Paragraph>
                  <Text strong>Last Name: </Text>
                </Paragraph>
                <Paragraph>
                  <Text>{person.lastName}</Text>
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Paragraph>
                  <Text strong>Gender: </Text>
                </Paragraph>
                <Paragraph>
                  <Text>{person.gender}</Text>
                </Paragraph>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Paragraph>
                  <Text strong>Phone: </Text>
                </Paragraph>
                <Paragraph>
                  <Text>{person.phoneNumber}</Text>
                </Paragraph>
              </Col>
              <Divider />
            </Row>
          ))}
        </Card>
      </Col>
      <Col span={24} style={{ marginBottom: 20 }}>
        <Card
          title={
            <>
              <Badge count={2} style={{ backgroundColor: "#08839B", marginRight: 8 }} />
              <span style={{ color: "#08839B" }}>Seat Reservation</span>
            </>
          }
          style={{ background: "#fff" }}
        >
          {ticketDetails?.message?.passengers?.map((person, index) => (
            <Row key={index}>
              <Col xs={24} sm={12}>
                <Paragraph>
                  <Text strong>{`Passenger ${index + 1} Seat Number: `}</Text>
                  {person.seatNumber}
                </Paragraph>
              </Col>
            </Row>
          ))}
        </Card>
      </Col>
    </Col>
  );
};

export default PassangersInfoForm;
