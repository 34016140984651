import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import StanbicLogo from "../asset/stanbic_logo.png";
import Cowrylogo from "../asset/Cowrylogo.png";
import productdivelogos from "../asset/productdivelogos.jpg";
import quicktellerlogo from "../asset/quicktellerlogo.png";
import msllogo from "../asset/msllogo.png";
import Form from 'react-bootstrap/Form';
import "./SignSample.css";
import { Link } from "react-router-dom";

export default function PartnerOptionsModal(props) {
    const [partnerAccordClick, setPartnerAccordClick] = useState(null);

    const partnerslist = [
        {
            id: 1,
            name: "StanbicIbtc",
            logo: StanbicLogo,
            isCheckedValue: props.stanbicIsChecked,
            onChangeFunctions: props.handleStanbicCheckboxChange,
            urlTerms: "stanbicterms",
            urlPrivacyP: "stanbicterms",
            partnerDescription: "Easy banking service with free verve card.",
        },
        {
            id: 2,
            name: "Cowry",
            logo: Cowrylogo,
            isCheckedValue: props.hasCowryConsent,
            onChangeFunctions: props.handleHasCowryConsentChange,
            urlTerms: "https://interstate.cowry.com.ng/",
            urlPrivacyP: "https://interstate.cowry.com.ng/",
            partnerDescription: "Stress free travel from home to camp.",
            // value: cowryconsent,
        },
        {
            id: 3,
            name: "QuickTeller",
            logo: quicktellerlogo,
            isCheckedValue: props.hasQuicktellerConsent,
            onChangeFunctions: props.handleHasQuicktellerConsentChange,
            urlTerms: "https://www.interswitchgroup.com/terms-of-use/quickteller.html",
            urlPrivacyP: "https://www.interswitchgroup.com/ng/privacy-policy",
            partnerDescription: "A QUICK CREDIT/LENDING transport ticket to Orientation Camps.",
            // value: cowryconsent,
        },
        {
            id: 4,
            name: "MyShopLauncher",
            logo: msllogo,
            isCheckedValue: props.hasMSLConsent,
            onChangeFunctions: props.handleHasMSLConsentChange,
            urlTerms: "https://myshoplauncher.com/terms",
            urlPrivacyP: "https://myshoplauncher.com/policy",
            partnerDescription: "A free website store front for Entrepreneurs and service providers.",
            // value: cowryconsent,
        },
        {
            id: 5,
            name: "ProductDive",
            logo: productdivelogos,
            isCheckedValue: props.hasProductDiveConsent,
            onChangeFunctions: props.handleHasProductDiveConsentChange,
            urlTerms: "https://productdive.com/productdive-refund-policy/",
            urlPrivacyP: "https://productdive.com/productdive-payment-default-policy/",
            partnerDescription: "A tech career starter pack.",
            // value: cowryconsent,
        },
    ]

    const handleAccordionClick = (eventKey) => {
        // Your function to be triggered when the accordion is clicked
        // console.log('Accordion clicked:', eventKey);
        setPartnerAccordClick(eventKey)
    };

// console.log(partnerAccordClick)
    return (
        <>
            <Modal
                show={props.show}
                {...props}
                onHide={props.handleClose}
                backdrop={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            // className="modal-pop"
            >
                <Modal.Header style={{ border: "none" }}>
                    <Modal.Title style={{ fontSize: "1rem" }} id="contained-modal-title-vcenter">
                        Our partners terms and conditions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p style={{ color: "#000" }}>
                            By proceeding you are accepting all our Terms and Conditions and perks to access and enjoy all the exclusive benefits. These policies prioritize transparency and security.
                        </p>
                    </div>
                    <div>
                        <Accordion onSelect={handleAccordionClick} style={{ paddingTop: "1em" }}>
                            <Accordion.Item eventKey="0" className="partneraccordionItem">
                                <Accordion.Header className="partneraccordionHeader">View our partners</Accordion.Header>
                                <Accordion.Body>
                                    {/* <CardGroup> */}
                                    {partnerslist
                                        // .filter((item, i) => i < 3)
                                        .map((item, i) => (
                                            <Card key={item.id} style={{ border: "none" }}>
                                                <div className="partnercardDiv">
                                                    <Card.Img variant="top" src={item.logo} className="partnercard-img" />
                                                    <div className="mb-3 mt-3" style={{ marginLeft: "1em" }}>
                                                        <Form className="ml-3">
                                                            <Form.Check // prettier-ignore
                                                                type="checkbox"
                                                                id={item.id}
                                                                // label={`default checkbox`}
                                                                checked={item.isCheckedValue}
                                                                onChange={item.onChangeFunctions}
                                                            // style={{borderColor: "#08839B"}}
                                                            />
                                                        </Form>
                                                    </div>
                                                    <Card.Body>
                                                        <Card.Title className="partnercard-title">{item.name}</Card.Title>
                                                        <Card.Text className="partnercard-text">
                                                            {item.partnerDescription}
                                                        </Card.Text>
                                                        <Card.Text className="partnercard-text">
                                                            By clicking this box, you agree to our <b><Link to={item?.urlTerms} target="blank" className="partnertermslink">Terms</Link></b> and <b><Link to={item?.urlPrivacyP} target="blank" className="partnertermslink">Privacy Policy </Link></b>and access to all our exclusive benefits.
                                                        </Card.Text>
                                                        {/* <Button variant="primary">Go somewhere</Button> */}
                                                    </Card.Body>
                                                </div>
                                            </Card>
                                        ))
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Modal.Body>

                <Modal.Footer className="partnersmodalfooter">
                    {partnerAccordClick === null ?
                        ""
                        :
                        <Button variant="primary" style={{ background: "transparent", color: "#08839B" }}>Decline All</Button>
                    }
                    <Button variant="primary" style={{ background: "#08839B" }} onClick={props.createYouthPlusAccount}>Allow All</Button>
                </Modal.Footer>

            </Modal>
        </>
    );
}
